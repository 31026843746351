import { Editor } from "@tiptap/react";
import { DefaultMarginOptions } from "../extensions/document-margin/constants";
import { DEFAULT_MARGIN_OPTIONS } from "./constants";
import { Modal } from "components/organisms/modal";
import { Select } from "../../primitives/Select";

import { formatMargins, getCurrentMargins, getCurrentMarginType } from "./utils";
import { useCallback, useEffect, useState } from "react";

type Props = {
    editor: Editor;
};

export function ToolbarMargins({ editor }: Props) {
    const [customMarginOpen, setCustomMarginOpen] = useState(false);
    const currentMarginType = getCurrentMarginType(editor);
    const onMarginChange = useCallback(
        (value: DefaultMarginOptions | "Custom") => {
            if (value === "Custom") {
                setCustomMarginOpen(true);
            } else {
                if (!editor) return;
                editor.commands.setMargin(value);
            }
        },
        [editor]
    );

    const MARGIN_OPTIONS = [
        ...DEFAULT_MARGIN_OPTIONS,
        {
            value: "Custom",
            title: "Custom",
            description: currentMarginType === "Custom" ? formatMargins(getCurrentMargins(editor)) : "Custom Margin",
        },
    ];

    return (
        <>
            <Select
                variant="subtle"
                value={currentMarginType}
                initialValue={DefaultMarginOptions.Normal}
                items={MARGIN_OPTIONS}
                onChange={onMarginChange}
                // We do not want to use internal value, because if we select
                // custom we can cancel and the old margin should be selected
                useInternalValue={false}
            />
            <CustomMarginsModal
                isOpen={customMarginOpen}
                onMarginChange={(margins) => {
                    editor.commands.setMargin(margins);
                }}
                onClose={() => {
                    setCustomMarginOpen(false);
                }}
                editor={editor}
            />
        </>
    );
}

interface CustomMarginsModalProps {
    isOpen: boolean;
    onMarginChange: (margins: { top: number; bottom: number; left: number; right: number }) => void;
    onClose: () => void;
    editor: Editor;
}

export function CustomMarginsModal({ isOpen, onMarginChange, onClose, editor }: CustomMarginsModalProps) {
    const [top, setTop] = useState<number>(1);
    const [bottom, setBottom] = useState<number>(1);
    const [left, setLeft] = useState<number>(1);
    const [right, setRight] = useState<number>(1);

    const handleSave = () => {
        onMarginChange({ top, bottom, left, right });
        onClose();
    };

    useEffect(() => {
        if (isOpen) {
            const { top, bottom, left, right } = getCurrentMargins(editor);
            setTop(top);
            setBottom(bottom);
            setLeft(left);
            setRight(right);
        }
    }, [editor, isOpen]);

    const body = (
        <div className="flex flex-1 flex-col p-4 gap-4">
            <div className="flex flex-1">
                <div className="flex flex-row flex-1 pr-1 mx-2 items-center justify-between">
                    Top:
                    <input
                        min={0}
                        type="number"
                        value={top}
                        onChange={(e) => setTop(parseFloat(e.target.value))}
                        className={"border border-gray-500 rounded-md text-stone-900 mx-2 w-14 p-1"}
                    />
                </div>
                <div className="flex flex-row flex-1 pr-1 mx-2 items-center justify-between">
                    Bottom:
                    <input
                        min={0}
                        type="number"
                        value={bottom}
                        onChange={(e) => setBottom(parseFloat(e.target.value))}
                        className={"border border-gray-500 rounded-md text-stone-900 mx-2 w-14 p-1"}
                    />
                </div>
            </div>
            <div className="flex flex-1">
                <div className="flex flex-row flex-1 pr-1 mx-2 items-center justify-between">
                    Left:
                    <input
                        min={0}
                        type="number"
                        value={left}
                        onChange={(e) => setLeft(parseFloat(e.target.value))}
                        className={"border border-gray-500 rounded-md text-stone-900 mx-2 w-14 p-1"}
                    />
                </div>
                <div className="flex flex-row flex-1 pr-1 mx-2 items-center justify-between">
                    Right:
                    <input
                        min={0}
                        type="number"
                        value={right}
                        onChange={(e) => setRight(parseFloat(e.target.value))}
                        className={"border border-gray-500 rounded-md text-stone-900 mx-2 w-14 p-1"}
                    />
                </div>
            </div>
        </div>
    );

    const footer = (
        <div>
            <button onClick={onClose} className="mr-2 bg-gray-300 hover:bg-gray-400 text-black px-4 py-2 rounded">
                Cancel
            </button>
            <button onClick={handleSave} className="bg-action hover:bg-blue-600 text-white px-4 py-2 rounded">
                Save
            </button>
        </div>
    );

    return (
        <Modal
            open={isOpen}
            onOpenChange={onClose}
            header="Custom Margin Options"
            body={body}
            footer={footer}
            hideClose={false}
        />
    );
}
