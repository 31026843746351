export type CopilotPresence = {
  name?: string;
  activePage: CopilotPresencePage | null;
  proposalRoomId: string;
  cursor: {
    percentX: number;
    percentY: number;
  };
  selectedId?: string;
};

export enum CopilotPresencePage {
  AskAi = "ask-ai",
  Project = "project",
  ComplianceMatrix = "compliancematrix",
  Documents = "documents",
  Template = "framework",
  Capture = "capture",
  Proposal = "proposal",
  ProposalPlan = "proposalplan",
  Extract = "extract",
}
