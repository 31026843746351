/** @jsxImportSource @emotion/react */

import { Dispatch, useEffect, useRef, useState } from "react";
import { Comment } from "../types";
import { CommentEditor } from "./CommentEditor/CommentEditor";
import { useAppSelector } from "store/storeTypes";
import { useCommentOperations } from "../../../api/comments/useCommentOperations";
import Avatar, { VultronAvatar } from "components/molecules/avatar";
import { ReactionBar } from "./ReactionBar";
import tw from "twin.macro";
import CommentOptions from "./CommentOptions";
import copyText from "utils/copyText";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import Tooltip from "components/atoms/tooltip/Tooltip";

interface CommentViewProps {
  comment: Comment;
  commentIsEditable?: boolean;
  internalContractId: string;
  isLastComment?: boolean;
  threadId?: string;
  referenceId?: string;
  quote_text?: string;
  isActive?: boolean;
  setDisableScroll: React.Dispatch<React.SetStateAction<boolean>>;
  resolved?: boolean;
  setCommentIsEditable?: Dispatch<React.SetStateAction<boolean>>;
  showCommentOptions?: boolean;
}

export const CommentView = ({
  comment,
  internalContractId,
  isLastComment,
  referenceId,
  quote_text,
  threadId,
  isActive,
  setDisableScroll,
  commentIsEditable,
  setCommentIsEditable,
  showCommentOptions,
}: CommentViewProps) => {
  const [readonly, setReadonly] = useState(true);

  const formatCommentDate = (createdAt: Date): string => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diffInMs = now.getTime() - createdDate.getTime(); // Difference in milliseconds

    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));

    if (diffInMinutes === 0) {
      return "Just now";
    }
    if (diffInMinutes < 60) {
      return `${diffInMinutes}m`;
    } else if (diffInHours < 24) {
      return `${diffInHours}h`;
    } else {
      const options: Intl.DateTimeFormatOptions = { month: "long", day: "numeric" };
      return createdDate.toLocaleDateString(undefined, options);
    }
  };

  const currentUser = useAppSelector((store) => store.auth.currentUser);
  const { editCommentMutation, deleteCommentMutation, updateReactionMutation } = useCommentOperations(
    internalContractId,
    referenceId
  );
  const commentRef = useRef<HTMLDivElement>(null);
  const hasReactions = Object.keys(comment.reactions).length > 0;
  const isUserVultron = comment.commenter.is_vultron;

  useEffect(() => {
    if (isActive && commentRef.current) {
      setTimeout(() => commentRef.current?.scrollIntoView({ behavior: "smooth", block: "center" }), 150);
    }
  }, [isActive]);

  const handleReaction = (emoji: string) => {
    const userReactions = comment.reactions[currentUser?.id || ""];
    const remove = userReactions && userReactions.includes(emoji);
    updateReactionMutation.mutate({ comment_id: comment.id, emoji, remove });
  };

  const normalizeTagLabel = (tag: string) => {
    if (tag === "outcomes_benefits") return "Outcomes & Benefits";
    return tag
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const truncatedReviewTags =
    !!comment.review_tags?.length && comment.review_tags?.length > 2
      ? normalizeTagLabel(
          comment.review_tags
            ?.slice(2, comment.review_tags?.length)
            .map((tag) => normalizeTagLabel(tag))
            .join(", ")
        )
      : "";

  return (
    <div className="flex w-full flex-col gap-2 group/comment" ref={commentRef}>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-center gap-2">
          {isUserVultron ? (
            <VultronAvatar size={22} />
          ) : (
            <Avatar size={22} textColor={"#FFFFFF"} name={comment.commenter.username} id={comment.commenter.id} />
          )}
          <div className="flex flex-row items-baseline gap-1.5 ml-[2px]">
            <div className="text-gray-700 font-medium text-sm truncate max-w-[70%]">
              {isUserVultron ? "Vultron" : comment.commenter.username}
            </div>
            <div className="text-gray-light text-xs">{formatCommentDate(comment.updated_at)}</div>
            <div className="flex flex-wrap items-center gap-1 ml-0.5">
              {comment?.review_tags?.slice(0, 2).map((tag) => (
                <div
                  key={tag}
                  className="text-xs bg-[#FFFBED] text-[#D9940B] border border-[#D9940B] px-1.5 py-0.5 rounded"
                >
                  {normalizeTagLabel(tag)}
                </div>
              ))}
              {!!comment?.review_tags?.length && comment?.review_tags?.length > 2 && (
                <Tooltip content={truncatedReviewTags}>
                  <div className="text-xs bg-[#F6F6F6] text-[#7A7F84] border border-[#7A7F84] px-1.5 py-0.5 rounded">
                    +{comment.review_tags.length - 2}
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        {(showCommentOptions || !hasReactions) && (
          <div
            className="flex flex-row gap-0.5 self-start p-1 h-4 items-center justify-center opacity-0 group-hover/comment:opacity-100"
            onFocus={(e) => e.stopPropagation()}
          >
            {!hasReactions && (
              <ReactionBar
                reactions={comment.reactions}
                onReaction={handleReaction}
                setDisableScroll={setDisableScroll}
              />
            )}
            {showCommentOptions && (
              <CommentOptions
                setDisableScroll={setDisableScroll}
                isCommenter={currentUser?.id === comment.commenter.id}
                onEdit={() => {
                  setReadonly(false);
                }}
                onDelete={() => {
                  triggerConfirm({
                    header: `Are you sure you want to delete this comment?`,
                    body: "This action is irreversible and will delete this reply from the thread.",
                  }).then((proceed) => {
                    if (proceed) {
                      deleteCommentMutation.mutate({ comment_id: comment.id });
                    }
                  });
                }}
                onCopyLink={() => {
                  if (!threadId) return;
                  const url = new URL(window.location.href);
                  url.searchParams.set("threadId", threadId);
                  url.searchParams.set("commentId", comment.id);
                  copyText(url.href);
                }}
              />
            )}
          </div>
        )}
      </div>
      <div
        className="pb-1 pl-5 ml-2.5 flex flex-col gap-y-1"
        css={[!isLastComment && tw`border-l border-l-[#E4E4E4] `]}
      >
        {quote_text && (
          <div className="flex flex-row pl-2 gap-2 px-1 text-xs border-l-2 border-l-[#E8C969] mb-1">
            <div className="wrap text-gray-light line-clamp-10">
              <CommentEditor readonly={readonly} content={quote_text} />
            </div>
          </div>
        )}
        <CommentEditor
          readonly={readonly && !commentIsEditable}
          content={comment.content}
          showCancel
          onCancel={() => {
            setReadonly(true);
            setCommentIsEditable?.(false);
          }}
          onAddComment={(content) => {
            editCommentMutation.mutate({ content, comment_id: comment.id });
            setReadonly(true);
            setCommentIsEditable?.(false);
          }}
        />
        {hasReactions && (
          // if there are reactions, move the reaction button next to the reactions
          <ReactionBar reactions={comment.reactions} onReaction={handleReaction} setDisableScroll={setDisableScroll} />
        )}
      </div>
    </div>
  );
};
