import { STRICTNESS_TO_META } from "const-values/Draft";
import { ResponseTolerance } from "types/Requirement";
import DropdownMenu, { DropdownMenuProps } from "../dropdown-menu";
import { PropsWithChildren } from "react";

type Props = {
  selectedStrictness: ResponseTolerance;
  setStrictness: (value: ResponseTolerance) => void;
} & Omit<DropdownMenuProps<ResponseTolerance>, "items" | "multiselect">;

const StrictnessDropdown = ({ children, selectedStrictness, setStrictness, ...props }: PropsWithChildren<Props>) => {
  return (
    <DropdownMenu
      items={[
        {
          key: 1,
          label: (
            <div>
              <div className="text-sm pb-1">{STRICTNESS_TO_META[ResponseTolerance.Strict].label}</div>
              <div className="text-[11.5px] text-gray-text">{STRICTNESS_TO_META[ResponseTolerance.Strict].text}</div>
            </div>
          ),
          selected: selectedStrictness === ResponseTolerance.Strict,
          value: ResponseTolerance.Strict,
          onSelect: () => setStrictness(ResponseTolerance.Strict),
        },
        {
          key: 2,
          label: (
            <div>
              <div className="text-sm pb-1">{STRICTNESS_TO_META[ResponseTolerance.Moderate].label}</div>
              <div className="text-[11.5px] text-gray-text">{STRICTNESS_TO_META[ResponseTolerance.Moderate].text}</div>
            </div>
          ),
          selected: selectedStrictness === ResponseTolerance.Moderate,
          value: ResponseTolerance.Moderate,
          onSelect: () => setStrictness(ResponseTolerance.Moderate),
        },
        {
          key: 3,
          label: (
            <div>
              <div className="text-sm pb-1">{STRICTNESS_TO_META[ResponseTolerance.Flexible].label}</div>
              <div className="text-[11.5px] text-gray-text">{STRICTNESS_TO_META[ResponseTolerance.Flexible].text}</div>
            </div>
          ),
          selected: selectedStrictness === ResponseTolerance.Flexible,
          value: ResponseTolerance.Flexible,
          onSelect: () => setStrictness(ResponseTolerance.Flexible),
        },
      ]}
      {...props}
    >
      {children}
    </DropdownMenu>
  );
};

export default StrictnessDropdown;
