export const CORE_CRITERIA = {
  active_voice: "Active Voice",
  explanation: "Approach",
  keywords: "Customer Language",
  substantiation: "Evidence",
  outcomes_benefits: "Outcomes & Benefits",
  readability: "Readability, Spelling, & Grammar",
  risk: "Risk Identification & Mitigation",
};

export enum SensitivityStatus {
  Concise = 0,
  Targeted,
  Exhaustive,
}

export const SENSITIVITY_OPTIONS = [
  {
    label: "Concise (recommended)",
    description: "Prioritize and focus feedback on the most critical elements that require improvement.",
    value: "concise",
  },
  {
    label: "Targeted",
    description:
      "Prioritize and focus feedback on major issues and significant concerns to improve key and essential areas.",
    value: "targeted",
  },
  {
    label: "Exhaustive",
    description:
      "Provide extensive feedback based on a line-by-line review to ensure alignment with the highest standards.",
    value: "exhaustive",
  },
];
