import { useAppSelector } from "store/storeTypes";
import { SourceModal } from "./SourceModal";
import { CreateProjectModal } from "components/modals/create-project-modal";
import { memo } from "react";
import DocumentViewerModal from "./DocumentViewerModal";

const GlobalModals = () => {
  const { sourceModal, createProjectModal, documentViewerModal } = useAppSelector((state) => state.modals);

  return (
    <>
      <SourceModal {...sourceModal} />
      <CreateProjectModal {...createProjectModal} />
      <DocumentViewerModal {...documentViewerModal} />
    </>
  );
};

export default memo(GlobalModals);
