/** @jsxImportSource @emotion/react */

import { Extraction, ExtractionStatus, Section } from "components/copilot/CopilotSchemaTypes";
import { CircleCheck, ExternalLink } from "lucide-react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { setActiveDocument, setHighlightedElementId } from "store/reducers/extract/CurrentExtractionReducer";
import { highlightAndScrollToElement, isInstantDraftStarted } from "../../../utils";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "styles/markdown-styles.css";

type Props = {
  requirements?: ToImmutable<Extraction["compliance_matrix"]>;
  groupedSections: Record<string, ToImmutable<Section>>;
  isImported: boolean;
};

const ContextRequirementsList = ({ requirements = [], groupedSections }: Props) => {
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const documents = useAppSelector((store) => store.currentExtractionState.documents);
  const activeDocument = useAppSelector((store) => store.currentExtractionState.activeDocument);
  const highlightedElementId = useAppSelector((root) => root.currentExtractionState.highlightedElementId);
  const isComplete = extraction?.status === ExtractionStatus.Completed;
  const isReadOnly = isComplete || isInstantDraftStarted(extraction?.instantDraftConfig?.status);
  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-col gap-1 ml-6 pb-1.5 pt-1">
      {!requirements.length && <div className="text-xs text-gray-400">No requirements</div>}
      {!!requirements.length &&
        requirements.map((row) => {
          const assignedSection = groupedSections[row.proposal_reference.section_id || ""];

          return (
            <div key={row.requirement.id} className="group relative">
              {activeDocument?.id !== row.document?.id && (
                <button
                  onClick={() => {
                    if (isReadOnly) return;
                    const doc = documents.find(({ id }) => id === row.document?.id);
                    if (doc) dispatch(setActiveDocument(doc));
                  }}
                  className="text-xs z-[1] select-none flex items-center gap-2 ml-auto absolute p-1.5 top-2 right-2 left-2 max-w-[175px] left shadow-sharp-thin rounded transition-opacity opacity-0 text-gray-darkest bg-zinc-200 group-hover:opacity-100 hover:bg-zinc-300 font-medium"
                >
                  <span className="truncate">{row.document?.name}</span>
                  <ExternalLink size={14} className="shrink-0" />
                </button>
              )}
              <div
                className="flex gap-2 rounded-md items-center cursor-pointer p-1 markdown-body"
                onClick={() => {
                  if (!row.requirement.element_id || activeDocument?.id !== row.document?.id) return;

                  if (highlightedElementId === row.requirement.element_id) {
                    highlightAndScrollToElement(highlightedElementId);
                  } else {
                    dispatch(setHighlightedElementId(row.requirement.element_id));
                  }
                }}
                css={[
                  activeDocument?.id !== row.document?.id &&
                    !isReadOnly &&
                    tw`pointer-events-none group-hover:opacity-50`,
                  assignedSection && tw`opacity-50`,
                ]}
              >
                <CircleCheck size={12} className="text-gray-400 shrink-0" />
                <Markdown remarkPlugins={[remarkGfm]} className="text-xs min-w-0 text-black whitespace-pre-line w-full">
                  {row.requirement?.content}
                </Markdown>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ContextRequirementsList;
