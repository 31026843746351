import { InstantDraftStatus } from "components/copilot/CopilotSchemaTypes";
import { REQUIREMENT_CLASSNAMES } from "./document-display/constants";

let timeout: NodeJS.Timeout;
export const highlightAndScrollToElement = (highlightedElementId: string) => {
  const blockNodes = document.querySelectorAll(`[data-element='${highlightedElementId}']`);
  blockNodes[0]?.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });

  const highlightedSelectedRequirement = document.querySelectorAll(
    `.${REQUIREMENT_CLASSNAMES.selected}, .${REQUIREMENT_CLASSNAMES.assigned}, .${REQUIREMENT_CLASSNAMES.extracted}`
  );

  const nodeToClassName = (node: Element) => {
    if (node.classList.contains(REQUIREMENT_CLASSNAMES.extracted)) {
      return "highlighted-extracted-requirement";
    } else if (node.classList.contains(REQUIREMENT_CLASSNAMES.selected)) {
      return "highlighted-selected-requirement";
    } else if (node.classList.contains(REQUIREMENT_CLASSNAMES.assigned)) {
      return "highlighted-assigned-requirement";
    }
    return "";
  };

  highlightedSelectedRequirement?.forEach((node) => node.classList.remove(nodeToClassName(node)));

  clearTimeout(timeout);
  blockNodes.forEach((node) => {
    node?.classList.add(nodeToClassName(node));

    timeout = setTimeout(() => {
      node?.classList.remove(nodeToClassName(node));
    }, 8000);
  });
};

export const isInstantDraftStarted = (instantDraftStatus?: InstantDraftStatus) =>
  (instantDraftStatus || InstantDraftStatus.Todo) >= InstantDraftStatus.Pending;
