import ListItem from "@tiptap/extension-list-item";

const MAX_DEPTH = 3;
declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    ListItem: {
      customSinkListItem: () => ReturnType;
      customLiftListItem: () => ReturnType;
    };
  }
}

export const CustomListItem = ListItem.extend({
  addCommands() {
    return {
      customSinkListItem:
        () =>
        ({ state, commands }) => {
          const { $from } = state.selection;
          let depth = 0;

          // Traverse up the node hierarchy
          for (let i = $from.depth; i > 0; i--) {
            const node = $from.node(i);
            if (node.type.name === "listItem") {
              depth++;
            }
          }

          // The the depth of the current node is greater than the max depth, do not sink
          if (depth > MAX_DEPTH - 1) return true;
          return commands.sinkListItem(this.name);
        },
      customLiftListItem:
        () =>
        ({ state, chain }) => {
          const { $from } = state.selection;
          let depth = 0;

          // Traverse up the node hierarchy
          for (let i = $from.depth; i > 0; i--) {
            const node = $from.node(i);
            if (node.type.name === "listItem") {
              depth++;
            }
          }

          // The the depth of the current node is greater than the max depth, do not sink
          const exceededDepth = depth - MAX_DEPTH;
          const liftCount = Math.max(exceededDepth, 0) + 1;

          // For each exceeded depth lift the list item
          let commandChain = chain();
          for (let i = 0; i < liftCount; i++) {
            commandChain = commandChain.liftListItem(this.name);
          }
          return commandChain.run();
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      ...this.parent?.(),
      "Shift-Tab": ({ editor }) => editor.commands.customLiftListItem(),
      Tab: ({ editor }) => editor.commands.customSinkListItem(),
    };
  },
});
