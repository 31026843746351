import { useSearchParams } from "react-router-dom";
import error from "../../Assets/error.png";
import { useEffect, useState } from "react";
import Loading from "./Loading";
import axios from "axios";
import { getWorkSpaces, setAuth } from "store/reducers/authReducerSlice";
import { useDispatch } from "react-redux";
import { useNotification } from "context/notificationContext";
import { BLOCKED_ERROR_CODE } from "./constants.ts";
import { setSessionRefreshTimer } from "utils/timerUtils";

// --------------- Auth Redirect page, When a user Logs into Auth0 -----------------------
const CheckLoginTokenPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  let [searchParams] = useSearchParams();
  const { setToast } = useNotification();
  const token = searchParams.get("token");
  const sso = searchParams.get("stytch_token_type") === "sso";
  const dispatch = useDispatch();

  const code = searchParams.get("code");

  // set withCredentials to true to allow cookies to be sent
  axios.defaults.withCredentials = true;

  const authError = searchParams.get("error");
  const blocked = authError === BLOCKED_ERROR_CODE;

  useEffect(() => {
    const setTokens = (token, sso, useAuth0) => {
      localStorage.setItem("vultron_user_token", JSON.stringify(token));
      localStorage.setItem("vultron_user_sso", JSON.stringify(sso));
      localStorage.setItem("vultron_user_use_auth0", JSON.stringify(useAuth0 || ""));
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axios.defaults.headers["X-Authorization-SSO"] = sso;
      axios.defaults.headers["X-Authorization-Auth0"] = useAuth0;
      setSessionRefreshTimer();
    };
    // check sso token
    const checkSSOToken = () => {
      if (!token) {
        setIsLoading(false);
        return;
      }
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      axios({
        method: "POST",
        url: "/users/authenticate/",
        data: { sso },
        headers: headers,
      })
        .then((res) => {
          setIsLoading(true);
          let token = res?.data?.session_token;
          let sso = res?.data?.sso;
          setTokens(token, sso, false);
          dispatch(getWorkSpaces(token));
          dispatch(
            setAuth({
              isAuthenticated: true,
              isLoading: false,
            })
          );
        })
        .catch((err) => {
          let errType = err?.response?.status;
          switch (errType) {
            case 401:
              const msg = sso
                ? "Authentication using the SSO provider failed."
                : "Your link has been expired. Please resend the new link.";
              setToast.error({
                title: "Authentication failed",
                msg,
              });
              break;
            default:
              setToast.error({
                title: "Authentication failed",
                msg: "We were unable to authenticate the login request due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
              });
          }
          console.log("error while verifying the user email link token. ", err);
        });
    };

    const fetchTokens = () => {
      axios({
        method: "POST",
        url: "/users/auth0/callback",
        data: { code: code },
      })
        .then((response) => {
          const token = response?.data?.session_token;
          setTokens(token, false, true);
          dispatch(getWorkSpaces(token));
          dispatch(
            setAuth({
              isAuthenticated: true,
              isLoading: false,
            })
          );
          return;
        })
        .catch((error) => {
          setIsLoading(false);
        });
    };

    if (sso) {
      checkSSOToken();
    } else {
      fetchTokens();
    }
    // eslint-disable-next-line
  }, []);

  //
  if (isLoading) return <Loading />;
  if (sso) {
    return (
      <div className="flex flex-col items-center">
        <img src={error} alt="" className="mb-8" />
        <div className="flex flex-col items-center mb-8">
          <h1 className="text-2xl font-semibold pt-4">Authentication Failed</h1>
          <p className="text-gray-mid py-2 text-sm w-full text-center leading-relaxed">
            Authentication using the SSO provider failed. <br /> Please contact your organization administrator.{" "}
          </p>
        </div>
      </div>
    );
  }
  if (blocked) {
    return (
      <div className="flex flex-col items-center">
        <img src={error} alt="" className="mb-8" />
        <div className="flex flex-col items-center mb-8">
          <h1 className="text-2xl font-semibold pt-4">Account Deactivated</h1>
          <p className="text-gray-mid py-2 text-sm w-full text-center leading-relaxed">
            Account deactivated due to inactivity. Please contact support@vultron.ai to have it reactivated.{" "}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col items-center">
      <img src={error} alt="" className="mb-8" />
      <div className="flex flex-col items-center mb-8">
        <h1 className="text-2xl font-semibold pt-4">Authentication Failed</h1>
        <p className="text-gray-mid py-2 text-sm w-full text-center leading-relaxed">
          Please retry or contact support@vultron.ai if the issue persists.{" "}
        </p>
      </div>
    </div>
  );
};

export default CheckLoginTokenPage;
