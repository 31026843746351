import { Editor, findParentNodeClosestToPos } from "@tiptap/react";
import { MenuItem } from "components/molecules/dropdown-menu/DropdownMenu";

export const getTableActionOptions = (editor: Editor): MenuItem<string>[] => [
    {
        key: "table",
        value: "table",
        label: "Insert Table",
        onSelect: () => {
            if (editor.isActive("table")) {
                return;
            }

            editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
            // Add empty paragraph and the end of the table
            editor
                .chain()
                .command(({ editor, commands }) => {
                    const table = findParentNodeClosestToPos(
                        editor.state.selection.$from,
                        (node) => node.type.name === editor.state.schema.nodes.table.name
                    );
                    if (!table) return false;
                    const tableEnd = table.pos + table.node.nodeSize;
                    return commands.insertContentAt(tableEnd, {
                        type: "paragraph",
                        content: [{ type: "text", text: "\n" }],
                    });
                })
                .run();
        },
    },
    {
        key: "columnBefore",
        value: "columnBefore",
        label: "Add Column Before",
        onSelect: () => editor.chain().focus().addColumnBefore().run(),
    },
    {
        key: "columnAfter",
        value: "columnAfter",
        label: "Add Column After",
        onSelect: () => editor.chain().focus().addColumnAfter().run(),
    },
    {
        key: "rowBefore",
        value: "rowBefore",
        label: "Add Row Before",
        onSelect: () => editor.chain().focus().addRowBefore().run(),
    },
    {
        key: "rowAfter",
        value: "rowAfter",
        label: "Add Row After",
        onSelect: () => editor.chain().focus().addRowAfter().run(),
    },
    {
        key: "deleteColumn",
        value: "deleteColumn",
        label: "Delete Column",
        onSelect: () => editor.chain().focus().deleteColumn().run(),
    },
    {
        key: "deleteRow",
        value: "deleteRow",
        label: "Delete Row",
        onSelect: () => editor.chain().focus().deleteRow().run(),
    },
    {
        key: "deleteTable",
        value: "deleteTable",
        label: "Delete Table",
        onSelect: () => editor.chain().focus().deleteTable().run(),
    },
    {
        key: "toggleHeaderColumn",
        value: "toggleHeaderColumn",
        label: "Toggle Header Column",
        onSelect: () => editor.chain().focus().toggleHeaderColumn().run(),
    },
    {
        key: "toggleHeaderRow",
        value: "toggleHeaderRow",
        label: "Toggle Header Row",
        onSelect: () => editor.chain().focus().toggleHeaderRow().run(),
    },
];
