import { Checkbox } from "components/atoms/checkbox";
import { Framework } from "components/copilot/CopilotSchemaTypes";
import { ToImmutable } from "YJSProvider/LiveObjects";
import * as Accordion from "@radix-ui/react-accordion";
import VolumeAndSectionsTableRow from "components/molecules/volume-and-sections-table-row";
import { ComponentProps, memo, useMemo } from "react";
import { useAppSelector } from "store/storeTypes";
import { FormattedSectionWithRequirementCount } from "types/Volume";

type Props = {
  volumes: ToImmutable<Framework["volumes"]>;
  onCheck: ComponentProps<typeof VolumeAndSectionsTableRow>["onCheck"];
  onPageCountSelect: ComponentProps<typeof VolumeAndSectionsTableRow>["onPageCountSelect"];
  isReadOnly: boolean;
};

const VolumesAndSectionsTable = ({ volumes, onCheck, onPageCountSelect, isReadOnly }: Props) => {
  const extractionMatrix = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.compliance_matrix || []
  );
  const checkedSections = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.sections || []
  );
  const checkedVolumes = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.volumes || []
  );
  const checkedState = useMemo(() => {
    return [...checkedVolumes, ...checkedSections].reduce<Record<string, boolean>>((obj, item) => {
      obj[item] = true;
      return obj;
    }, {});
  }, [checkedSections, checkedVolumes]);
  const isAllChecked = useMemo(() => {
    return (
      !!volumes.length &&
      volumes.every(
        (volume) =>
          checkedVolumes?.includes(volume.id) &&
          volume.sections.every((section) => checkedSections?.includes(section.id))
      )
    );
  }, [checkedSections, checkedVolumes, volumes]);

  const groupedComplianceMatrix = useMemo(() => {
    return (
      extractionMatrix.filter(
        (row) =>
          !!row.requirement.content?.trim() &&
          !row.requirement.soft_deleted &&
          !!row.proposal_reference.section_id &&
          !row.requirement.skipped
      ) || []
    ).reduce<Record<string, number>>((acc, requirement) => {
      const sectionId = requirement.proposal_reference.section_id;
      if (sectionId) acc[sectionId] = (acc[sectionId] || 0) + 1;
      return acc;
    }, {});
  }, [extractionMatrix]);

  const formattedVolumes = useMemo(() => {
    return volumes.map((vol) => {
      let volumeRequirementCount = 0;
      const sections = vol.sections.map((section) => {
        const sectionRequirementCount = groupedComplianceMatrix[section.id] || 0;
        volumeRequirementCount += sectionRequirementCount;
        return {
          ...section,
          requirementCount: sectionRequirementCount,
          checked: !!checkedState[section.id],
        };
      });
      return {
        ...vol,
        checked: !!checkedState[vol.id],
        requirementCount: volumeRequirementCount,
        sections:
          sections.reduce<FormattedSectionWithRequirementCount[]>((acc, section) => {
            if (!section.parent_id) {
              const subsections = sections.filter(({ parent_id }) => parent_id === section.id) || [];
              return [...acc, { ...section, subsections }];
            }

            return acc;
          }, []) || [],
      };
    });
  }, [checkedState, groupedComplianceMatrix, volumes]);

  return (
    <div className="flex flex-col border border-gray-light rounded-md overflow-hidden">
      <div className="flex flex-row border-b border-gray-light py-2 text-sm font-light text-[#667085] items-center bg-[#FCFCFD]">
        <div className="px-4">
          <Checkbox
            variant="primaryBlack"
            disabled={isReadOnly || !volumes.length}
            checked={isAllChecked}
            onCheck={(checked) => {
              const volumes = formattedVolumes.map(({ id }) => id);
              const sections = formattedVolumes.flatMap(({ sections }) =>
                sections.flatMap((section) => [section.id, ...(section.subsections || []).map(({ id }) => id)])
              );
              onCheck(checked, { volumes, sections });
            }}
          />
        </div>
        <div className="flex-1 pl-6">Volume</div>
        <div className="w-36 text-center"># of Requirements</div>
        <div className="w-36 text-center">Page limit</div>
      </div>
      <div className="flex flex-col min-h-52 bg-white">
        <Accordion.Root
          defaultValue={formattedVolumes[0]?.id ? [formattedVolumes[0].id] : undefined}
          type="multiple"
          className="h-full flex-1 relative"
        >
          {formattedVolumes.map((volume) => (
            <VolumeAndSectionsTableRow
              key={volume.id}
              onPageCountSelect={onPageCountSelect}
              isReadOnly={isReadOnly}
              volume={volume}
              onCheck={onCheck}
            />
          ))}
          {!formattedVolumes.length && (
            <div className="text-gray-400 absolute h-full w-full flex items-center justify-center">No Volumes</div>
          )}
        </Accordion.Root>
      </div>
    </div>
  );
};

export default memo(VolumesAndSectionsTable);
