/** @jsxImportSource @emotion/react */
import driveWhite from "Assets/drive-white.svg";
import folderOpenSm from "Assets/folder-open-white.svg";
import lod from "Assets/loader.svg";
import searchIcon from "Assets/search-normal.png";
import cloud_upload from "Assets/cloud-upload.svg";
import ExtractingDocumentToast from "components/LoadingToast";
import NotFoundItems from "components/NotFoundItems";
import { useEffect, useRef, useState } from "react";
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import {
  fetchMediaStorage,
  setIsDragOverDrive,
  setMediaNavHistory,
  setSelectedFolderHeading,
  updateMediaRootDocs,
  updateMediaRootFolders,
} from "store/reducers/driveReducerSlice";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { Subdirectory, WorkspaceMedia } from "types/FileStorage";
import { ACCEPT_MEDIA_TYPES } from "../constants";
import AddMediaFileModal from "../modals/AddMediaFileModal";
import AddMediaFolderModal from "../modals/AddMediaFolderModal";
import MoveModal from "../modals/MoveModal";
import PreviewMediaModal from "../modals/PreviewMediaModal";
import UpdateMediaFileModal from "../modals/UpdateMediaFileModal";
import UpdateMediaFolderModal from "../modals/UpdateMediaFolderModal";
import Folder from "./Folder";
import Media from "./Media";
import { useDropValidator, useMediaMutateOperations, useMediaSearch, useNestedDriveMedia } from "./hooks";
import { useNotification } from "context/notificationContext";
import { useParams } from "react-router-dom";
import { Plus } from "lucide-react";

const MediaContent = () => {
  const { isSearching, isSearchActive, searchResults, onKeywordChange, keyword, setDirectoryId } = useMediaSearch();
  const [isFolderAddModalOpen, setIsFolderAddModalOpen] = useState(false);
  const [isFileAddModalOpen, setIsFileAddModalOpen] = useState(false);
  const [loadingToast, setLoadingToast] = useState({ loading: false, files: 1 });
  const { createFile, isCreatingFile } = useMediaMutateOperations();
  const dispatch = useAppDispatch();
  const {
    selectedFolderHeading,
    isDragOverDrive,
    isLoading: isMediaLoading,
  } = useAppSelector((root) => root.drive.media);
  const [isMoveMediaFolderModalOpen, setIsMoveMediaFolderModalOpen] = useState(false);
  const [isUpdateMediaFolderModalOpen, setIsUpdateMediaFolderModalOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<Subdirectory | null>(null);
  const [isUpdateMediaFileModalOpen, setIsUpdateMediaFileModalOpen] = useState(false);
  const [isMoveMediaFileModalOpen, setIsMoveMediaFileModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<WorkspaceMedia | null>(null);
  const { setToast } = useNotification();
  const { folderId } = useParams();
  const {
    currentFiles,
    currentSubdirectories,
    nextNestedDriveMediaState,
    currentNestedDriveMediaState,
    setCurrentNestedDriveMediaState,
  } = useNestedDriveMedia();

  const dropValidator = useDropValidator();

  useEffect(() => {
    setDirectoryId(folderId || "");
  }, [folderId, setDirectoryId]);

  const { getRootProps: getMainRootProps, getInputProps: getMainInputProps } = useDropzone({
    validator: dropValidator,
    maxFiles: 10,
    onDropRejected: (fileRejections: FileRejection[]) => {
      for (const fileRejection of fileRejections) {
        for (const error of fileRejection.errors) {
          if (error.code === ErrorCode.TooManyFiles) {
            setToast.error({
              title: "Upload too many images",
              msg: "You can only upload 10 images at a time",
            });
          }
        }
      }
    },
    onDrop: (acceptedFiles: File[]) => {
      if (isFileAddModalOpen) return;
      dispatch(setSelectedFolderHeading(""));
      dispatch(setIsDragOverDrive(false));
      if (acceptedFiles.length) {
        Promise.all(
          acceptedFiles.map((file) =>
            createFile({
              file,
              user_tags: [],
              parent_directory_id: currentNestedDriveMediaState?.current_directory?.id,
            })
          )
        ).then((files) => onMediaFileCreated(files));
      }
    },
    onDragOver: (e) => {
      e.preventDefault();
      if (isFileAddModalOpen) return;
      if (currentNestedDriveMediaState?.current_directory) {
        dispatch(setSelectedFolderHeading(currentNestedDriveMediaState?.current_directory?.name));
      } else {
        dispatch(setIsDragOverDrive(true));
      }
    },
    onDragLeave: (e) => {
      const relatedTarget = e.relatedTarget as any;
      if (!relatedTarget || (relatedTarget !== e.currentTarget && !e.currentTarget.contains(relatedTarget))) {
        dispatch(setIsDragOverDrive(false));
        dispatch(setSelectedFolderHeading(""));
      }
    },
    accept: ACCEPT_MEDIA_TYPES,
    noClick: true,
  });

  const onMediaFileCreated = (newFiles: WorkspaceMedia[], dragToFolder = false) => {
    if (dragToFolder) {
      return;
    }
    if (!currentNestedDriveMediaState?.current_directory) {
      dispatch(updateMediaRootDocs([...currentFiles, ...newFiles]));
    } else {
      setCurrentNestedDriveMediaState({
        current_directory: currentNestedDriveMediaState?.current_directory ?? null,
        files: [...currentFiles, ...newFiles],
        subdirectories: currentSubdirectories,
      });
    }
  };

  const onFolderCreated = (newFolder: Subdirectory) => {
    if (!currentNestedDriveMediaState?.current_directory) {
      dispatch(updateMediaRootFolders([...currentSubdirectories, newFolder]));
    } else {
      setCurrentNestedDriveMediaState({
        current_directory: currentNestedDriveMediaState?.current_directory ?? null,
        files: currentFiles,
        subdirectories: [...currentSubdirectories, newFolder],
      });
    }
    dispatch(fetchMediaStorage());
  };

  const onMove = (moveType: "directory" | "file", itemId: string) => {
    if (!currentNestedDriveMediaState?.current_directory) {
      if (moveType === "file") {
        dispatch(updateMediaRootDocs(currentFiles.filter((media) => media.id !== itemId)));
      } else if (moveType === "directory") {
        dispatch(updateMediaRootFolders(currentSubdirectories.filter((folder) => folder.id !== itemId)));
        dispatch(fetchMediaStorage());
      }
    } else {
      if (moveType === "file") {
        setCurrentNestedDriveMediaState({
          current_directory: currentNestedDriveMediaState?.current_directory,
          files: currentFiles.filter((media) => media.id !== itemId),
          subdirectories: currentSubdirectories,
        });
      } else if (moveType === "directory") {
        setCurrentNestedDriveMediaState({
          current_directory: currentNestedDriveMediaState?.current_directory,
          files: currentFiles,
          subdirectories: currentSubdirectories.filter((folder) => folder.id !== itemId),
        });
        dispatch(fetchMediaStorage());
      }
    }
  };

  const [isInitialFetch, setIsInitialFetch] = useState(false);
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (isMediaLoading && firstUpdate.current) {
      setIsInitialFetch(true);
      firstUpdate.current = false;
    } else setIsInitialFetch(false);
  }, [isMediaLoading]);

  const filesToDisplay = isSearchActive ? searchResults : currentFiles;
  return (
    <div className="relative flex-grow bg-white" {...getMainRootProps()}>
      <input multiple {...getMainInputProps()} />
      {/* --------- DOTTED LINE APPEARS ON DRAG AND DROP ----------- */}
      <div
        className="absolute w-full h-full pointer-events-none z-50"
        css={[
          (isDragOverDrive || selectedFolderHeading) &&
            !isFileAddModalOpen &&
            tw`border-[3px] border-dashed border-action`,
        ]}
      />

      <div className="relative">
        {/* ---------------------------------- SECTION SEARCH -------------------------------- */}
        <section className="pt-2 pb-6 px-1">
          <div className="flex-1 relative">
            <img src={searchIcon} alt="search icon" loading="lazy" className="absolute top-2.5 left-1.5 w-5" />
            <input
              placeholder="Search keywords..."
              className="bg-white focus:outline-none block focus:ring-0 focus:ring-gray-200 text-sm text-gray-500 w-full h-10 py-3 pl-9 pr-3 rounded-md border border-gray-light"
              value={keyword}
              onChange={(e) => onKeywordChange(e.target.value)}
            />
          </div>
        </section>

        {/* ---------------------------- FOLDERS SECTION ------------------------------ */}
        {!isSearchActive && (
          <section className="folders-section min-h-[150px] pt-1 px-1 bg-white">
            <div className="flex justify-between items-center mb-6">
              <h5 className="font-medium text-md">Folders</h5>
              <div
                className="flex items-center gap-1 rounded-md cursor-pointer px-1.5 py-1  bg-gray-100 hover:border-gray-300 border border-gray-200"
                onClick={() => {
                  setIsFolderAddModalOpen(true);
                }}
              >
                <span className="text-sm font-semibold">Folder</span>
                <button onClick={() => {}}>
                  <Plus size={14} />
                </button>
              </div>
            </div>

            {isInitialFetch && !currentSubdirectories.length ? (
              <div className="w-full flex justify-center flex-col py-4 px-2">
                <img className="mx-auto max-w-full" src={lod} alt="Loading..." />
                <div className="flex-grow" />
              </div>
            ) : !!currentSubdirectories.length ? (
              <div className="cards grid grid-cols-4 2xl:flex 2xl:flex-wrap gap-x-[20px] gap-y-5 mb-8">
                {currentSubdirectories.map((folder) => (
                  <Folder
                    onMove={onMove}
                    key={folder.id}
                    folder={folder}
                    onMediaFileCreated={onMediaFileCreated}
                    onFolderDeleted={(id: string) => {
                      if (!currentNestedDriveMediaState?.current_directory) {
                        dispatch(updateMediaRootFolders(currentSubdirectories.filter((folder) => folder.id !== id)));
                      } else {
                        setCurrentNestedDriveMediaState({
                          current_directory: currentNestedDriveMediaState?.current_directory,
                          files: currentFiles,
                          subdirectories: currentSubdirectories.filter((folder) => folder.id !== id),
                        });
                      }
                    }}
                    setSelectFolder={setSelectedFolder}
                    onClick={() => {
                      setCurrentNestedDriveMediaState(nextNestedDriveMediaState?.[folder.id]);
                      dispatch(
                        setMediaNavHistory([
                          {
                            name: folder.name,
                            id: folder.id,
                          },
                        ])
                      );
                    }}
                    setIsMoveMediaFolderModalOpen={setIsMoveMediaFolderModalOpen}
                    setIsUpdateMediaFolderModalOpen={setIsUpdateMediaFolderModalOpen}
                  />
                ))}
              </div>
            ) : (
              <div className="pb-10">
                <h5 className="text-base text-center font-semibold text-[#1D2630]">No Folders</h5>
                <p className="text-[#353535] text-center pt-[5px]">
                  Create a folder by clicking on the “Folder +” button.
                </p>
              </div>
            )}

            <AddMediaFolderModal
              onFolderCreated={onFolderCreated}
              open={isFolderAddModalOpen}
              onOpenChange={setIsFolderAddModalOpen}
              currentDirectory={currentNestedDriveMediaState?.current_directory}
            />

            {selectedFolder && (
              <UpdateMediaFolderModal
                onFolderUpdated={(name: string) => {
                  if (!currentNestedDriveMediaState?.current_directory) {
                    dispatch(
                      updateMediaRootFolders(
                        currentSubdirectories.map((folder) =>
                          folder.id === selectedFolder?.id ? { ...folder, name } : folder
                        )
                      )
                    );
                  } else {
                    setCurrentNestedDriveMediaState({
                      current_directory: currentNestedDriveMediaState?.current_directory,
                      files: currentFiles,
                      subdirectories: currentSubdirectories.map((folder) =>
                        folder.id === selectedFolder?.id ? { ...folder, name } : folder
                      ),
                    });
                  }
                }}
                open={isUpdateMediaFolderModalOpen}
                onOpenChange={setIsUpdateMediaFolderModalOpen}
                folder={selectedFolder}
                onFolderSelected={setSelectedFolder}
              />
            )}

            {selectedFolder && (
              <MoveModal
                open={isMoveMediaFolderModalOpen}
                onOpenChange={setIsMoveMediaFolderModalOpen}
                moveType={"directory"}
                currentDirectory={currentNestedDriveMediaState?.current_directory}
                selectedItem={selectedFolder}
                setSelectedFolder={setSelectedFolder}
                onMove={onMove}
              />
            )}
          </section>
        )}

        {/* -------------------------- SECTION MEDIA -------------------------------- */}
        <section className="document-section px-1 relative mb-9 bg-white">
          <div className="flex items-center justify-between mb-6">
            <h5 className="font-medium text-md">Graphics</h5>
            {!isSearchActive && (
              <div
                onClick={() => setIsFileAddModalOpen(true)}
                className="flex items-center gap-1 rounded-md cursor-pointer px-1.5 py-1  bg-gray-100 hover:border-gray-300 border border-gray-200"
              >
                <span className="text-sm font-semibold">Graphic</span>
                <span className="relative block cursor-pointer">
                  <Plus size={14} />
                </span>
              </div>
            )}
          </div>
          {isInitialFetch || isSearching ? (
            <div className="w-full flex justify-center flex-col py-14 px-2">
              <img className="mx-auto max-w-full" src={lod} alt="Loading..." />
              <div className="flex-grow" />
            </div>
          ) : (
            <>
              {!!filesToDisplay.length && (
                <div className="cards grid grid-cols-4 2xl:flex 2xl:flex-wrap gap-x-[20px] gap-y-5">
                  {filesToDisplay.map((file) => (
                    <Media
                      onMediaFileDeleted={(id: string) => {
                        if (!currentNestedDriveMediaState?.current_directory) {
                          dispatch(updateMediaRootDocs(currentFiles.filter((media) => media.id !== id)));
                        } else {
                          setCurrentNestedDriveMediaState({
                            current_directory: currentNestedDriveMediaState?.current_directory,
                            files: currentFiles.filter((media) => media.id !== id),
                            subdirectories: currentSubdirectories,
                          });
                        }
                      }}
                      key={file.id}
                      media={file}
                      setSelectedMedia={setSelectedMedia}
                      setIsMoveMediaFileModalOpen={setIsMoveMediaFileModalOpen}
                      setIsUpdateMediaFileModalOpen={setIsUpdateMediaFileModalOpen}
                      setIsPreviweModalOpen={setIsPreviewModalOpen}
                    />
                  ))}
                </div>
              )}
              {isSearchActive && !filesToDisplay.length && (
                <NotFoundItems
                  title="No graphics found"
                  subTitle="No matching results. Try another search."
                  className={"w-full flex mt-24 justify-center items-center bg-inherit"}
                />
              )}
              {!isSearchActive && !filesToDisplay.length && (
                <>
                  <div
                    onClick={() => setIsFileAddModalOpen(true)}
                    className="p-3 w-full mx-auto cursor-pointer relative border-gray-300 border-dashed border-2 m-3 justify-center flex flex-col items-center rounded-lg bg-white min-h-[260px]"
                  >
                    <img className="w-9 h-9 mb-2" src={cloud_upload} alt="" />
                    <span className="font-semibold pointer-events-none">Drag and drop files here</span>
                    <span className="text-gray-text pointer-events-none pt-[4px]">We accept jpeg and png files</span>
                  </div>
                  <div className="mb-5" />
                </>
              )}
            </>
          )}

          <AddMediaFileModal
            currentNestedDriveMediaState={currentNestedDriveMediaState}
            open={isFileAddModalOpen}
            onOpenChange={setIsFileAddModalOpen}
            onMediaFileCreated={onMediaFileCreated}
          />

          {selectedMedia && (
            <UpdateMediaFileModal
              onMediaFileUpdated={(file: WorkspaceMedia) => {
                if (!currentNestedDriveMediaState?.current_directory) {
                  dispatch(updateMediaRootDocs(currentFiles.map((media) => (media.id === file.id ? file : media))));
                } else {
                  setCurrentNestedDriveMediaState({
                    current_directory: currentNestedDriveMediaState?.current_directory,
                    files: currentFiles.map((media) => (media.id === file.id ? file : media)),
                    subdirectories: currentSubdirectories,
                  });
                }
              }}
              open={isUpdateMediaFileModalOpen}
              onOpenChange={setIsUpdateMediaFileModalOpen}
              selectedItem={selectedMedia}
            />
          )}

          {selectedMedia && (
            <MoveModal
              open={isMoveMediaFileModalOpen}
              onOpenChange={setIsMoveMediaFileModalOpen}
              moveType={"file"}
              currentDirectory={currentNestedDriveMediaState?.current_directory}
              selectedItem={selectedMedia}
              onMove={onMove}
            />
          )}

          {selectedMedia && (
            <PreviewMediaModal
              open={isPreviewModalOpen}
              onOpenChange={setIsPreviewModalOpen}
              selectedMedia={selectedMedia}
            />
          )}
        </section>
      </div>

      {/* ------------- Drag and Drop Toast -------------------- */}
      {(isDragOverDrive || selectedFolderHeading) && !isFileAddModalOpen && (
        <div className="absolute bottom-24 w-full pointer-events-none z-50">
          <div className="absolute left-1/2 transform -translate-x-1/2 bg-gray-darkest py-3.5 px-16 rounded-full text-center">
            <span className="text-lg opacity-90 text-[#ffffff] block mb-1.5">Drop files to upload them to</span>

            {selectedFolderHeading && (
              <span className="text-lg font-semibold text-[#ffffff] flex justify-center items-center gap-2">
                <img src={folderOpenSm} alt="icon" loading="lazy" className="w-[18px] h-[18px] bg-[#fffffff]" />{" "}
                {selectedFolderHeading}
              </span>
            )}

            {isDragOverDrive && (
              <span className="text-lg font-semibold text-[#ffffff] flex justify-center items-center gap-2">
                <img src={driveWhite} alt="icon" loading="lazy" className="w-[18px] h-[18px] bg-[#fffffff]" /> Content
                Library
              </span>
            )}
          </div>
        </div>
      )}

      {isCreatingFile && (
        <ExtractingDocumentToast
          loadingText={`Uploading ${loadingToast?.files > 1 ? "Graphics" : "Graphic"}`}
          handleClose={() => setLoadingToast({ loading: isCreatingFile, files: 1 })}
        />
      )}
    </div>
  );
};

export default MediaContent;
