import { SectionStatus } from "components/copilot/CopilotSchemaImmutableTypes";
import { Section, SectionManager, Volume } from "components/copilot/CopilotSchemaTypes";
import { nanoid } from "nanoid";
import { LiveList, LiveObject } from "YJSProvider/LiveObjects";

export const createVolume = (properties?: Partial<Volume>): LiveObject<Volume> => {
  const newID = nanoid();
  return new LiveObject({
    id: newID,
    title: "",
    sections: new LiveList([]),
    updated_at: new Date().toISOString(),
    assignees: [],
    ...properties,
  });
};

export const createSection = (properties?: Partial<Section>): { id: string; item: LiveObject<Section> } => {
  const newID = nanoid();
  return {
    id: properties?.id || newID,
    item: new LiveObject({
      id: newID,
      title: "",
      proposal: "",
      proposal_sources: new LiveList([]),
      assignees: [],
      status: SectionStatus.Todo,
      section_manager: createSectionManager(),
      ...properties,
    }),
  };
};

export const createSectionManager = (properties?: Partial<SectionManager>): Section["section_manager"] => {
  return new LiveObject({
    isIgnored: false,
    ...properties,
  });
};
