import { useState, useEffect, useMemo } from "react";

const useTypewriter = (text: string, speed = 50, reset?: boolean) => {
  const [index, setIndex] = useState(0);
  const displayText = useMemo(() => text.slice(0, index), [index, text]);

  useEffect(() => {
    if (index >= text.length) return;

    const timeoutId = setTimeout(() => {
      setIndex((i) => i + 1);
    }, speed);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [index, text, speed]);

  useEffect(() => {
    if (reset) setIndex(0);
  }, [reset]);

  return displayText;
};

export default useTypewriter;
