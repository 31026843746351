import {
  ComplianceMatrixRow,
  Extraction,
  ExtractionStatus,
  InstantDraftStatus,
} from "components/copilot/CopilotSchemaTypes";
import { StepValue } from "components/copilot/extract-v2/doc-viewer";
import { nanoid } from "nanoid";
import { ResponseTolerance } from "types/Requirement";
import { LiveList, LiveObject, ToImmutable } from "YJSProvider/LiveObjects";

export const createExtraction = (properties?: Partial<Extraction>): LiveObject<Extraction> => {
  const newID = nanoid();
  return new LiveObject({
    id: newID,
    name: `Extraction_${newID.slice(0, 6)}`,
    status: ExtractionStatus.Extracting,
    file_ids: new LiveList([]),
    step: StepValue.Template,
    compliance_matrix: new LiveList([]),
    framework: new LiveObject({ volumes: new LiveList([]) }),
    created_at: new Date().toISOString(),
    instantDraftConfig: createInstantDraftConfig(),
    ...properties,
  });
};

export const createInstantDraftConfig = (
  properties?: Partial<Extraction["instantDraftConfig"]>
): LiveObject<NonNullable<Extraction["instantDraftConfig"]>> => {
  return new LiveObject({
    strictness: ResponseTolerance.Strict,
    confirmations: new LiveList([]),
    volumes: new LiveList([]),
    sections: new LiveList([]),
    status: InstantDraftStatus.Todo,
    submittedRequirements: [],
    ...properties,
  });
};

export const getSortedRequirementsBySectionOrder = (requirements: ToImmutable<Extraction["compliance_matrix"]>) =>
  [...requirements].sort((a, b) => (a.requirement?.section_order || 0) - (b.requirement?.section_order || 0));

export const getLiveSortedRequirementsBySectionOrder = (requirements: LiveObject<ComplianceMatrixRow>[]) =>
  [...requirements].sort(
    (a, b) => (a.get("requirement")?.get("section_order") || 0) - (b.get("requirement")?.get("section_order") || 0)
  );
