import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { CaptureSchemaType, Form } from "types/Capture";

export const CAPTURE_FORMS_QUERY_KEY = "capture/forms";

type Params = {
  schema_type?: CaptureSchemaType;
  project_id?: string;
};

export const getFormsQueryFn = (variables: Params) =>
  axios.get("/forms/", { params: variables }).then((resp) => resp.data);

const useGetForms = (params?: Params, options?: Partial<UseQueryOptions<Form[], Error>>) => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id") || undefined;
  const variables = { ...params, project_id: projectId };

  const { data = [], ...rest } = useQuery({
    queryKey: [CAPTURE_FORMS_QUERY_KEY, variables],
    gcTime: 10 * 60 * 1000, // 10 minutes
    queryFn: () => getFormsQueryFn(variables),
    ...options,
  });

  return {
    data,
    queryKey: [CAPTURE_FORMS_QUERY_KEY, variables],
    ...rest,
  };
};

export default useGetForms;
