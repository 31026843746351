import { toggleCreateProjectModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";
import Icon from "components/atoms/icons/Icon";

const NotFound = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="min-h-[84vh] bg-white w-full flex justify-center items-center flex-col py-7 px-2">
      <div className="text-center mt-5">
        <h1 className="font-bold text-xl mb-1">No projects found</h1>
        <span className="text-gray-text text-sm mt-3">Create a new project to get started</span>
      </div>
      <button
        className="bg-gray-200 flex min-w-[40px] my-5 min-h-[26px] text-gray-600 gap-1 py-1.5 pr-3.5 pl-2 text-sm items-center rounded-md"
        onClick={() => dispatch(toggleCreateProjectModal({ open: true }))}
      >
        <Icon name="Plus" className="w-4 h-4" />
        <span>New Project</span>
      </button>
    </div>
  );
};

export default NotFound;
