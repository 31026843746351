/** @jsxImportSource @emotion/react */

import { Checkbox } from "components/atoms/checkbox";
import { LucidePlus } from "lucide-react";
import tw from "twin.macro";
import { FormattedSectionWithRequirementCount } from "types/Volume";
import DropdownMenu from "../dropdown-menu";
import { useMemo, useState } from "react";
import { MenuItem } from "../dropdown-menu/DropdownMenu";
import { EMPTY_VALUE } from "const-values/Copilot";

type Props = {
  isReadOnly: boolean;
  section: FormattedSectionWithRequirementCount;
  showConnection?: boolean;
  isLastSection?: boolean;
  onCheck: (checked: boolean, sections: string[]) => void;
  onPageCountSelect: (sectionId: string, count: number) => void;
};

const SectionRow = ({
  section,
  showConnection = true,
  onCheck,
  onPageCountSelect,
  isReadOnly,
  isLastSection,
}: Props) => {
  const [sectionRowContainer, setSectionRowContainer] = useState<HTMLDivElement | null>(null);
  const isSubsection = !!section.parent_id;
  const numberItems: MenuItem<number>[] = useMemo(
    () =>
      Array.from({ length: 100 }, (_, i) => ({
        key: i,
        label: i || EMPTY_VALUE,
        onSelect: () => onPageCountSelect(section.id, i),
      })),
    [onPageCountSelect, section.id]
  );

  return (
    <>
      <div
        ref={setSectionRowContainer}
        className="flex flex-row gap-0.5 items-center justify-between"
        css={[isSubsection && tw`ml-8`]}
      >
        <div className="px-4 relative">
          <Checkbox
            variant="primaryBlack"
            disabled={isReadOnly}
            checked={section.checked}
            onCheck={(checked) =>
              onCheck(
                checked,
                isSubsection ? [section.id] : [section.id, ...(section.subsections || []).map(({ id }) => id)].flat()
              )
            }
          />
          {showConnection && (
            <div className="absolute top-[calc(100%+8px)] w-0.5 rounded bg-gray-300 h-3 left-[calc(50%-1px)]" />
          )}
        </div>
        <div className="flex flex-row items-center w-[calc(100%-50px)]">
          <div title={section.title} className="flex flex-row group flex-1 gap-1.5 items-center min-w-0 duration-100">
            <div className="flex flex-row justify-between items-center text-gray-500 min-w-0 text-sm py-3 w-full">
              <span
                className="whitespace-nowrap font-normal truncate"
                css={[!section.title.trim() && tw`text-gray-400`]}
              >
                {section.title.trim() || "Section title..."}
              </span>
            </div>
          </div>
          <div className="w-36 text-center text-sm text-gray-500">{section.requirementCount}</div>
          <div className="w-36 flex justify-center text-sm">
            <DropdownMenu
              disabled={isReadOnly}
              contentProps={{ css: tw`max-h-[200px] min-w-[52px]` }}
              items={numberItems}
              portalProps={{ container: sectionRowContainer }}
            >
              <div
                className="bg-gray-100 text-gray-500 rounded flex items-center justify-center h-6 min-w-6 border border-dashed border-gray-300 duration-100 hover:text-gray-darkest hover:border-gray-darkest"
                css={[!!section.draft_page_count && tw`border-solid`]}
              >
                {!section.draft_page_count ? <LucidePlus size={14} /> : section.draft_page_count}
              </div>
            </DropdownMenu>
          </div>
        </div>
      </div>
      {section.subsections?.map((subsection, i) => (
        <SectionRow
          onPageCountSelect={onPageCountSelect}
          key={subsection.id}
          onCheck={onCheck}
          isReadOnly={isReadOnly}
          section={subsection}
          showConnection={!((section.subsections?.length || 0) - 1 === i)}
        />
      ))}
    </>
  );
};

export default SectionRow;
