/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { useSelector } from "react-redux";
import Popover from "../../atoms/popover";
import { SmilePlus } from "lucide-react";
import { useAppSelector } from "store/storeTypes";
import tw from "twin.macro";

interface ReactionBarProps {
  reactions: Record<string, string[]>;
  onReaction: (emoji: string) => void;
  setDisableScroll: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReactionBar: React.FC<ReactionBarProps> = ({ reactions, onReaction, setDisableScroll }) => {
  const [showPicker, setShowPicker] = useState(false);
  // get all workspace users
  const workspaceMembers = useSelector((store: any) => store.auth?.workspaceMembers);
  const currentUser = useAppSelector((store) => store.auth.currentUser);

  const handleEmojiSelect = (emoji: { native: string }) => {
    onReaction(emoji.native);
    setShowPicker(false);
    setDisableScroll(false);
  };

  const handlePickerOpenChange = (open: boolean) => {
    setShowPicker(open);
    setDisableScroll(open);
  };

  const handleUsernamesReaction = (reaction: string) => {
    // filter users in workspace with users who have reactions
    const usersWithReaction = workspaceMembers.filter(
      (member: { id: string }) => member.id !== currentUser?.id && reactions[member.id]?.includes(reaction)
    );

    return usersWithReaction.map((user: { username: string }) => user.username).join(", ");
  };

  const currentUserReactions = (reactions || {})[currentUser?.id || ""] || [];

  // group reactions by emoji and count the number of reactions per emoji
  const groupedReactions = Object.values(reactions)
    .flat()
    .reduce((acc: Record<string, number>, reaction) => {
      if (acc[reaction]) {
        acc[reaction] += 1;
      } else {
        acc[reaction] = 1;
      }
      return acc;
    }, {});

  const reactionEntries = Object.entries(groupedReactions);

  return (
    <div className="flex flex-wrap items-center gap-1">
      {reactionEntries.map(([emoji, count]) => {
        const usernameReactionList = handleUsernamesReaction(emoji);
        return (
          <button
            key={emoji}
            onClick={() => onReaction(emoji)}
            className="px-2 py-0.5 text-gray-600 h-[30px] duration-100 border border-transparent rounded-full bg-gray-100 hover:bg-gray-200 flex items-center active:scale-95"
            css={[currentUserReactions.includes(emoji) && tw`bg-gray-darkest/10 text-gray-darkest border-gray-600`]}
          >
            <Tooltip disabled={!usernameReactionList.length} content={`${usernameReactionList} reacted with ${emoji}`}>
              <div>
                <span>{emoji}</span>
                <span className="ml-2 text-sm">{count}</span>
              </div>
            </Tooltip>
          </button>
        );
      })}
      <Popover
        open={showPicker}
        onOpenChange={handlePickerOpenChange}
        content={<Picker data={data} onEmojiSelect={handleEmojiSelect} theme="light" />}
      >
        <button
          className="duration-100 border border-transparent hover:bg-gray-200 active:scale-95"
          css={[
            !!reactionEntries.length && tw`rounded-full px-2 py-0.5 bg-gray-100 h-[30px] text-gray-600`,
            !reactionEntries.length && tw`rounded-md px-1 py-1 text-gray-light hover:text-gray-darkest`,
          ]}
        >
          <SmilePlus height={18} width={18} />
        </button>
      </Popover>
    </div>
  );
};
