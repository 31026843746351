import dots from "Assets/3dots.svg";
import dltLoader from "Assets/delete-loader.svg";
import folderOpenIcon from "Assets/folder-open.svg";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  setDraggingDocId,
  setDraggingFolderId,
  setIsDragOverDrive,
  setSelectedFolderHeading,
} from "store/reducers/driveReducerSlice";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { Subdirectory, WorkspaceMedia } from "types/FileStorage";
import { MoveIcon } from "utils/icons";
import { ACCEPT_MEDIA_TYPES, MAX_MEDIA_FILE_SIZE } from "../constants";
import { useMediaMutateOperations } from "./hooks";
import ExtractingDocumentToast from "components/LoadingToast";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { Pencil, Trash2 } from "lucide-react";

interface Props {
  folder: Subdirectory;
  onClick: () => void;
  setSelectFolder: (folder: Subdirectory) => void;
  onMediaFileCreated: (files: WorkspaceMedia[], dragToFolder: boolean) => void;
  onFolderDeleted: (id: string) => void;
  onMove: (moveType: "directory" | "file", itemId: string) => void;
  setIsUpdateMediaFolderModalOpen: (isOpen: boolean) => void;
  setIsMoveMediaFolderModalOpen: (isOpen: boolean) => void;
}

export default function Folder({
  folder,
  onClick,
  setSelectFolder,
  onMediaFileCreated,
  onFolderDeleted,
  onMove,
  setIsUpdateMediaFolderModalOpen,
  setIsMoveMediaFolderModalOpen,
}: Props) {
  const [loadingToast, setLoadingToast] = useState({ loading: false, files: 1 });
  const [folderHoverStyles, setFolderHoverStyles] = useState("");
  const { isDeletingFolder, isCreatingFile, deleteFolder, moveFile, moveFolder, createFile } =
    useMediaMutateOperations();
  const dispatch = useAppDispatch();
  const { selectedFolderHeading, draggingDocId, draggingFolderId } = useAppSelector((root) => root.drive.media);

  function handleDragOver(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (draggingDocId) {
      setFolderHoverStyles("border-action bg-[#DBE0E5]");
      return;
    }
    if (draggingFolderId) return;
    dispatch(setSelectedFolderHeading(folder.name));
    dispatch(setIsDragOverDrive(false));
  }

  function handleDragLeave(e: any) {
    const relatedTarget = e.relatedTarget;
    if (!relatedTarget || (relatedTarget !== e.currentTarget && !e.currentTarget.contains(relatedTarget))) {
      dispatch(setSelectedFolderHeading(""));
      setFolderHoverStyles("");
    }
  }

  function handleDrag(e: any) {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setDraggingFolderId(folder.id));
    dispatch(setDraggingDocId(""));
  }

  async function handleDrop(e: any) {
    e.preventDefault();
    e.stopPropagation();

    /// if files are dragged from computer directly into folders
    if (selectedFolderHeading) {
      const droppedFiles: File[] = Array.from(e.dataTransfer.files);
      dispatch(setSelectedFolderHeading(""));
      dispatch(setIsDragOverDrive(false));
      const validFiles = droppedFiles.filter(
        (file: File) => file.size <= MAX_MEDIA_FILE_SIZE && Object.keys(ACCEPT_MEDIA_TYPES).includes(file.type)
      );
      for (const file of droppedFiles)
        if (validFiles.length > 0) {
          createFile({
            parent_directory_id: folder.id,
            file,
            user_tags: [],
          }).then((res) => {
            if (res) {
              onMediaFileCreated(res, true);
            }
          });
        }
    }

    //// if file is dragged into a folder
    if (draggingDocId) {
      moveFile(draggingDocId, { parent_directory_id: folder.id }).then((res) => {
        if (res) {
          onMove("file", draggingDocId);
        }
      });
    }

    ///// if folder is dragged into another folder
    if (draggingFolderId && draggingFolderId !== folder.id) {
      moveFolder(draggingFolderId, { parent_directory_id: folder.id }).then((res) => {
        if (res) {
          onMove("directory", draggingFolderId);
        }
      });
    }

    setFolderHoverStyles("");
    dispatch(setDraggingFolderId(""));
    dispatch(setDraggingDocId(""));
  }

  const menuItems = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-2">
            <Pencil size={14} /> Rename
          </div>
        ),
        onSelect: () => {
          setSelectFolder(folder);
          setIsUpdateMediaFolderModalOpen(true);
        },
      },
      {
        key: 2,
        label: (
          <div className="flex items-center gap-2">
            <MoveIcon />
            Move
          </div>
        ),
        onSelect: () => {
          setSelectFolder(folder);
          setIsMoveMediaFolderModalOpen(true);
        },
      },
      {
        key: 3,
        label: (
          <div className="flex items-center gap-2 text-red-500">
            {isDeletingFolder ? <img src={dltLoader} alt="icon" width={14} height={14} /> : <Trash2 size={14} />}
            Delete
          </div>
        ),
        disabled: isDeletingFolder,
        onSelect: async () => {
          const res = await deleteFolder(folder.id);
          if (res) {
            onFolderDeleted(folder.id);
          }
        },
      },
    ],
    [
      deleteFolder,
      folder,
      isDeletingFolder,
      onFolderDeleted,
      setIsMoveMediaFolderModalOpen,
      setIsUpdateMediaFolderModalOpen,
      setSelectFolder,
    ]
  );

  return (
    <Link
      title={folder.name}
      to={`/dashboard/library/media/folder/${folder.id}`}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <div
        className={`flex items-center gap-2.5 h-full hover:bg-hover-default relative px-2.5 py-2.5 rounded-lg shadow-[0_0px_5px_rgba(27,46,94,0.17)] 2xl:w-[258px] ${
          folderHoverStyles || "bg-white"
        }`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDrag={handleDrag}
        onDragLeave={handleDragLeave}
        draggable
      >
        <img
          src={folderOpenIcon}
          alt="Folder Open Icon"
          loading="lazy"
          className="w-[24px] h-[24px] pointer-events-none"
        />
        <div className="flex-1">
          <div className="flex justify-between align-items-center">
            <div>
              <h4 className="font-semibold text-sm break-words">
                {folder.name.length <= 17 ? folder.name : folder.name.substring(0, 17) + "..."}
              </h4>
              <p className="text-[11px] text-[#5B6B79]">{folder.all_nested_files?.length ?? 0} Graphics</p>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              className="flex items-center"
            >
              <DropdownMenu items={menuItems}>
                <div className="cursor-pointer ml-auto">
                  <img src={dots} alt="Three Dots" className="max-w-full mx-auto pointer-events-none object-contain" />
                </div>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>

      {isCreatingFile && (
        <ExtractingDocumentToast
          loadingText={`Uploading ${loadingToast?.files > 1 ? "Graphics" : "Graphic"}`}
          handleClose={() => setLoadingToast({ loading: isCreatingFile, files: 1 })}
        />
      )}
    </Link>
  );
}
