import { useEffect, useState } from "react";

export default function usePersistedStorage<T>(key: string, defaultValue?: T): [T | undefined, (value: T) => void] {
  const [value, setValue] = useState(() => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  });

  useEffect(() => {
    const item = localStorage.getItem(key);

    if (!item && defaultValue) {
      localStorage.setItem(key, JSON.stringify(defaultValue));
    }

    setValue(item ? JSON.parse(item) : defaultValue);

    function handler(e: StorageEvent) {
      if (e.key !== key) return;

      setValue(JSON.parse(e.newValue ?? '""'));
    }

    window.addEventListener("storage", handler);

    return () => {
      window.removeEventListener("storage", handler);
    };
  }, [defaultValue, key]);

  const setValueWrap = (value: T) => {
    try {
      setValue(value);
      const newStringifiedValue = JSON.stringify(value);
      localStorage.setItem(key, newStringifiedValue);
      if (typeof window !== "undefined") {
        window.dispatchEvent(new StorageEvent("storage", { key, newValue: newStringifiedValue }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  return [value, setValueWrap];
}
