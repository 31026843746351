export const parseArrayBufferToChunks = (arrayBuffer: ArrayBuffer): Uint8Array[] => {
  const dataView = new DataView(arrayBuffer);
  let offset = 0;
  const chunks: Uint8Array[] = [];

  while (offset < dataView.byteLength) {
    // Read the length of the next update (first 4 bytes)
    const updateLength = dataView.getUint32(offset);
    offset += 4;

    // Extract the update bytes based on the length
    const updateBytes = new Uint8Array(arrayBuffer, offset, updateLength);
    offset += updateLength;
    chunks.push(updateBytes);
  }

  return chunks;
};
