import { Editor } from "@tiptap/react";
import { getTableActionOptions } from "./utils";
import { Table } from "lucide-react";
import DropdownMenu from "components/molecules/dropdown-menu/DropdownMenu";
import tw from "twin.macro";

type ToolbarTableProps = {
  editor: Editor;
};

const ToolbarTable = ({ editor }: ToolbarTableProps) => {
  const tableActionOptions = getTableActionOptions(editor);

  return (
    <DropdownMenu
      triggerProps={{ css: tw`p-[--space-5] w-fit text-gray-500 hover:bg-[--color-surface]` }}
      items={tableActionOptions}
    >
      <Table size={16} />
    </DropdownMenu>
  );
};

export default ToolbarTable;
