import { useCallback, useState } from "react";
import CustomModal from "../CustomModal";
import cross from "../../Assets/crosss.svg";
import cloud_upload from "../../Assets/cloud-upload.svg";
import pdf from "../../Assets/pdf.svg";
import xls from "../../Assets/xls.svg";
import ppt from "../../Assets/ppt.svg";
import txtFile from "../../Assets/txtFile.svg";
import trash from "../../Assets/trash.svg";
import { Spinner } from "../../utils/icons";
import docs from "../../Assets/doc.svg";
import { useDropzone } from "react-dropzone";
import { formatBytes } from "utils/helpers";
import { useNotification } from "context/notificationContext";

// Max size 200 MB
const maxSize = 200000000;

// ------------------- Upload new Doc modal handler --------------------------------
const UploadDriveDocModal = ({ showUploadModal, setShowUploadModal, createDocument, isDrive }) => {
  const [uploading, setUploadingLoading] = useState(false);
  const { setToast } = useNotification();
  const [files, setFiles] = useState([]);

  // close modal
  const closeModal = () => {
    if (uploading) return;
    setShowUploadModal(false);
    setFiles([]);
  };

  // on file drop/select
  const onDrop = async (acceptedFiles) => {
    if (uploading) return;
    if (acceptedFiles && acceptedFiles?.length > 0) {
      if (isDrive) {
        onSaveBtn(acceptedFiles);
        return;
      }
      let newFiles = [];
      acceptedFiles.forEach((file) => {
        if (files.find((b) => b.name === file.name)) {
          return;
        }
        newFiles.push(file);
      });
      let prevFiles = [...newFiles, ...files];
      setFiles(prevFiles);
    }
  };

  // validation
  const dropValidator = (file) => {
    if (file?.size > maxSize) {
      setToast.error({
        title: "Document size not supported",
        msg:
          file?.name +
          `size is too large. We do not support document sizes larger than ${formatBytes(
            maxSize
          )}. Please try again with a smaller document or contact support@vultron.ai for assistance.`,
      });
      return {
        code: "file-too-large",
        message: `Document is larger than ${formatBytes(maxSize)}`,
      };
    }

    return null;
  };

  // drop files hook
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: maxSize,
    validator: dropValidator,
    accept: {
      "text/plain": [".txt"],
      "text/pdf": [".pdf"],
      "text/doc": [".docx"],
      "text/excel": [".xlsx"],
      "text/powerpoint": [".pptx"],
    },
    noClick: false,
    maxFiles: 30,
    multiple: true,
    noDrag: !!isDrive,
  });

  // remove file
  const removeFile = (file) => {
    let prevFiles = [...files];
    prevFiles = prevFiles.filter((f) => f !== file);
    setFiles(prevFiles);
  };

  const imgIcon = (type) => {
    let img = pdf;
    switch (type) {
      case "application/pdf":
        img = pdf;
        break;
      case "application/doc":
      case "application/docx":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        img = docs;
        break;
      case "application/xls":
      case "application/xlsx":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        img = xls;
        break;
      case "application/pptx":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        img = ppt;
        break;
      case "application/txt":
      case "text/plain":
        img = txtFile;
        break;
      default:
        img = docs;
    }
    return img;
  };

  const onSaveBtn = useCallback(
    (selectedFiles) => {
      if (uploading) return;
      setUploadingLoading(true);
      createDocument(selectedFiles || files)
        .then(() => {
          setFiles([]);
        })
        .finally(() => {
          setUploadingLoading(false);
        });
    },
    [createDocument, files, uploading]
  );

  if (isDrive) {
    return (
      <div
        {...getRootProps()}
        className="p-3 w-full mx-auto cursor-pointer relative border-gray-300 border-dashed border-2 m-3 justify-center flex flex-col items-center rounded-lg bg-white min-h-[260px]"
      >
        <input multiple {...getInputProps()} />
        {isDragActive ? (
          <div className="absolute bg-gray-100 flex justify-center items-center text-gray-700 top-0 left-0 h-full w-full z-[2] bg-dark1 bg-opacity-75">
            {" "}
          </div>
        ) : null}
        <img className="w-9 h-9 mb-2" src={cloud_upload} alt="" />
        <span className="font-semibold pointer-events-none">Drag and drop files here</span>
        <span className="text-gray-text pointer-events-none pt-[4px]">
          We accept pdf, docx, txt, xlsx, and pptx files
        </span>
      </div>
    );
  }
  return (
    <CustomModal isOpen={showUploadModal} onClose={closeModal} className="w-full max-w-[596px]">
      <div>
        <div className="px-6 pt-4 flex justify-between">
          <h3 className="font-semibold text-lg">Upload Documents</h3>
          <img src={cross} alt="" className="cursor-pointer" onClick={closeModal} />
        </div>
        <div className="py-1 px-6">
          <div
            {...getRootProps()}
            className="p-3 w-full mx-auto cursor-pointer relative border-gray-300 border-dashed border-2 m-3 justify-center flex flex-col items-center rounded-lg bg-white min-h-[220px]"
          >
            <input multiple {...getInputProps()} />
            {isDragActive ? (
              <div className="absolute bg-gray-100 flex justify-center items-center text-gray-700 top-0 left-0 h-full w-full z-[2] bg-dark1 bg-opacity-75">
                {" "}
              </div>
            ) : null}
            <img className="w-9 h-9 mb-2" src={cloud_upload} alt="" />
            <span className="font-semibold pointer-events-none">Drag and drop files here</span>
            <span className="text-gray-text pointer-events-none">We accept pdf, docx, txt, xlsx, and pptx files</span>
          </div>
          {/* ---------------------------------- */}
          <div className="flex flex-col overflow-y-auto max-h-[30vh]">
            {files?.map((fileItem, i) => (
              <div key={i} className="p-3 flex justify-between items-center w-full">
                <div className="flex gap-3 px-3 flex-grow max-w-[95%]">
                  <img src={imgIcon(fileItem?.type)} alt="" />
                  <div className="flex flex-col max-w-[93%]">
                    <span
                      style={{
                        wordWrap: "break-word",
                        textOverflow: "clip",
                      }}
                      className="block text-sm font-medium break-words overflow-hidden max-w-full"
                    >
                      {fileItem?.name}
                    </span>
                    <span className="text-xs">{formatBytes(fileItem?.size)}</span>
                  </div>
                </div>
                <button className="px-2 min-w-[33px] w-[33px] border-0 b-white" onClick={() => removeFile(fileItem)}>
                  <img src={trash} alt="" />
                </button>
              </div>
            ))}
          </div>
          <div className="flex mt-4 justify-end items-center gap-3 my-2">
            <button className="border-gray-300 border px-4 py-2 rounded-lg" onClick={closeModal}>
              Cancel
            </button>

            <button
              className="bg-gray-darkest disabled:opacity-50 py-2 px-4 flex gap-1 items-center text-white rounded-lg"
              disabled={uploading || !files?.length}
              onClick={() => onSaveBtn(files)}
            >
              {uploading ? (
                <>
                  <Spinner width={17} height={17} /> Importing documents
                </>
              ) : (
                <>Upload</>
              )}
            </button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default UploadDriveDocModal;
