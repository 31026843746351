import { TOP_BANNER } from "const-values/Banner";

const TopBanner = ({ copy }: { copy: string }) => {
    return (
        <div
            className="bg-gray-darkest text-xs font-medium justify-center text-white w-screen flex items-center px-5 z-1000"
            style={{ height: TOP_BANNER.HEIGHT }}
        >
            {copy}
        </div>
    );
};

export default TopBanner;
