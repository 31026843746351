import { useState } from "react";
import { uploadInternalContractLink } from "api/api";
import { useNotification } from "context/notificationContext";
import { Modal } from "components/organisms/modal";
import tw from "twin.macro";
import { Button } from "components/editor/components";

// ---------------- Add new Link Modal handler --------------------------
const LinkModal = ({ showLinkModal, setShowLinkModal, internalContractId, setForceRefresh, searchPage }) => {
    const [showBtnLoading, setShowBtnLoading] = useState(false);
    const { setToast } = useNotification();

    const [name, setName] = useState("");
    const [link, setLink] = useState("https://");

    // on save link
    const onSaveBtn = () => {
        if (name.length === 0 || link.length === 0) {
            setToast.warning({
                msg: "Please provide a bookmark name and url",
            });
            return;
        }
        setShowBtnLoading(true);

        uploadInternalContractLink(internalContractId, {
            name: name,
            url: link,
        })
            .then((res) => {
                setShowLinkModal(false);
                setShowBtnLoading(false);
                setName("");
                setLink("");
                setForceRefresh(true);
            })
            .catch((err) => {
                setShowBtnLoading(false);
                setToast.error({
                    title: "Unable to create bookmark",
                    msg: "We were unable to create the bookmark due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
            });
    };

    return (
        <Modal
            open={showLinkModal}
            onOpenChange={setShowLinkModal}
            contentProps={{ css: tw`w-[550px]` }}
            header={`Add ${searchPage ? "Link" : "Bookmark"}`}
            body={
                <div className="px-6 flex flex-col gap-3">
                    <div className="flex flex-col  gap-3">
                        <label className="text-gray-text"> Name</label>
                        <input
                            type="text"
                            className="border border-gray-300 rounded-lg p-2 focus:outline-none"
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                    </div>
                    <div className="flex">
                        <span className="inline-flex py-2 px-4 items-center  text-sm text-gray-900 bg-[#fafafa] border border-gray-300 rounded-l-lg  ">
                            URL
                        </span>
                        <input
                            type="text"
                            className=" text-blue-500 rounded-none rounded-r-lg p-2 border-l-0 border border-gray-300 w-full focus:outline-none"
                            value={link}
                            onChange={(e) => {
                                setLink(e.target.value);
                            }}
                        />
                    </div>
                    <div className=" flex justify-end items-center mt-4 gap-2">
                        <Button
                            size="md"
                            className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
                            variant="outline"
                            onClick={() => {
                                setShowLinkModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button size="md" variant="primary" type="submit" loading={showBtnLoading} onClick={onSaveBtn}>
                            Add
                        </Button>
                    </div>
                </div>
            }
        />
    );
};

export default LinkModal;
