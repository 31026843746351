import { useSearchParams } from "react-router-dom";
import { Requirement } from "types/Requirement";

import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

export const REQUIREMENTS_QUERY_KEY = "capture/schemas";

type Params = {
  source: string[];
  section_header: string[];
  keyword: string[];
  analysis_id: string;
};

const useGetRequirements = (params?: Partial<Params>, options?: Partial<UseQueryOptions<Requirement[], Error>>) => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id")?.toLocaleLowerCase();
  const variables = { ...params, project_id: projectId };
  const queryKey = [REQUIREMENTS_QUERY_KEY, variables];
  const { data = [], ...rest } = useQuery<Requirement[], Error>({
    queryKey,
    queryFn: () =>
      axios.get<Requirement[]>(`/autopilot/${projectId}/requirements`, { params: variables }).then((resp) => resp.data),
    ...options,
  });

  return {
    data,
    queryKey,
    ...rest,
  };
};

export default useGetRequirements;
