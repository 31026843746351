import { DRIVE_ROUTES } from "Routes/drive";
import axios, { AxiosResponse } from "axios";
import { useNotification } from "context/notificationContext";
import { useHandleApiError } from "hook/useHandleApiError";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import { Subdirectory, WorkspaceMedia } from "types/FileStorage";
import { formatBytes } from "utils/helpers";
import { ACCEPT_MEDIA_TYPES, MAX_MEDIA_FILE_SIZE } from "../constants";
import { useDebouncedCallback } from "hook/useDebouncedCallback";

export type NestedDriveMediaState = {
    current_directory: Subdirectory | null;
    files: WorkspaceMedia[];
    subdirectories: Subdirectory[];
};

export const useNestedDriveMedia = () => {
    const { folderId } = useParams();
    const [currentNestedDriveMediaState, setCurrentNestedDriveMediaState] = useState<NestedDriveMediaState>();
    const [nextNestedDriveMediaState, setNextNestedDriveMediaState] = useState<{
        [key: string]: NestedDriveMediaState;
    }>({});
    const [isLoading, setIsLoading] = useState(false);
    const { rootFiles, rootSubdirectories } = useAppSelector((root) => root.drive.media);

    const currentFiles = folderId ? currentNestedDriveMediaState?.files || [] : rootFiles;
    const currentFolders = useMemo(
        () => (folderId ? currentNestedDriveMediaState?.subdirectories || [] : rootSubdirectories),
        [currentNestedDriveMediaState?.subdirectories, folderId, rootSubdirectories]
    );

    const getNextNestedDriveMediaState = useCallback(() => {
        if (isLoading) return;
        setIsLoading(true);
        const directoryRequests = currentFolders.map(
            ({ id }) =>
                axios.get(DRIVE_ROUTES.media.directory.get(id)) as Promise<AxiosResponse<NestedDriveMediaState, any>>
        );
        Promise.all(directoryRequests)
            .then((results) => {
                setNextNestedDriveMediaState(
                    results.reduce<{ [key: string]: NestedDriveMediaState }>((acc, { data }) => {
                        if (data.current_directory) return { [data.current_directory?.id]: data, ...acc };
                        return acc;
                    }, {})
                );
            })
            .catch(() => {})
            .finally(() => setIsLoading(false));
    }, [isLoading, currentFolders]);

    useEffect(() => {
        getNextNestedDriveMediaState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentNestedDriveMediaState?.current_directory?.id]);

    const getCurrentNestedDriveMediaState = useCallback(async (folderId?: string) => {
        try {
            const url = folderId ? DRIVE_ROUTES.media.directory.get(folderId) : DRIVE_ROUTES.media.directory.getRoot;
            const { data } = await axios.get(url);
            setCurrentNestedDriveMediaState(data);
        } catch {}
    }, []);

    useEffect(() => {
        getCurrentNestedDriveMediaState(folderId);
    }, [folderId, getCurrentNestedDriveMediaState]);

    return {
        currentFiles,
        currentSubdirectories: currentFolders,
        nextNestedDriveMediaState,
        currentNestedDriveMediaState,
        setCurrentNestedDriveMediaState,
        isLoading,
    };
};

export const useMediaSearch = (maintainResults: boolean = false) => {
    const [directoryId, setDirectoryId] = useState("");
    const [keyword, setKeyword] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [searchResults, setSearchResults] = useState<WorkspaceMedia[]>([]);

    const triggerSearch = useCallback(async () => {
        if (!maintainResults && keyword.trim() === "") {
            setSearchResults([]);
            setIsSearching(false);
            return;
        }

        setIsSearching(true);
        try {
            const { data } = await axios.get(DRIVE_ROUTES.media.search, {
                params: {
                    ...(keyword.trim() && { keyword: keyword.trim() }),
                    ...(directoryId && { directory_id: directoryId }),
                },
            });
            setSearchResults(data);
        } catch {
        } finally {
            setIsSearching(false);
        }
    }, [directoryId, keyword, maintainResults]);

    const debounceSearch = useDebouncedCallback(triggerSearch, 500);

    useEffect(() => {
        if (keyword) setIsSearching(true);
        debounceSearch();
    }, [keyword]);

    const onKeywordChange = useCallback((newKeyword: string) => {
        setKeyword(newKeyword);
    }, []);

    const isSearchActive = !!keyword;

    return { isSearching, searchResults, onKeywordChange, setDirectoryId, isSearchActive, keyword };
};

export const useDropValidator = () => {
    const { setToast } = useNotification();
    return useCallback(
        (file: File) => {
            if (file.size > MAX_MEDIA_FILE_SIZE) {
                setToast.error({
                    title: "Unable to upload image",
                    msg:
                        file?.name +
                        ` size is too large. We do not support image sizes larger than ${formatBytes(
                            MAX_MEDIA_FILE_SIZE
                        )}. Please try again with a smaller document or contact support@vultron.ai for assistance.`,
                });
                return {
                    code: "file-too-large",
                    message: `File is larger than ${formatBytes(MAX_MEDIA_FILE_SIZE)}`,
                };
            }
            if (file.type && !Object.keys(ACCEPT_MEDIA_TYPES).includes(file.type)) {
                setToast.error({
                    title: "Unable to upload image",
                    msg: "Image type not supported. We support JPEG and PNG files.",
                });
                return {
                    code: "invalid-file-type",
                    message: "Invalid file type",
                };
            }
            return null;
        },
        [setToast]
    );
};

export const useMediaMutateOperations = () => {
    const [isCreatingFolder, setIsCreatingFolder] = useState(false);
    const [isUpdatingFolder, setIsUpdatingFolder] = useState(false);
    const [isDeletingFolder, setIsDeletingFolder] = useState(false);
    const [isMovingFolder, setIsMovingFolder] = useState(false);
    const [isCreatingFile, setIsCreatingFile] = useState(false);
    const [isUpdatingFile, setIsUpdatingFile] = useState(false);
    const [isDeletingFile, setIsDeletingFile] = useState(false);
    const [isMovingFile, setIsMovingFile] = useState(false);
    const { setError } = useHandleApiError();
    const { setToast } = useNotification();

    const createFolder = useCallback(
        async (data: { parent_directory_id?: string; name: string }) => {
            setIsCreatingFolder(true);
            try {
                const res = await axios.post(DRIVE_ROUTES.media.directory.create, data);
                setToast.success({ msg: "Folder created" });
                return res.data;
            } catch (error) {
                setError(
                    "Failed to create folder. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
            } finally {
                setIsCreatingFolder(false);
            }
        },
        [setError, setToast]
    );

    const updateFolder = useCallback(
        async (id: string, data: { parent_directory_id?: string; name: string }) => {
            setIsUpdatingFolder(true);
            try {
                await axios.put(DRIVE_ROUTES.media.directory.update(id), data);
                setToast.success({ msg: "Folder updated" });
                return true;
            } catch (error) {
                setError(
                    "Failed to update the folder. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
                return false;
            } finally {
                setIsUpdatingFolder(false);
            }
        },
        [setError, setToast]
    );

    const deleteFolder = useCallback(
        async (id: string) => {
            setIsDeletingFolder(true);
            try {
                await axios.delete(DRIVE_ROUTES.media.directory.delete(id));
                setToast.success({ msg: "Folder deleted" });
                return true;
            } catch (error) {
                setError(
                    "Failed to delete folder. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
                return false;
            } finally {
                setIsDeletingFolder(false);
            }
        },
        [setError, setToast]
    );

    const moveFolder = useCallback(
        async (id: string, data: { parent_directory_id?: string }) => {
            setIsMovingFolder(true);
            try {
                await axios.post(DRIVE_ROUTES.media.directory.move(id), data);
                setToast.success({ msg: "Folder moved" });
                return true;
            } catch (error) {
                setError(
                    "Failed to move the folder. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
                return false;
            } finally {
                setIsMovingFolder(false);
            }
        },
        [setError, setToast]
    );

    const createFile = useCallback(
        async (data: {
            parent_directory_id?: string;
            user_tags: string[];
            file: File;
            visible_in_content_library?: boolean;
        }) => {
            setIsCreatingFile(true);
            if (!data.file) return;
            try {
                const formData = new FormData();
                formData.append("file", data.file);
                if (data.parent_directory_id) {
                    formData.append("parent_directory_id", data.parent_directory_id);
                }
                for (const tag of data.user_tags) {
                    formData.append("user_tags", tag);
                }

                formData.append(
                    "visible_in_content_library",
                    typeof data.visible_in_content_library === "undefined"
                        ? String("true")
                        : String(data.visible_in_content_library)
                );

                const res = await axios.post(DRIVE_ROUTES.media.file.create, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                setToast.success({ msg: "Graphic uploaded" });
                return res.data;
            } catch (error) {
                setError(
                    "Failed to upload graphic. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
            } finally {
                setIsCreatingFile(false);
            }
        },
        [setError, setToast]
    );

    const updateFile = useCallback(
        async (id: string, data: { user_tags: string[]; file_name: string }) => {
            setIsUpdatingFile(true);
            try {
                const res = await axios.put(DRIVE_ROUTES.media.file.update(id), data);
                setToast.success({ msg: "Graphic updated" });
                return res.data;
            } catch (error) {
                setError(
                    "Failed to update graphic. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
            } finally {
                setIsUpdatingFile(false);
            }
        },
        [setError, setToast]
    );

    const deleteFile = useCallback(
        async (id: string) => {
            setIsDeletingFile(true);
            try {
                await axios.delete(DRIVE_ROUTES.media.file.delete(id));
                setToast.success({ msg: "Graphic deleted" });
                return true;
            } catch (error) {
                setError(
                    "Failed to delete graphic. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
                return false;
            } finally {
                setIsDeletingFile(false);
            }
        },
        [setError, setToast]
    );

    const moveFile = useCallback(
        async (id: string, data: { parent_directory_id?: string }) => {
            setIsMovingFile(true);
            try {
                await axios.post(DRIVE_ROUTES.media.file.move(id), data);
                setToast.success({ msg: "Graphic moved" });
                return true;
            } catch (error) {
                setError(
                    "Failed to move the graphic. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
                );
                return false;
            } finally {
                setIsMovingFile(false);
            }
        },
        [setError, setToast]
    );

    return {
        createFolder,
        isCreatingFolder,
        createFile,
        isCreatingFile,
        updateFile,
        isUpdatingFile,
        deleteFile,
        isDeletingFile,
        moveFile,
        isMovingFile,
        updateFolder,
        isUpdatingFolder,
        deleteFolder,
        isDeletingFolder,
        moveFolder,
        isMovingFolder,
    };
};
