import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type State = {
  reviewsInProgress: string[];
  proposalsWithSuccessfulReviews: string[];
};

const initialState: State = {
  reviewsInProgress: [],
  proposalsWithSuccessfulReviews: [],
};

const proposalReducer = createSlice({
  name: "proposal",
  initialState,
  reducers: {
    addDocIdToReviews: (state, action: PayloadAction<string>) => {
      state.reviewsInProgress.push(action.payload);
    },
    removeDocIdFromReviews: (state, action: PayloadAction<string>) => {
      state.reviewsInProgress = state.reviewsInProgress.filter((id) => id !== action.payload);
    },
    addDocIdToSuccessfulReviews: (state, action: PayloadAction<string>) => {
      const docId = action.payload;

      if (!state.proposalsWithSuccessfulReviews.includes(docId)) {
        state.proposalsWithSuccessfulReviews.push(docId);
      }
    },
    removeDocIdFromSuccessfulReviews: (state, action: PayloadAction<string>) => {
      state.proposalsWithSuccessfulReviews = state.proposalsWithSuccessfulReviews.filter((id) => id !== action.payload);
    },
  },
});

export const {
  addDocIdToReviews,
  removeDocIdFromReviews,
  addDocIdToSuccessfulReviews,
  removeDocIdFromSuccessfulReviews,
} = proposalReducer.actions;

export default proposalReducer.reducer;
