import { useComments } from "../../../api/comments/useComments";
import { Thread } from "./Thread";
import { useActiveThread } from "./useThread";
import Loader from "utils/Spinner";
import { DropdownMenu, DropdownMenuLabel } from "components/molecules/dropdown-menu";
import { ListFilter } from "lucide-react";
import { CommentsFilterState, ThreadContext } from "../types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useProxyRef } from "hook/useProxyRef";
import { useAppSelector } from "store/storeTypes";
import { useThreadEditorMapping } from "./useThreadEditorMapping";
import { Editor } from "@tiptap/react";

interface CommentsPanelProps {
  context: ThreadContext;
  internalContractId: string;
  referenceId?: string;
  filterState?: CommentsFilterState;
}
export const CommentsPanel = ({ internalContractId, referenceId, context }: CommentsPanelProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const commentIdParam = searchParams.get("commentId");
  const [filterState, setFilterState] = useState<CommentsFilterState>(CommentsFilterState.Open);
  const [userFilterState, setUserFilterState] = useState<CommentsFilterState>(CommentsFilterState.AllUsers);
  const currentFilterState = useProxyRef(filterState);
  const { resolvedThreads, unresolvedThreads, isLoading, data } = useComments(internalContractId, referenceId);
  const editor = useAppSelector((state) => state.copilot.activeCommentEditor);
  const activeEditorID = useAppSelector((state) => state.copilot.activeEditorId);
  const activeThreadId = useActiveThread(editor);
  const [activeCommentId, setActiveCommentId] = useState<string | null>(null);
  const threadEditorMapping = useThreadEditorMapping(data, context, internalContractId, referenceId);
  const [disableScroll, setDisableScroll] = useState(false);
  const allThreads = useMemo(() => [...resolvedThreads, ...unresolvedThreads], [resolvedThreads, unresolvedThreads]);
  const initialActiveThreadIdRef = useRef(searchParams.get("threadId")); // the threadId to activate on load

  useEffect(() => {
    if (!initialActiveThreadIdRef.current) return;
    // Once threads are loaded, set the filter state based on the resolved state of the thread
    const matchedThread = allThreads.find((thread) => thread.id === initialActiveThreadIdRef.current);
    if (matchedThread) {
      setFilterState(matchedThread.resolved ? CommentsFilterState.Resolved : CommentsFilterState.Open);
    }
    // remove params from URL
    searchParams.delete("threadId");
    searchParams.delete("commentId");
    setSearchParams(searchParams, { replace: true });
  }, [isLoading]);

  useEffect(() => {
    // update filter state when active thread changes
    if (filterState === CommentsFilterState.Resolved && activeThreadId) {
      setFilterState(CommentsFilterState.Open);
    }
  }, [activeThreadId, filterState]);

  const getFilteredThreadIds = useMemo(() => {
    return (userFilter: CommentsFilterState) => {
      return allThreads
        .filter((thread) => {
          let userMatch = true;
          switch (userFilter) {
            case CommentsFilterState.VultronOnly:
              userMatch = thread.comments.some((comment) => comment.commenter.is_vultron);
              break;
            case CommentsFilterState.MemberOnly:
              userMatch = thread.comments.every((comment) => !comment.commenter.is_vultron);
              break;
            case CommentsFilterState.AllUsers:
            default:
              userMatch = true;
          }

          return userMatch;
        })
        .map((thread) => thread.id);
    };
  }, [allThreads]);

  const updateHiddenComments = (userFilter: CommentsFilterState) => {
    const visibleThreadIds = getFilteredThreadIds(userFilter);
    const hiddenThreadIds = allThreads.map((thread) => thread.id).filter((id) => !visibleThreadIds.includes(id));
    if (editor) {
      editor.commands.setHiddenComments(hiddenThreadIds);
    }
  };

  const handleFilterChange = (newState: CommentsFilterState) => {
    setFilterState(newState);
    if (newState === CommentsFilterState.Resolved) {
      editor?.commands.setActiveComment(undefined);
      updateHiddenComments(userFilterState);
    }
  };

  const handleUserFilterChange = (newState: CommentsFilterState) => {
    setUserFilterState(newState);
    updateHiddenComments(newState);
  };

  useEffect(() => {
    if (!isLoading && allThreads.length > 0) {
      updateHiddenComments(userFilterState);
    }
  }, [isLoading, allThreads, filterState, userFilterState]);

  const filterIsApplied = filterState !== CommentsFilterState.Open || userFilterState !== CommentsFilterState.AllUsers;

  useEffect(() => {
    setActiveCommentId(commentIdParam);
    if (commentIdParam) {
      setSearchParams(
        (prev) => {
          prev.delete("commentId");
          return prev;
        },
        { replace: true }
      );
    }
  }, [commentIdParam, setSearchParams]);

  useEffect(() => {
    if (!activeThreadId || isLoading) return;
    // check that the current filter state matches the thread resolved state
    const thread = unresolvedThreads.find((thread) => thread.id === activeThreadId);
    if (!thread && currentFilterState.current === CommentsFilterState.Open) {
      setFilterState(CommentsFilterState.Resolved);
    }
  }, [activeThreadId, isLoading]);

  const threads = filterState === CommentsFilterState.Resolved ? resolvedThreads : unresolvedThreads;

  const filteredThreads = useMemo(() => {
    return threads.filter((thread) => {
      if (thread.comments.length === 0) {
        return false;
      }

      if (userFilterState === CommentsFilterState.VultronOnly) {
        return thread.comments.some((comment) => comment.commenter.is_vultron);
      } else if (userFilterState === CommentsFilterState.MemberOnly) {
        return thread.comments.every((comment) => !comment.commenter.is_vultron);
      } else if (userFilterState === CommentsFilterState.AllUsers) {
        return true;
      }
      return false;
    });
  }, [threads, userFilterState]);

  if (isLoading)
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loader type={undefined} />
      </div>
    );
  return (
    <div className="flex flex-col flex-1 overflow-auto w-full">
      <div className="flex flex-row justify-between items-center px-4 py-1 border-y border-b-slate-200">
        <span className="text-sm">{filterState} Comments</span>
        <DropdownMenu
          items={[
            {
              key: "statusFilter",
              node: <DropdownMenuLabel label="Filter by status" />,
            },
            {
              key: CommentsFilterState.Open,
              label: CommentsFilterState.Open,
              onSelect: () => handleFilterChange(CommentsFilterState.Open),
              selected: filterState === CommentsFilterState.Open,
            },
            {
              key: CommentsFilterState.Resolved,
              label: CommentsFilterState.Resolved,
              onSelect: () => handleFilterChange(CommentsFilterState.Resolved),
              selected: filterState === CommentsFilterState.Resolved,
            },
            {
              key: "typeFilter",
              node: <DropdownMenuLabel label="Filter by type" className="border-t border-t-gray-light mt-1.5 pt-3" />,
            },
            {
              key: CommentsFilterState.AllUsers,
              label: CommentsFilterState.AllUsers,
              onSelect: () => handleUserFilterChange(CommentsFilterState.AllUsers),
              selected: userFilterState === CommentsFilterState.AllUsers,
            },
            {
              key: CommentsFilterState.VultronOnly,
              label: CommentsFilterState.VultronOnly,
              onSelect: () => handleUserFilterChange(CommentsFilterState.VultronOnly),
              selected: userFilterState === CommentsFilterState.VultronOnly,
            },
            {
              key: CommentsFilterState.MemberOnly,
              label: CommentsFilterState.MemberOnly,
              onSelect: () => handleUserFilterChange(CommentsFilterState.MemberOnly),
              selected: userFilterState === CommentsFilterState.MemberOnly,
            },
          ]}
          triggerProps={{ className: "p-1 rounded-md hover:bg-hover-default" }}
        >
          <ListFilter size={22} color={filterIsApplied ? "#1E1E1e" : "#7A7F84"} />
        </DropdownMenu>
      </div>
      {filteredThreads.length === 0 && (
        <div className="pt-2 flex flex-col items-center h-full justify-center">
          <span className="font-medium text-xl mb-1">No comments</span>
        </div>
      )}
      <div className={`${disableScroll ? "overflow-hidden" : "overflow-auto"} flex-1 w-full`}>
        {filteredThreads.map((thread) => {
          let threadEditor: Editor | undefined | null;
          if (context === ThreadContext.PROPOSAL) {
            threadEditor = editor;
          } else {
            threadEditor = threadEditorMapping[thread.id] === activeEditorID ? editor : undefined;
          }
          return (
            <Thread
              editorId={threadEditorMapping[thread.id]}
              editor={threadEditor}
              thread={thread}
              key={thread.id}
              internalContractId={internalContractId}
              referenceId={referenceId}
              isActiveThread={thread.id === activeThreadId}
              context={context}
              activeCommentId={activeCommentId}
              filterState={filterState}
              setDisableScroll={setDisableScroll}
              initialActiveThreadIdRef={initialActiveThreadIdRef}
            />
          );
        })}
      </div>
    </div>
  );
};
