/** @jsxImportSource @emotion/react */

import { Interpolation, Theme } from "@emotion/react";
import { InputHTMLAttributes } from "react";
import tw, { TwStyle } from "twin.macro";

type Size = "sm" | "md" | "lg";

const sizeStyles: Record<Size, TwStyle> = {
  sm: tw`h-7 min-h-[28px] text-xs rounded-md pl-2 pr-1 py-1.5`,
  md: tw`h-9 min-h-[28px] text-sm rounded-lg pl-2 pr-1 py-2`,
  lg: tw`h-10 min-h-[40px] text-base rounded-lg pl-3 pr-1 py-2`,
};

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  size?: Size;
  styles?: Interpolation<Theme>;
}

const Input = ({ size = "md", styles, ...props }: Props) => {
  return <input className="border border-gray-light outline-none" css={[sizeStyles[size], styles]} {...props} />;
};

export default Input;
