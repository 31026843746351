import { updateInternalContractDetails, updateInputDetails } from "api/api";
import { useEffect, useRef, useState, useMemo } from "react";
import edit from "../../../Assets/edit-2.png";
import show from "../../../Assets/show.svg";
import hide from "../../../Assets/hide.svg";
import { RiInformationFill } from "@remixicon/react";
import Tooltip from "components/atoms/tooltip/Tooltip";

const Names = ({ internalContractDetails, companyName }) => {
  const clientNameTextAreaRef = useRef();
  const companyNameTextAreaRef = useRef();

  const references = useMemo(
    () => ({
      client_name: clientNameTextAreaRef,
      company_name: companyNameTextAreaRef,
    }),
    []
  );

  const [open, setOpen] = useState(true);

  const [nameEditable, setNameEditable] = useState(false);
  const [companyNameEditable, setCompanyNameEditable] = useState(false);

  const [activeTextArea, setActiveTextArea] = useState("");

  const [client, setClient] = useState({
    client_name: "",
    company_name: "",
  });

  useEffect(() => {
    const internalCDetails = JSON.parse(JSON.stringify(internalContractDetails));
    setClient((pS) => ({
      ...pS,
      client_name: internalCDetails?.internal_contract?.client_name,
      company_name: companyName,
    }));
  }, [internalContractDetails, companyName]);

  const adjustTextAreaHeight = (ref, noFocus) => {
    let textArea = ref?.current;
    if (textArea) {
      if (!noFocus) {
        textArea.focus();
        textArea.setSelectionRange(textArea.value.length, textArea.value.length);
      }

      textArea.style.height = textArea.scrollHeight + "px";
    }
  };

  useEffect(() => {
    if (client) adjustTextAreaHeight(references[activeTextArea], true);
  }, [client]);

  useEffect(() => {
    if (activeTextArea) adjustTextAreaHeight(references[activeTextArea]);
  }, [activeTextArea]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setClient((pS) => ({
      ...pS,
      [name]: value,
    }));
  };

  const updateClient = async (e) => {
    e.preventDefault();

    const companyNameChanged = activeTextArea === "company_name";
    setNameEditable(false);
    setCompanyNameEditable(false);
    setActiveTextArea("");

    console.log(client);
    try {
      const trimmedClient = Object.keys(client).reduce((acc, key) => {
        return { ...acc, [key]: client[key]?.trim() || "" };
      }, {});
      setClient(trimmedClient);
      if (companyNameChanged) {
        await updateInputDetails(internalContractDetails?.internal_contract?.id, {
          company_name: client[activeTextArea],
          use_default_company_name: false,
        });
      } else {
        await updateInternalContractDetails(internalContractDetails?.internal_contract?.id, {
          client_name: client[activeTextArea],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      updateClient(event);
    }
  };

  return (
    <div className="border border-[#D8DDE2] p-4 shadow-sm rounded-lg mb-4 bg-white">
      <div className="flex w-full">
        <div
          onClick={() => setOpen(!open)}
          role="button"
          className="w-full flex items-center p-0 justify-between cursor-pointer select-none"
        >
          <h3 className="text-base font-medium mr-2">Labels</h3>
          <Tooltip content="Vultron will use these labels in the generated content.">
            <div className="relative flex items-center justify-center h-[15px] w-[15px]">
              <RiInformationFill size={14} className="text-action rounded-full z-[1]" />

              <div
                className="pulse-animation absolute rounded-full left-0.5 right-0.5 top-0.5 bottom-0.5"
                // @ts-ignore
                style={{ "--pulse-bg": "rgba(42, 71, 171, 0.7)" }}
              />
            </div>
          </Tooltip>
          <span className="ml-auto">
            <img className={open ? "" : "hidden"} src={hide} alt="icon" />
            <img className={open ? "hidden" : ""} src={show} alt="icon" />
          </span>
        </div>
      </div>
      <form
        onSubmit={updateClient}
        className={`w-full m-0 p-0 transition-all duration-300 ease-linear ${
          open ? "pt-4" : "pt-0 opacity-0 h-0 overflow-hidden"
        }`}
      >
        <div className="flex flex-col pt-2 gap-1 w-full">
          <span className="text-[#5B6B79] text-[14px] font-medium">Client Name</span>
          {nameEditable ? (
            <textarea
              type="text"
              className="w-full outline-none  no-scrollbar text-[14px] p-0 m-0 resize-none border-none  break-words "
              onChange={changeHandler}
              style={{ lineHeight: "14px" }}
              name="client_name"
              onBlur={updateClient}
              ref={references.client_name}
              value={client?.client_name}
              onKeyDown={handleKeyDown}
            ></textarea>
          ) : (
            <div className="text-sm group break-words w-full text-[14px] mt-0">
              {client?.client_name || "-"}{" "}
              <img
                src={edit}
                alt=""
                className="hidden group-hover:inline-block cursor-pointer  ml-[10px] w-[14px] h-[14px]"
                onClick={() => {
                  setNameEditable(true);
                  setActiveTextArea("client_name");
                }}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-1 w-full pt-4 pb-[6.4px]">
          <span className="text-[#5B6B79] text-[14px] font-medium">Company Name</span>
          {companyNameEditable ? (
            <textarea
              type="text"
              className="w-full outline-none  no-scrollbar text-[14px] p-0 m-0 resize-none border-none  break-words "
              onChange={changeHandler}
              style={{ lineHeight: "14px" }}
              name="company_name"
              onBlur={updateClient}
              ref={references.company_name}
              value={client?.company_name}
              onKeyDown={handleKeyDown}
            ></textarea>
          ) : (
            <div className="text-sm group break-words w-full text-[14px]">
              {client?.company_name || "-"}{" "}
              <img
                src={edit}
                alt=""
                className="hidden group-hover:inline-block cursor-pointer  ml-[10px] w-[14px] h-[14px]"
                onClick={() => {
                  setCompanyNameEditable(true);
                  setActiveTextArea("company_name");
                }}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Names;
