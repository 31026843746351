import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";
import { Extension } from "@tiptap/react";
import { commentDraftPluginKey } from "components/Comments/CommentsDraftPlugin";
import { commentPluginHighlightKey } from "components/Comments/CommentsPluginHighlight";

const focusPluginKey = new PluginKey("focus");
const UnfocusedSelectionExtension = Extension.create({
  name: "unfocusedSelection",

  addProseMirrorPlugins() {
    return [
      // This plugin is taken from here: https://discuss.prosemirror.net/t/handling-focus-in-plugins/1981/5
      new Plugin({
        key: focusPluginKey,
        state: {
          init() {
            return false;
          },
          apply(transaction, prevFocused) {
            let focused = transaction.getMeta(focusPluginKey);
            if (typeof focused === "boolean") {
              return focused;
            }
            return prevFocused;
          },
        },
        props: {
          handleDOMEvents: {
            blur: (view) => {
              view.dispatch(view.state.tr.setMeta(focusPluginKey, false));
              return false;
            },
            focus: (view) => {
              view.dispatch(view.state.tr.setMeta(focusPluginKey, true));
              return false;
            },
          },
        },
      }),
      // This plugin is our custom plugin, then adds a mock "selection" decoration, when the editor is not focused
      new Plugin({
        key: new PluginKey("unfocusedSelection"),
        state: {
          init() {
            return DecorationSet.empty;
          },
          apply(tr, _oldState, _oldEditorState, newEditorState) {
            const isFocused = focusPluginKey.getState(newEditorState);
            if (tr.selection.empty || isFocused) {
              // If there is no selection, return the old state
              return DecorationSet.empty;
            } else {
              // If there is a selection, create a decoration
              const decorations = [];
              const { from, to } = tr.selection;
              decorations.push(
                Decoration.inline(from, to, {
                  style: "background-color: #e6e6ff; font-family: inherit;",
                })
              );
              return DecorationSet.create(newEditorState.doc, decorations);
            }
          },
        },
        props: {
          decorations(state) {
            const commentsState = commentDraftPluginKey.getState(state);
            const activeCommentState = commentPluginHighlightKey.getState(state);
            const isDraftActive = commentsState?.draftActive;
            const isCommentActive = activeCommentState?.activeCommentId;

            if (isDraftActive || isCommentActive) {
              return DecorationSet.empty;
            }
            return this.getState(state);
          },
        },
      }),
    ];
  },
});

export default UnfocusedSelectionExtension;
