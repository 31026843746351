import { Modal } from "components/organisms/modal";
import { ComponentProps, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { Subdirectory, WorkspaceMedia } from "types/FileStorage";
import { FolderIconBlue, FolderIconYellow, Spinner } from "utils/icons";
import { useMediaMutateOperations } from "../media/hooks";

interface Props extends Partial<ComponentProps<typeof Modal>> {
  moveType: "file" | "directory";
  selectedItem: Subdirectory | WorkspaceMedia | null;
  currentDirectory?: Subdirectory | null;
  setSelectedFile?: (file: WorkspaceMedia | null) => void;
  setSelectedFolder?: (folder: Subdirectory | null) => void;
  onMove: (moveType: "directory" | "file", itemId: string) => void;
}

const MoveModal = ({
  onMove,
  moveType,
  selectedItem,
  currentDirectory,
  setSelectedFile,
  setSelectedFolder,
  ...props
}: Props) => {
  const [selectedDestination, setSelectedDestination] = useState<string | null | undefined>(undefined); // null is the root content drive directory, undefined is no selection
  const [selectedItemName, setSelectedItemName] = useState<string>("");
  const { moveFile, moveFolder, isMovingFile, isMovingFolder } = useMediaMutateOperations();
  const loading = isMovingFile || isMovingFolder;
  const { allFolderNodes } = useAppSelector((root) => root.drive.media);

  const availableFolderIds = useMemo(() => {
    let ids: string[] = [];
    if (moveType === "directory") {
      const parentDirectoryId = (selectedItem as Subdirectory)?.parent_directory ?? "";
      ids = Object.keys(allFolderNodes).filter(
        (id) => id !== parentDirectoryId && id !== (selectedItem as Subdirectory).id
      );
    } else if (moveType === "file") {
      const parentDirectoryId = (selectedItem as WorkspaceMedia)?.parent_directory ?? "";
      ids = Object.keys(allFolderNodes).filter((id) => id !== parentDirectoryId);
    }
    return ids;
  }, [moveType, selectedItem, allFolderNodes]);

  useEffect(() => {
    if (selectedItem) {
      setSelectedItemName(
        moveType === "directory" ? (selectedItem as Subdirectory).name : (selectedItem as WorkspaceMedia).file_name
      );
    }
  }, [selectedItem]);

  const handleMove = async () => {
    if (loading || !selectedItem) return;
    if (moveType === "directory") {
      await moveFolder(selectedItem.id, selectedDestination ? { parent_directory_id: selectedDestination } : {});
    } else if (moveType === "file") {
      await moveFile(selectedItem.id, selectedDestination ? { parent_directory_id: selectedDestination } : {});
    }
    onMove(moveType, selectedItem.id);
    props.onOpenChange?.(false);
    setSelectedDestination(null);
  };

  const handleCloseMoveModal = () => {
    props.onOpenChange?.(false);
    setSelectedDestination(null);
    if (setSelectedFile) {
      setSelectedFile(null);
    }
    if (setSelectedFolder) {
      setSelectedFolder(null);
    }
  };

  return (
    <Modal
      contentProps={{ css: tw`w-[595px]`, onClick: (e) => e.stopPropagation() }}
      header={`Move “${selectedItemName}“`}
      body={
        <div className="flex flex-col overflow-y-auto px-5">
          <div className="my-6 max-h-[350px] flex flex-col border rounded overflow-auto">
            {availableFolderIds.map((id, i) => (
              <div
                role="button"
                className={`${
                  availableFolderIds?.length === i + 1 ? "" : "border-b"
                } flex gap-3 p-4 cursor-pointer select-none ${
                  selectedDestination === id ? " bg-[#f7f7f7]" : "hover:bg-[#f8f8f8]"
                }`}
                key={id}
                onClick={() => {
                  setSelectedDestination((prev) => (prev === id ? undefined : id));
                }}
              >
                <div className="min-w-[38px] flex items-center justify-center">
                  {id ? <FolderIconYellow /> : <FolderIconBlue />}
                </div>
                <p className="font-[500] flex-1">{allFolderNodes[id].name}</p>
              </div>
            ))}
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end gap-4">
          <button
            className="border-[1px] border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
            disabled={loading}
            onClick={() => !loading && handleCloseMoveModal()}
          >
            Cancel
          </button>
          <button
            className="border-0 disabled:opacity-50 bg-gray-darkest rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2"
            onClick={handleMove}
            disabled={loading}
          >
            {loading ? <Spinner width="18" height="18" /> : null}
            {loading ? "Moving" : "Move"}
          </button>
        </div>
      }
      {...props}
    />
  );
};

export default MoveModal;
