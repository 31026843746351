/** @jsxImportSource @emotion/react */

import { ExtractionStatus, InstantDraftStatus } from "components/copilot/CopilotSchemaTypes";
import { Button } from "components/editor/components";
import { LucideArrowRight, LucideCheckCircle } from "lucide-react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import { CopilotPresencePage } from "types/Presence";
import Publish from "Assets/extract/publish-extraction.png";
import { ReactComponent as Check } from "Assets/svgs/check.svg";
import { STRICTNESS_TO_META } from "const-values/Draft";
import { GenerationStatus, ResponseTolerance } from "types/Requirement";
import { useMemo } from "react";
import useImportExtraction from "hook/doc-view/useImportExtraction";
import useGetRequirements from "hook/Requirements/useGetRequirements";
import { Skeleton } from "components/molecules/skeleton";
import { theme } from "twin.macro";

const ReviewResults = () => {
  const { importExtraction, isImporting } = useImportExtraction();
  const [searchParams] = useSearchParams();
  const { extractionId } = useParams();
  const navigate = useNavigate();
  const volumes = useAppSelector((store) => store.currentExtractionState.currentExtraction?.framework.volumes);
  const totalRequirementsSelected = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.submittedRequirements || []
  );
  const extractionStatus = useAppSelector((root) => root.currentExtractionState.currentExtraction?.status);
  const isExtractionComplete = extractionStatus === ExtractionStatus.Completed;
  const instantDraftConfig = useAppSelector(
    (root) => root.currentExtractionState.currentExtraction?.instantDraftConfig
  );
  const isDraftComplete = instantDraftConfig?.status === InstantDraftStatus.Done;

  const { data: atlasRequirements, isLoading: isLoadingAtlasRequirements } = useGetRequirements(
    { analysis_id: extractionId || "" },
    { enabled: !!extractionId && isDraftComplete }
  );
  const matrix = useAppSelector((store) => store.currentExtractionState.currentExtraction?.compliance_matrix);
  const validMatrix = useMemo(
    () => matrix?.filter((row) => !row.requirement.skipped && !row.requirement.soft_deleted) || [],
    [matrix]
  );
  const respondedCount = useMemo(
    () =>
      totalRequirementsSelected.filter((reqId) => {
        const foundAtlasRequirement = atlasRequirements.find((atlasReq) => atlasReq.id === reqId);
        return foundAtlasRequirement?.response?.generation_status === GenerationStatus.Complete;
      }).length,
    [atlasRequirements, totalRequirementsSelected]
  );

  const respondedCountUnderThreshold = (respondedCount / totalRequirementsSelected.length) * 100 <= 25;
  const respondedCountColor = !respondedCountUnderThreshold ? theme`colors.green.500` : theme`colors.red.500`;
  const strictnessMeta = STRICTNESS_TO_META[instantDraftConfig?.strictness || ResponseTolerance.Strict];
  const { icon: StrictnessIcon, label: strictnessLabel } = strictnessMeta;

  if (!isExtractionComplete) {
    return (
      <div className="h-full w-full gap-8 flex flex-col justify-center items-center px-14">
        <img src={Publish} alt="publish" className="w-40 h-40" />
        <div className="text-gray-500 text-sm text-center">
          {isDraftComplete ? (
            <>
              Your proposal draft generation is complete.
              <br />
              Publish the results over to requirements and drafts.
            </>
          ) : (
            <>
              Your compliance matrix and proposal outline is ready.
              <br />
              Publish the results over to requirements and drafts.
            </>
          )}
        </div>
        <div className="text-gray-500 text-sm text-center w-1/3 min-w-[380px] max-w-[500px]">
          {isDraftComplete ? (
            <div className="flex flex-col gap-3 bg-white shadow-md rounded-md border border-gray-light py-4 px-8">
              <div className="flex flex-row gap-1.5 justify-center pb-3">
                <span className="">Strictness:</span>
                <div className="flex items-center gap-1 text-gray-darkest">
                  <StrictnessIcon />
                  {strictnessLabel}
                </div>
              </div>
              <div className="bg-gray-200 relative overflow-hidden rounded-sm h-2 w-full">
                <div
                  className="absolute duration-700 rounded-sm left-0 top-0 bottom-0"
                  style={{
                    backgroundColor: respondedCountColor,
                    width: `${(respondedCount / totalRequirementsSelected?.length) * 100}%`,
                  }}
                />
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex flex-row gap-1 justify-center">
                  <span
                    className="font-medium flex items-center"
                    css={{ ...(!isLoadingAtlasRequirements && { color: respondedCountColor }) }}
                  >
                    {isLoadingAtlasRequirements ? (
                      <>
                        <Skeleton borderRadius={4} width={16} />/<Skeleton borderRadius={4} width={16} />
                      </>
                    ) : (
                      `${respondedCount}/${totalRequirementsSelected?.length}`
                    )}
                  </span>
                  <span> requirements responded to by Vultron</span>
                </div>
                {respondedCountUnderThreshold && !isLoadingAtlasRequirements && (
                  <span className="text-gray-400 text-xs">
                    Some requirements could not be addressed due to insufficient context. Please finish generating the
                    responses in the Drafts tab.
                  </span>
                )}
              </div>
            </div>
          ) : (
            <div>
              <span className="font-medium">{validMatrix.length}</span> total requirements and{" "}
              <span className="font-medium">{volumes?.length || 0}</span> drafts included
            </div>
          )}
        </div>
        <Button
          loading={isImporting}
          disabled={isImporting}
          onClick={() => {
            importExtraction();
          }}
          variant="primaryBlack"
          size="md"
        >
          Publish Draft <LucideCheckCircle className="ml-2" size={16} />
        </Button>
      </div>
    );
  }

  return (
    <div className="h-full w-full gap-8 flex flex-col justify-center items-center px-14">
      <Check className="w-40 h-40" />
      <div className="text-gray-500 text-sm text-center">
        {isDraftComplete ? (
          <>
            You have successfully published the proposal draft.
            <br />
            Navigate to Drafts to view the published proposal drafts.
          </>
        ) : (
          <>
            You have successfully generated a compliance matrix and proposal draft outline.
            <br />
            Navigate to requirements or drafts to view the results.{" "}
          </>
        )}
      </div>
      <Button
        onClick={() => {
          const newSearchParams = new URLSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            tab: CopilotPresencePage.Template,
          });

          navigate({
            pathname: "/dashboard/contracts/details",
            search: newSearchParams.toString(),
          });
        }}
        variant="primaryBlack"
        size="md"
      >
        Go to Drafts <LucideArrowRight className="ml-2" size={16} />
      </Button>
    </div>
  );
};

export default ReviewResults;
