import StarterKit from "@tiptap/starter-kit";
import Table from "@tiptap/extension-table";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TaskList from "@tiptap/extension-task-list";
import TextAlign from "@tiptap/extension-text-align";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";
import FontFamily from "@tiptap/extension-font-family";
import Image from "@tiptap/extension-image";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import { Document } from "@tiptap/extension-document";
import { ExtensionFontSize } from "components/yjs-editor/components/extensions/ExtensionFontSize";
import { Markdown as TiptapMarkdown } from "tiptap-markdown";
import { useEditor, EditorContent, Editor } from "@tiptap/react";
import { ContentLibraryImage } from "components/yjs-editor/components/ContentLibrary/ContentLibraryImage";
import { CustomTableCell } from "components/yjs-editor/components/CustomTableCell";
import { CustomTaskItem } from "components/yjs-editor/components/CustomTaskItem";
import { CustomListItem } from "components/yjs-editor/components/extensions/CustomListItem";
import PasteExtension from "components/yjs-editor/components/extensions/PasteExtension";
import { useEffect } from "react";

export const SHARED_EXTENSIONS = [
  StarterKit.configure({
    document: false,
    blockquote: {
      HTMLAttributes: {
        class: "tiptap-blockquote",
      },
    },
    heading: {
      levels: [1, 2, 3, 4, 5],
      HTMLAttributes: {
        class: "tiptap-heading",
      },
    },
    // The Collaboration extension comes with its own history handling
    history: false,
    horizontalRule: {
      HTMLAttributes: {
        class: "tiptap-hr",
      },
    },
    listItem: false,
    orderedList: {
      HTMLAttributes: {
        class: "tiptap-ordered-list",
      },
    },
    paragraph: {
      HTMLAttributes: {
        class: "markdown-paragraph",
      },
    },
  }),
  Document.extend({
    content: "block+",
  }),
  Highlight.configure({
    HTMLAttributes: {
      class: "tiptap-highlight",
    },
  }),
  ContentLibraryImage,
  Image.configure({
    HTMLAttributes: {
      class: "tiptap-image",
    },
  }),
  Table.configure({
    HTMLAttributes: {
      class: "tiptap-table",
    },
  }),
  TableRow.configure({
    HTMLAttributes: {
      class: "tiptap-table-row",
    },
  }),
  TableHeader.configure({
    HTMLAttributes: {
      class: "tiptap-table-header",
    },
  }),
  CustomTableCell,
  Placeholder.configure({
    placeholder: "Start writing…",
    emptyEditorClass: "tiptap-empty",
  }),
  CustomTaskItem,
  TaskList.configure({
    HTMLAttributes: {
      class: "tiptap-task-list",
    },
  }),
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  Typography,
  Underline,
  TextStyle,
  ExtensionFontSize,
  FontFamily.configure({
    types: ["textStyle"],
  }),
  PasteExtension,
  CustomListItem.configure({
    HTMLAttributes: {
      class: "tiptap-list-item",
    },
  }),
  TiptapMarkdown.configure({
    html: true, // Allow HTML input/output
    tightLists: true, // No <p> inside <li> in markdown output
    bulletListMarker: "-", // <li> prefix in markdown output
    linkify: false, // Create links from "https://..." text
    breaks: false, // New lines (\n) in markdown input are converted to <br>
    transformPastedText: true, // Allow to paste markdown text in the editor
    transformCopiedText: false, // Copied text is transformed to markdown
  }),
];

export const getMarkdownExtensions = () => {
  return [...SHARED_EXTENSIONS];
};

interface MarkdownEditorProps {
  content: string;
  isStreaming?: boolean;
  streamCopy?: string;
  error?: boolean;
}

export const MarkdownEditor: React.FC<MarkdownEditorProps> = ({
  content,
  isStreaming = false,
  streamCopy = "",
  error = false,
}) => {
  const editor = useEditor({
    extensions: getMarkdownExtensions(),
    editable: false,
    content: "",
  });

  useEffect(() => {
    if (!editor || !!error) return;

    if (isStreaming && streamCopy) {
      setTimeout(() => editor.commands.setContent(streamCopy));
    } else if (!isStreaming) {
      setTimeout(() => editor.commands.setContent(content));
    }
  }, [editor, isStreaming, streamCopy, content, error]);

  if (!editor) {
    return null;
  }

  return <EditorContent editor={editor} />;
};

// Helper function to get the raw text from the editor
export const getMarkdownEditor = (content: string) => {
  const editor = new Editor({
    extensions: getMarkdownExtensions(),
    content,
  });

  return editor;
};
