import {
  createComplianceMatrixRow,
  createComplianceMatrixRowRequirement,
  createUserInstruction,
  createWritingPrompt,
} from "./../utils/complianceMatrix";
import {
  UserInstruction,
  WritingPrompt,
  RequirementStatus,
  RequirementCompliance,
} from "components/copilot/CopilotSchemaImmutableTypes";
import { Storage, ComplianceMatrixRow, ComplianceMatrixRowContentBody } from "components/copilot/CopilotSchemaTypes";
import { useUpdateTemplate } from "components/copilot/Framework/hooks";
import { addRequirementToSection, resetSourceRequirementsOrder } from "components/copilot/Framework/utils";
import { COMPLIANCE_TO_META, REQUIREMENT_STATUS_TO_META } from "const-values/Draft";
import { useTrackUserMetric } from "utils/metrics";
import { getWordCount } from "utils/getWordCount";
import { useSearchParams } from "react-router-dom";
import { useMutation } from "YJSProvider/createYJSContext";
import { filter, find, findIndex, LiveList, LiveObject, ToImmutable, update } from "YJSProvider/LiveObjects";

const useRequirementOperations = () => {
  const updateTemplate = useUpdateTemplate();
  const trackUserEvent = useTrackUserMetric();
  const [searchParams] = useSearchParams();
  const tabSlug = searchParams.get("tab")?.toLocaleLowerCase();

  const assignToSection = useMutation(({ storage }, reqId: string, sectionId?: string | null) => {
    const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    const volumes = (storage.get("framework") as Storage["framework"])?.get("volumes");
    const liveRequirement = find(complianceMatrix, (row) => reqId === row.get("requirement").get("id"));

    if (!liveRequirement) return;

    if (!sectionId) {
      const proposalReference = liveRequirement.get("proposal_reference");
      if (proposalReference) {
        update(proposalReference, {
          volume_id: "",
          section_id: "",
          subsection_id: "",
        });
        resetSourceRequirementsOrder(complianceMatrix, liveRequirement);
      }
    } else {
      addRequirementToSection({
        complianceMatrix,
        activeRow: liveRequirement,
        volumeList: volumes,
        destinationSectionId: sectionId,
      });
      trackUserEvent("Requirements: Requirement Added to Section", {
        requirement_id: String(reqId),
        section_id: String(sectionId),
      });
    }

    updateTemplate({ isDirty: true });
  }, []);

  const setRequirementContent = useMutation(
    ({ storage }, reqId: string, content: ToImmutable<ComplianceMatrixRow>["requirement"]["content"]) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      if (!complianceMatrix) return;
      const liveRequirement = find(complianceMatrix, (row) => reqId === row.get("requirement").get("id"));

      if (liveRequirement?.get("locked")) return;

      liveRequirement?.get("requirement").set("content", content);

      if (content !== liveRequirement?.get("requirement").get("content")) {
        trackUserEvent("Requirements: Requirement Updated", {
          requirement_id: String(reqId),
          word_count: getWordCount(content),
        });
      }
    },
    []
  );

  const setRequirementLock = useMutation(({ storage }, reqId: string, lock: boolean, lockActor: string) => {
    const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    if (!complianceMatrix) return;
    const liveRequirement = find(complianceMatrix, (row) => reqId === row.get("requirement").get("id"));

    liveRequirement?.set("locked", lock);
    liveRequirement?.set("locked_actor", lockActor);
  }, []);

  const setRequirementStatus = useMutation(
    ({ storage }, reqId: string, status: ToImmutable<ComplianceMatrixRow>["requirement_status"]) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      if (!complianceMatrix) return;
      const liveRequirement = find(complianceMatrix, (row) => reqId === row.get("requirement").get("id"));

      liveRequirement?.set("requirement_status", status);

      trackUserEvent("Requirements: Requirement Status Updated", {
        requirement_id: reqId,
        new_requirement_status: status
          ? REQUIREMENT_STATUS_TO_META[status]?.label
          : REQUIREMENT_STATUS_TO_META[RequirementStatus.Todo].label,
      });
    },
    []
  );

  const setGeneratedHeading = useMutation(
    ({ storage }, reqId: string, heading: ToImmutable<ComplianceMatrixRow>["requirement"]["generated_heading"]) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const liveRequirement = find(complianceMatrix, (row) => reqId === row.get("requirement").get("id"));

      liveRequirement?.get("requirement").set("generated_heading", heading);
    },
    []
  );

  const setComplianceStatus = useMutation(
    ({ storage }, reqId: String, status: ToImmutable<ComplianceMatrixRow>["compliance_status"]) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const liveRequirement = find(complianceMatrix, (row) => reqId === row.get("requirement").get("id"));

      liveRequirement?.set("compliance_status", status);

      trackUserEvent("Requirements: Compliance Status Updated", {
        requirement_id: reqId,
        new_compliance_status: status
          ? COMPLIANCE_TO_META[status]?.label
          : COMPLIANCE_TO_META[RequirementCompliance.Empty].label,
      });
    },
    []
  );

  const setAssignees = useMutation(
    ({ storage }, reqId: string, assignees: ToImmutable<ComplianceMatrixRow>["assigned_user_ids"]) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const liveRequirement = find(complianceMatrix, (row) => reqId === row.get("requirement").get("id"));

      liveRequirement?.set("assigned_user_ids", assignees);

      trackUserEvent("Requirements: Assignee Updated", {
        requirement_id: reqId,
        new_assignees_count: assignees?.length ?? 0,
      });
    },
    []
  );

  const setSheet = useMutation(({ storage }, reqId: string, sheetId: string) => {
    const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    const liveRequirement = find(complianceMatrix, (row) => reqId === row.get("requirement").get("id"));

    liveRequirement?.get("requirement").set("extraction_id", sheetId);

    trackUserEvent("Requirements: Sheet Updated", {
      requirement_id: reqId,
      sheet_id: sheetId,
    });
  }, []);

  const addNewWritingPrompt = useMutation(({ storage }, reqId: string, properties?: Partial<WritingPrompt>) => {
    const newLiveWritingPrompt = createWritingPrompt(properties);
    const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    const liveRequirement = find(complianceMatrix, (row) => reqId === row.get("requirement").get("id"));
    if (liveRequirement?.get("locked")) return;

    const liveWritingPrompts = liveRequirement?.get("writing_prompts");
    if (!liveWritingPrompts?.length) {
      liveRequirement?.set("writing_prompts", new LiveList([newLiveWritingPrompt]));
    } else liveRequirement?.get("writing_prompts")?.push([newLiveWritingPrompt]);

    return newLiveWritingPrompt.toJSON();
  }, []);

  const saveWritingPromptProperties = useMutation(
    ({ storage }, reqId: string, promptId: string, properties: Partial<WritingPrompt>) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const row = find(complianceMatrix, (row) => reqId === row.get("requirement").get("id"));
      if (!row || row.get("locked")) return;
      const writingPrompts = row.get("writing_prompts") as LiveList<LiveObject<WritingPrompt>> | undefined;
      const livePrompt = writingPrompts && find(writingPrompts, (prompt) => prompt.get("id") === promptId);
      if (!livePrompt) return;
      update(livePrompt, properties);
    },
    []
  );

  const deleteWritingPrompt = useMutation(({ storage }, reqId: string, promptid: string) => {
    const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    if (!complianceMatrix) return;
    const row = find(complianceMatrix, (row) => reqId === row.get("requirement").get("id"));

    if (row?.get("locked")) return;
    const livePrompts = row?.get("writing_prompts") as LiveList<LiveObject<WritingPrompt>> | undefined;
    if (!livePrompts) return;
    const livePromptIndex = findIndex(livePrompts, (prompt) => prompt.get("id") === promptid);

    if (typeof livePromptIndex === "number" && livePromptIndex >= 0) livePrompts?.delete(livePromptIndex);
  }, []);

  const saveRequirementFileIdFilters = useMutation(({ storage }, reqId: string, fileIdFilters: string[]) => {
    const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    const row = find(complianceMatrix, (row) => reqId === row.get("requirement").get("id"));
    if (!row || row.get("locked")) return;
    const requirementFileIdFilters = row.get("requirement_file_id_filters") as LiveList<string> | undefined;
    if (!requirementFileIdFilters) {
      row.set("requirement_file_id_filters", new LiveList(fileIdFilters));
    } else {
      fileIdFilters.forEach((fileId) => {
        if (!requirementFileIdFilters.toArray().includes(fileId)) {
          requirementFileIdFilters.push([fileId]);
        }
      });
    }
  }, []);

  const addNewUserInstruction = useMutation(({ storage }, reqId: string, content: string) => {
    const newLiveUserInstruction = createUserInstruction(content);
    const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    const liveRequirement = find(complianceMatrix, (row) => reqId === row.get("requirement").get("id"));
    if (liveRequirement?.get("locked")) return;
    const liveUserInstructions = liveRequirement?.get("user_instructions");

    if (!liveUserInstructions?.length) {
      liveRequirement?.set("user_instructions", new LiveList([newLiveUserInstruction]));
    } else liveRequirement?.get("user_instructions")?.push([newLiveUserInstruction]);

    return newLiveUserInstruction.toJSON();
  }, []);

  const saveWritingInstructionProperties = useMutation(
    ({ storage }, reqId: string, instructionId: string, properties: Partial<UserInstruction>) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const row = find(complianceMatrix, (row) => reqId === row.get("requirement").get("id"));
      if (row?.get("locked")) return;

      const userInstruction = row?.get("user_instructions") as LiveList<LiveObject<UserInstruction>> | undefined;
      if (!userInstruction) return;

      const liveInstruction = find(userInstruction, (instruction) => instruction.get("id") === instructionId);
      if (!liveInstruction) return;
      update(liveInstruction, properties);
    },
    []
  );

  const deleteWritingInstruction = useMutation(({ storage }, reqId: string, instructionId: string) => {
    const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    if (!complianceMatrix) return;
    const row = find(complianceMatrix, (row) => reqId === row.get("requirement").get("id"));
    const liveInstructions = row?.get("user_instructions") as LiveList<LiveObject<UserInstruction>> | undefined;

    if (row?.get("locked")) return;
    if (!liveInstructions) return;

    const liveInstructionIndex = findIndex(liveInstructions, (instruction) => instruction.get("id") === instructionId);

    if (typeof liveInstructionIndex === "number" && liveInstructionIndex >= 0)
      liveInstructions?.delete(liveInstructionIndex);
  }, []);

  const deleteRequirementRow = useMutation(({ storage }, reqId: string) => {
    const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    if (!complianceMatrix) return;
    const rowIndex = findIndex(complianceMatrix, (row) => row.get("requirement")?.get("id") === reqId);

    if (rowIndex >= 0) (storage.get("compliance_matrix") as Storage["compliance_matrix"]).delete(rowIndex);
  }, []);

  const disregardRequirement = useMutation(({ storage }, reqId: string, undoDisregard?: boolean) => {
    const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    if (!complianceMatrix) return;
    const complianceMatrixRow = find(complianceMatrix, (row) => row?.get("requirement")?.get("id") === reqId);

    if (!complianceMatrixRow) return;

    if (undoDisregard) {
      complianceMatrixRow.get("requirement")?.set("disregarded", false);
    } else {
      complianceMatrixRow.get("requirement")?.set("disregarded", true);
    }

    assignToSection(reqId, null);
  }, []);

  const appendGeneratedContentIdeas = useMutation(
    ({ storage }, reqId: string, generatedContentIdeas: ToImmutable<ComplianceMatrixRowContentBody>[]) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      if (!complianceMatrix) return;
      const complianceMatrixRow = find(complianceMatrix, (row) => row?.get("requirement")?.get("id") === reqId);

      const liveContentIdeas = complianceMatrixRow?.get("generated_content_ideas_v2");
      if (!liveContentIdeas?.length) {
        complianceMatrixRow?.set("generated_content_ideas_v2", new LiveList(generatedContentIdeas));
      } else {
        generatedContentIdeas?.forEach((idea) => {
          liveContentIdeas?.push([idea]);
        });
      }
    },
    []
  );

  const appendSelectedContentIdeas = useMutation(
    ({ storage }, reqId: string, selectedContentIdeas: ToImmutable<ComplianceMatrixRowContentBody>[]) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      if (!complianceMatrix) return;
      const complianceMatrixRow = find(complianceMatrix, (row) => row?.get("requirement")?.get("id") === reqId);

      const liveContentIdeas = complianceMatrixRow?.get("selected_content_v2");
      if (!liveContentIdeas?.length) {
        complianceMatrixRow?.set("selected_content_v2", new LiveList(selectedContentIdeas));
      } else {
        selectedContentIdeas?.forEach((idea) => {
          liveContentIdeas?.push([idea]);
        });
      }
    },
    []
  );

  const setSkipped = useMutation(({ storage }, reqId: string, skipped: boolean) => {
    const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    if (!complianceMatrix) return;
    const complianceMatrixRow = find(complianceMatrix, (row) => row?.get("requirement")?.get("id") === reqId);

    if (!complianceMatrixRow) return;

    complianceMatrixRow.get("requirement")?.set("skipped", skipped);
  }, []);

  const setNotes = useMutation(({ storage }, reqId: string, notes: string) => {
    const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    const complianceMatrixRow = find(complianceMatrix, (row) => row?.get("requirement")?.get("id") === reqId);

    complianceMatrixRow?.set("notes", notes);
  }, []);

  const splitRequirements = useMutation(
    ({ storage }, reqId: string, newReqs: string[], retainSection?: boolean) => {
      const newRows: ToImmutable<ComplianceMatrixRow>[] = [];
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      if (!complianceMatrix) return;
      const complianceMatrixRowIndex = findIndex(
        complianceMatrix,
        (row) => row?.get("requirement")?.get("id") === reqId
      );
      const complianceMatrixRow = complianceMatrix.get(complianceMatrixRowIndex);
      const immutableRow = complianceMatrixRow?.toJSON();

      if (!complianceMatrixRow) return;

      newReqs.forEach((content, i) => {
        const createdRequirement = createComplianceMatrixRowRequirement({
          extraction_id: immutableRow?.requirement.extraction_id,
          content,
          ...(retainSection && {
            section_order: complianceMatrixRow?.get("requirement").get("section_order"),
          }),
        });
        const newRow = createComplianceMatrixRow({
          requirement: createdRequirement,
          ...(retainSection && {
            proposal_reference: new LiveObject(complianceMatrixRow.get("proposal_reference").toJSON()),
          }),
        });
        complianceMatrix.insert(complianceMatrixRowIndex + i + 1, [newRow]);

        newRows.push(newRow.toJSON() as ToImmutable<ComplianceMatrixRow>);
      });

      if (retainSection) {
        const sectionRequirements = filter(
          complianceMatrix,
          (row) =>
            row.get("proposal_reference").get("section_id") ===
            complianceMatrixRow.get("proposal_reference").get("section_id")
        );
        const sortedRows = [...sectionRequirements].sort(
          (a, b) =>
            (a.get("requirement")?.get("section_order") || 0) - (b.get("requirement")?.get("section_order") || 0)
        );
        sortedRows.forEach((row, idx) => {
          row.get("requirement")?.set("section_order", idx);
        });
      }

      trackUserEvent("Requirements: Requirements Split", {
        tab: tabSlug,
        requirement_word_count: getWordCount(immutableRow?.written_content || ""),
      });

      return newRows;
    },
    [tabSlug]
  );

  return {
    setRequirementStatus,
    setComplianceStatus,
    assignToSection,
    setAssignees,
    setRequirementContent,
    addNewWritingPrompt,
    saveWritingPromptProperties,
    addNewUserInstruction,
    saveWritingInstructionProperties,
    saveRequirementFileIdFilters,
    deleteWritingPrompt,
    deleteWritingInstruction,
    deleteRequirementRow,
    disregardRequirement,
    appendGeneratedContentIdeas,
    appendSelectedContentIdeas,
    setSheet,
    setSkipped,
    setNotes,
    splitRequirements,
    setRequirementLock,
    setGeneratedHeading,
  };
};

export default useRequirementOperations;
