import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EvaluationModalProps } from "components/copilot/ComplianceMatrix/EvaluationModal";
import { TemplatesModalProps } from "components/copilot/Framework/TemplatesModal";
import { CreateProjectModalProps } from "components/modals/create-project-modal/CreateProjectModal";
import { PreviewProposalModalProps } from "components/modals/preview-proposal-modal/PreviewProposalModal";
import { SaveTemplateModalProps } from "components/modals/save-template-modal";
import { StrictnessModalProps } from "components/modals/strictness-modal/StrictnessModal";
import { WritingPromptIdeationModalProps } from "components/modals/writing-prompt-ideation-modal/WritingPromptIdeationModal";
import { SourceModalProps } from "components/organisms/global-modals/SourceModal";
import { DocumentViewerModalProps } from "components/organisms/global-modals/DocumentViewerModal";
import { RequirementExtractionModalProps } from "components/organisms/requirement-extraction-modal/RequirementExtractionModal";

enum Modals {
  SourceModal = "sourceModal",
  TemplatesModal = "templatesModal",
  SaveTemplateModal = "saveTemplateModal",
  CreateProjectModal = "createProjectModal",
  WritingPromptIdeationModal = "writingPromptIdeationModal",
  RequirementExtractionModal = "requirementExtractionModal",
  StrictnessModal = "strictnessModal",
  EvaluationModal = "evaluationModal",
  PreviewProposalModal = "previewProposalModal",
  DocumentViewerModal = "documentViewerModal",
}

type InitialState = {
  [Modals.SourceModal]: SourceModalProps;
  [Modals.RequirementExtractionModal]: RequirementExtractionModalProps;
  [Modals.TemplatesModal]: TemplatesModalProps;
  [Modals.SaveTemplateModal]: SaveTemplateModalProps;
  [Modals.CreateProjectModal]: CreateProjectModalProps;
  [Modals.WritingPromptIdeationModal]: WritingPromptIdeationModalProps;
  [Modals.StrictnessModal]: StrictnessModalProps;
  [Modals.EvaluationModal]: EvaluationModalProps;
  [Modals.PreviewProposalModal]: PreviewProposalModalProps;
  [Modals.DocumentViewerModal]: DocumentViewerModalProps;
};

const initialState: InitialState = {
  sourceModal: {
    open: false,
    initialProps: {},
  },
  saveTemplateModal: {
    open: false,
    initialProps: {},
  },
  templatesModal: {
    open: false,
  },
  createProjectModal: {
    open: false,
  },
  writingPromptIdeationModal: {
    open: false,
  },
  requirementExtractionModal: {
    open: false,
  },
  strictnessModal: {
    open: false,
    initialProps: {},
  },
  evaluationModal: {
    open: false,
    initialProps: {},
  },
  documentViewerModal: {
    open: false,
    initialProps: {},
  },
  previewProposalModal: {
    open: false,
  },
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    toggleSourceModal: (state: InitialState, action: PayloadAction<SourceModalProps>) => {
      return { ...initialState, sourceModal: { ...state.sourceModal, ...action.payload } };
    },
    toggleTemplatesModal: (state, action: PayloadAction<TemplatesModalProps>) => {
      return { ...state, templatesModal: action.payload };
    },
    toggleSaveTemplateModal: (state, action: PayloadAction<SaveTemplateModalProps>) => {
      return { ...state, saveTemplateModal: action.payload };
    },
    toggleCreateProjectModal: (state, action: PayloadAction<CreateProjectModalProps>) => {
      return { ...state, createProjectModal: action.payload };
    },
    toggleWritingPromptsIdeationModal: (state, action: PayloadAction<WritingPromptIdeationModalProps>) => {
      return { ...state, writingPromptIdeationModal: action.payload };
    },
    toggleRequirementExtractionModal: (state, action: PayloadAction<RequirementExtractionModalProps>) => {
      return { ...state, requirementExtractionModal: action.payload };
    },
    toggleStrictnessModal: (state, action: PayloadAction<StrictnessModalProps>) => {
      return { ...state, strictnessModal: action.payload };
    },
    toggleEvaluationModal: (state, action: PayloadAction<EvaluationModalProps>) => {
      return { ...state, evaluationModal: action.payload };
    },
    togglePreviewProposalModal: (state, action: PayloadAction<PreviewProposalModalProps>) => {
      return { ...state, previewProposalModal: action.payload };
    },
    toggleDocumentViewerModal: (state, action: PayloadAction<DocumentViewerModalProps>) => {
      return { ...state, documentViewerModal: action.payload };
    },
  },
});

export default modalsSlice.reducer;
export const {
  toggleSourceModal,
  toggleTemplatesModal,
  toggleSaveTemplateModal,
  toggleCreateProjectModal,
  toggleWritingPromptsIdeationModal,
  toggleRequirementExtractionModal,
  toggleStrictnessModal,
  toggleEvaluationModal,
  togglePreviewProposalModal,
  toggleDocumentViewerModal,
} = modalsSlice.actions;
