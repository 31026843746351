/** @jsxImportSource @emotion/react */

import { ExtractionStatus, Volume } from "components/copilot/CopilotSchemaTypes";
import { EllipsisVertical, LucidePlus } from "lucide-react";
import Icon from "components/atoms/icons/Icon";
import SectionRow from "../section-row";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { HTMLAttributes, forwardRef, memo, useMemo, useState } from "react";
import { setEditableTemplateRowState } from "store/reducers/extract/CurrentExtractionReducer";
import { StepValue } from "../../../types";
import tw, { theme } from "twin.macro";
import useExtractionOperations from "hook/useExtractionOperations";
import { useDraftDropdownItems, useTemplateRowOperations } from "../hooks";
import { useDrag } from "./hooks";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { DndContext, DragOverlay, DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import SortableItem from "../section-row/SortableItem";
import { verticalSortableListCollisionDetection } from "pages/draft-volume/draft-volume-sidebar/utils";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { isInstantDraftStarted } from "../../../utils";
import { scrollToTemplateManager } from "../utils";
import Tooltip from "components/atoms/tooltip";
import { EMPTY_VALUE } from "const-values/Copilot";

interface Props extends HTMLAttributes<HTMLDivElement> {
  draft: ToImmutable<Omit<Volume, "sections">> & { sections: FormattedSection[] };
  withOpacity?: boolean;
  isDragging?: boolean;
  dragProps?: { attributes?: DraggableAttributes; listeners?: SyntheticListenerMap };
}

const DraftRow = forwardRef<HTMLDivElement, Props>(({ dragProps, isDragging, withOpacity, draft, style }, ref) => {
  const [portalRef, setPortalRef] = useState<HTMLDivElement | null>(null);
  const [titleRef, setTitleRef] = useState<HTMLSpanElement | null>(null);
  const [titleContainerRef, setTitleContainerRef] = useState<HTMLDivElement | null>(null);
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const editableTemplateRowState = useAppSelector((store) => store.currentExtractionState.editableTemplateRowState);
  const { handleAddVolume, handleAddSection } = useTemplateRowOperations(extraction?.id);
  const { sensors, dragSections, handleDragEnd, handleDragStart, handleDragCancel, activeDragId } = useDrag(
    draft.sections,
    draft.id,
    extraction?.id
  );
  const dispatch = useAppDispatch();
  const isReadOnly =
    extraction?.status === ExtractionStatus.Completed || isInstantDraftStarted(extraction?.instantDraftConfig?.status);
  const { setDraftName } = useExtractionOperations();
  const { items } = useDraftDropdownItems(draft, extraction?.id);
  const isImportStep = extraction?.step === StepValue.Review;
  const isEditing = editableTemplateRowState.id === draft.id;

  const activeDragSection = useMemo(
    () => draft.sections.find((draft) => draft.id === activeDragId),
    [activeDragId, draft.sections]
  );

  const shouldShowTootlipTitle = useMemo(
    () =>
      !isEditing &&
      !isDragging &&
      !!draft.title &&
      (titleRef?.clientWidth || 0) >= (titleContainerRef?.clientWidth || 0),
    [isDragging, isEditing, titleContainerRef?.clientWidth, titleRef?.clientWidth, draft.title]
  );

  return (
    <div
      className="rounded-md px-1 pt-2"
      ref={ref}
      css={[
        isDragging && tw`bg-white`,
        isDragging && isImportStep && tw`bg-layout-gray-light`,
        {
          boxShadow: isDragging ? theme`boxShadow.expanded` : "none",
          zIndex: isDragging ? "4" : "auto",
          opacity: withOpacity ? "0.3" : "1",
          pointerEvents: isEditing ? "none" : "auto",
          ...style,
        },
      ]}
      {...dragProps?.attributes}
    >
      <div ref={setPortalRef}>
        <div className="group gap-0.5 flex flex-row items-center">
          {isReadOnly && <span className="opacity-40 pr-2">{EMPTY_VALUE}</span>}

          {!isReadOnly && (
            <div className="flex flex-row items-center">
              <DropdownMenu
                portalProps={{ container: portalRef }}
                items={[
                  {
                    key: 1,
                    label: "Volume",
                    onSelect: () => {
                      const idx = extraction?.framework?.volumes?.findIndex((volume) => volume.id === draft.id) || 0;
                      handleAddVolume(idx === -1 ? 0 : idx + 1);
                    },
                  },
                  {
                    key: 2,
                    label: "Section",
                    onSelect: () => {
                      handleAddSection(draft.id);
                    },
                  },
                ]}
              >
                <div className="z-[1] bg-transparent rounded p-0.5 text-slate-500 hover:text-slate-900 hover:bg-gray-200">
                  <LucidePlus size={12} />
                </div>
              </DropdownMenu>
              <div
                className="z-[1] bg-transparent rounded p-0.5 text-slate-500 hover:text-slate-900 hover:bg-slate-200"
                css={[{ cursor: isDragging ? "grabbing" : "grab" }]}
                {...dragProps?.listeners}
              >
                <Icon name="Draggable" className="w-3 h-3" />
              </div>
            </div>
          )}
          <Tooltip
            contentProps={{ align: "start", side: "left", sideOffset: 34, alignOffset: 6 }}
            content={draft.title}
            disableHoverableContent
            disabled={!shouldShowTootlipTitle}
            delayDuration={800}
          >
            <div
              className="relative group overflow-hidden flex-1 w-[calc(100%-30px)]"
              css={[isReadOnly && tw`w-[calc(100%-12px)] overflow-visible`]}
            >
              <div className="text-gray-darkest flex items-center gap-2 w-full text-left">
                {editableTemplateRowState.id === draft.id ? (
                  <input
                    autoFocus
                    onChange={(e) => {
                      dispatch(setEditableTemplateRowState({ localValue: e.target.value }));
                      e.stopPropagation();
                    }}
                    placeholder="Draft title..."
                    onKeyDown={(e) => {
                      if (e.code === "Enter") {
                        if (extraction?.id) {
                          setDraftName(extraction.id, draft.id, editableTemplateRowState.localValue);
                        }
                        setTimeout(() => dispatch(setEditableTemplateRowState({ id: "", localValue: "" })), 100);
                      }
                    }}
                    onBlur={() => {
                      if (extraction?.id) {
                        setDraftName(extraction.id, draft.id, editableTemplateRowState.localValue);
                      }
                      setTimeout(() => dispatch(setEditableTemplateRowState({ id: "", localValue: "" })), 100);
                    }}
                    value={editableTemplateRowState.localValue}
                    className="text-sm py-2 font-semibold outline-none text-gray-darkest w-full bg-transparent"
                  />
                ) : (
                  <div className="text-sm font-semibold py-2 min-w-0 w-full">
                    <div
                      ref={setTitleContainerRef}
                      className="flex flex-row items-center"
                      onClick={() => scrollToTemplateManager(`template-manager-volume-${draft.id}`, true)}
                      css={[!draft.title.trim() && tw`text-gray-400`]}
                    >
                      <span className="whitespace-nowrap" ref={setTitleRef}>
                        {draft.title || "Draft title..."}
                      </span>
                    </div>
                    {!isEditing && (
                      <div
                        className="absolute bottom-0 right-0 top-0 to-transparent bg-gradient-to-l w-14 from-white"
                        css={[!isReadOnly && tw`group-hover:w-16 group-hover:from-50% group-hover:from-white`]}
                      />
                    )}
                  </div>
                )}
              </div>
              {!isReadOnly && (
                <div
                  className="opacity-0 text-slate-700 flex items-center gap-1.5 text-sm absolute bottom-0 right-0 top-0 pl-2 pr-2 bg-white group-hover:opacity-100"
                  css={[isEditing && tw`hidden`, isImportStep && tw`bg-layout-gray-light`]}
                >
                  <DropdownMenu
                    portalProps={{ container: portalRef }}
                    contentProps={{ align: "end", css: tw`min-w-[120px]` }}
                    items={items}
                  >
                    <div className="">
                      <EllipsisVertical size={14} />
                    </div>
                  </DropdownMenu>
                </div>
              )}
            </div>
          </Tooltip>
        </div>
        <div>
          <DndContext
            sensors={sensors}
            collisionDetection={verticalSortableListCollisionDetection}
            onDragEnd={(event) => handleDragEnd(event)}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
          >
            <SortableContext
              id="EXTRACTION_TEMPLATE_SECTIONS"
              items={dragSections || []}
              strategy={verticalListSortingStrategy}
            >
              {dragSections?.map((section, i) => {
                return <SortableItem key={section.id} volumeId={draft.id} section={section} />;
              })}
              <DragOverlay style={{ transformOrigin: "0 0 " }}>
                {!!activeDragId && activeDragSection && (
                  <SectionRow volumeId={draft.id} section={activeDragSection} isDragging />
                )}
              </DragOverlay>
            </SortableContext>
          </DndContext>
        </div>
      </div>
    </div>
  );
});

export default memo(DraftRow);
