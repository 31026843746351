import { FC, SVGProps } from "react";
import { FormItemStatus } from "types/Capture";
import { ReactComponent as StatusTodo } from "Assets/statuses/form-item/todo.svg";
import { ReactComponent as StatusInProgress } from "Assets/statuses/form-item/in-progress.svg";
import { ReactComponent as StatusDone } from "Assets/statuses/form-item/done.svg";
import { theme } from "twin.macro";

export const CAPTURE_SCHEMA_TYPE_PREFIX = "capture";
export const CAPTURE_STATIC_PAGES_META = { "win-themes": { label: "Win Themes", slug: "win-themes" } };
export const CAPTURE_CREATE_INITIAL_FORMS_KEY = "capture/creatingForms";
export const FORM_ITEM_STATUS_TO_META: Record<
  FormItemStatus,
  { label: string; color: string; icon: FC<SVGProps<{}>> }
> = {
  [FormItemStatus.NotStarted]: {
    label: "To Do",
    color: theme`colors.slate.600`,
    icon: StatusTodo,
  },
  [FormItemStatus.InProgress]: {
    label: "In Progress",
    color: theme`colors.yellow.400`,
    icon: StatusInProgress,
  },
  [FormItemStatus.Done]: {
    label: "Done",
    color: theme`colors.green.500`,
    icon: StatusDone,
  },
};
