export const statusList = [
  {
    color: "#00a9b2",
    bg: "#cefcff",
    label: "Interested",
  },
  {
    color: "#df8600",
    bg: "#ffe8c5",
    label: "Work in progress",
  },
  {
    color: "#3f7cff",
    bg: "#d3e1fe",
    label: "Submitted",
  },
  {
    color: "#8202e9",
    bg: "#ead1fe",
    label: "Cancelled",
  },
  {
    color: "#00b076",
    bg: "#bef1e0",
    label: "Won opportunity",
  },
  {
    color: "#dd0000",
    bg: "#ffd7d7",
    label: "Lost opportunity",
  },
  {
    color: "#202020",
    bg: "#d6d6d6",
    label: "Archived",
  },
];
