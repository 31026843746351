export const colorList = [
  { color: "#4F00B2", bg: "#D9BBFF" },
  { color: "#339E00", bg: "#B2DC9E" },
  { color: "#00809D", bg: "#90C8D4" },
  { color: "#E8AB7E", bg: "#CF5800" },
  { color: "#AA0000", bg: "#FFBBBB" },
  { color: "#339E00", bg: "#B2DC9E" },
  { color: "#AA0000", bg: "#FFBBBB" },
  { color: "#E8AB7E", bg: "#CF5800" },
  { color: "#00809D", bg: "#90C8D4" },
  { color: "#4F00B2", bg: "#D9BBFF" },
  { color: "#E8AB7E", bg: "#CF5800" },
  { color: "#00A8CC", bg: "#B4F8C8" }, // Teal
  { color: "#FF5733", bg: "#FFF7A5" }, // Orange
  { color: "#800080", bg: "#E6E6FA" }, // Purple
  { color: "#FF1493", bg: "#FFB6C1" }, // Pink
  { color: "#008000", bg: "#90EE90" }, // Green
  { color: "#FFD700", bg: "#FFEFD5" }, // Gold
];
