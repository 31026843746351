import { Mark, mergeAttributes } from "@tiptap/core";
import { v4 } from "uuid";
import { YJSProvider } from "YJSProvider/YJSProvider";
import { CommentsExtension } from "./CommentsExtension";

export const CommentsMark = Mark.create<{
  provider: YJSProvider;
  removeThread: (thread: string) => void;
  restoreThread: (thread: string) => void;
}>({
  name: "comment",
  inclusive: false,
  excludes: "",

  addOptions() {
    return {
      provider: undefined as unknown as YJSProvider,
      internalContractId: undefined,
      volumeId: undefined,
      removeThread: () => {},
      restoreThread: () => {},
    };
  },

  defaultAttributes: {
    id: v4(),
    resolved: false,
  },

  addAttributes() {
    return {
      id: {
        default: null,
        renderHTML: (attributes) => {
          return { "data-comment-id": attributes.id };
        },
      },
      resolved: {
        default: false,
        renderHTML: (attributes) => {
          return {
            "data-comment-resolved": attributes.resolved.toString(),
            class: attributes.resolved ? "comment-resolved" : "",
          };
        },
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ["span", mergeAttributes(HTMLAttributes), 0];
  },

  parseHTML() {
    return [
      {
        tag: "span[data-comment-id]",
        getAttrs: (node) => {
          if (!(node instanceof HTMLElement)) return {};
          return {
            id: node.getAttribute("data-comment-id"),
            resolved: node.getAttribute("data-comment-resolved") === "true",
          };
        },
      },
    ];
  },

  addExtensions() {
    return [CommentsExtension.configure(this.options)];
  },
});
