import { EllipsisVertical } from "lucide-react";
import { Button } from "../../primitives/Button";
import { Editor } from "@tiptap/react";
import { ToolbarSection } from "../toolbar-sections/types";

import styles from "../Toolbar.module.css";
import Tooltip from "components/atoms/tooltip/Tooltip";
import * as Popover from "@radix-ui/react-popover";

export const OverflownToolbarButton = ({
  overflowStates,
  toolbarSections,
  editor,
  triggerRef,
}: {
  toolbarSections: ToolbarSection[];
  overflowStates: { [key: string]: boolean };
  editor: Editor;
  triggerRef: React.Ref<HTMLButtonElement>;
}) => {
  return (
    <Popover.Root>
      <Popover.Trigger>
        <Tooltip content="More">
          <Button variant="subtle" ref={triggerRef}>
            <EllipsisVertical size={14} />
          </Button>
        </Tooltip>
      </Popover.Trigger>
      <Popover.Content
        side="bottom"
        align="end"
        className="shadow-lg pl-3 py-1 bg-gray-50 rounded-lg flex flex-row z-50"
      >
        {toolbarSections
          .filter((section) => overflowStates[section.key])
          .map((section) => (
            <div className={styles.toolbarSection}>
              <section.component key={section.key} editor={editor} />
            </div>
          ))}
      </Popover.Content>
    </Popover.Root>
  );
};
