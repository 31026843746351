import { Editor } from "@tiptap/react";
import { ExpandIcon } from "utils/icons";
import { isExpandedAction } from "store/reducers/aiReducerSlice";
import { OverflownToolbarButton } from "./toolbar-overflow/OverflowToolbarButton";
import { ToolbarMargins } from "./toolbar-margins/ToolbarMargins";
import { toolbarSections } from "./toolbar-sections/constants";
import { useDispatch } from "react-redux";
import { useRef } from "react";
import { useToolbarOverflow } from "./toolbar-overflow/useToolbarOverflow";
import styles from "./Toolbar.module.css";

type Props = {
  editor: Editor;
  toolbarContainerRef: React.RefObject<HTMLDivElement>;
  fullscreen?: boolean;
};

export function Toolbar({ editor, fullscreen, toolbarContainerRef }: Props) {
  const dispatch = useDispatch();
  const sectionsRef = useRef<Record<string, HTMLElement | null>>({});

  const overflowStates = useToolbarOverflow(toolbarContainerRef, sectionsRef);

  const renderToolbarComponents = () => {
    let overflowButtonRendered = false;
    return toolbarSections.map((section) => {
      const isOverflowing = overflowStates[section.key];
      const toolbarSectionComponent = (
        <div
          key={section.key}
          ref={(el) => (sectionsRef.current[section.key] = el)}
          className={`${styles.toolbarSection} ${isOverflowing ? styles.toolbarSectionOverflow : ""}`}
        >
          <section.component editor={editor} />
        </div>
      );
      if (isOverflowing && !overflowButtonRendered) {
        overflowButtonRendered = true;
        return (
          <div className={styles.toolbarSection}>
            <OverflownToolbarButton
              overflowStates={overflowStates}
              toolbarSections={toolbarSections}
              editor={editor}
              triggerRef={(el) => (sectionsRef.current["overflowButton"] = el)}
            />
            {toolbarSectionComponent}
          </div>
        );
      }
      return toolbarSectionComponent;
    });
  };

  return (
    <div className={styles.toolbar}>
      <div className={styles.avatarAndExpand}>
        {!fullscreen && (
          <span
            onClick={() => dispatch(isExpandedAction())}
            role="button"
            aria-label="Expand"
            className={`${styles.toolbarButton} cursor-pointer select-none w-max max-w-full}`}
          >
            <ExpandIcon />
          </span>
        )}
        <div className="mr-3">
          <ToolbarMargins editor={editor} />
        </div>
      </div>
      {renderToolbarComponents()}
    </div>
  );
}
