// ---------- Not found Results message component  -------------------
const NotFoundItems = ({ title, subTitle, className }) => {
  return (
    <div className={`bg-inherit w-full flex justify-center items-center flex-col py-7 px-2 ${className || ""}`}>
      <h1 className="font-bold text-lg">{title || "Not found"}</h1>
      {subTitle && <span className="text-gray-text">{subTitle}</span>}
    </div>
  );
};

export default NotFoundItems;
