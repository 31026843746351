import axios from "axios";
import { CommentThread } from "components/Comments/types";
import {
  CreateCommentMutationVariables,
  DeleteCommentMutationVariables,
  DeleteThreadMutationVariables,
  EditorCommentMutationVariables,
  ResolveThreadMutationVariables,
  RestoreThreadMutationVariables,
  UpdateReadMutationVariables,
} from "./types";

export const threadQueryKey = (internalContractId: string, reference_id?: string) => [
  "threads",
  internalContractId,
  reference_id,
];

export const getThreads = async (internalContractId: string, reference_id?: string) => {
  const res = await axios.get<CommentThread[]>(`/contracts/internal/${internalContractId}/threads`, {
    params: {
      reference_id,
    },
  });
  return res.data
    .map((thread: CommentThread) => {
      return {
        ...thread,
        comments: thread.comments.map((comment) => {
          return {
            ...comment,
            created_at: new Date(comment.created_at),
            updated_at: new Date(comment.updated_at),
          };
        }),
        created_at: new Date(thread.created_at),
        updated_at: new Date(thread.updated_at),
      };
    })
    .sort((a, b) => a.created_at.getTime() - b.created_at.getTime());
};

/**
 * Creates a comment, when a threadId is not passed, it creates a new thread
 * @param threadId existing thread id to add the comment to
 * @param newThreadId new thread id to create and add the comment to
 * @returns the created/updated threadId
 */
export const createComment = async ({
  content,
  thread_id,
  new_thread_id,
  new_comment_id,
  internalContractId,
  reference_id,
  quote_text,
  context,
}: CreateCommentMutationVariables & {
  internalContractId: string;
  reference_id?: string;
}) => {
  const res = await axios.post<{ proposal_document_id: string }>(
    `/contracts/internal/${internalContractId}/comment/create`,
    {
      comment: {
        content,
      },
      thread_id,
      new_thread_id,
      new_comment_id,
      context,
      reference_id,
      quote_text,
    }
  );

  // @ts-ignore
  return res.data.thread_id;
};

export const editComment = async ({
  content,
  comment_id,
  internalContractId,
}: EditorCommentMutationVariables & {
  internalContractId: string;
}) => {
  await axios.post<{ proposal_document_id: string }>(`/contracts/internal/${internalContractId}/comment/edit`, {
    comment_id,
    comment: {
      content,
    },
  });
};

export const addReaction = async ({
  comment_id,
  emoji,
  internalContractId,
}: {
  comment_id: string;
  emoji: string;
  internalContractId: string;
}) => {
  const res = await axios.post(`/contracts/internal/${internalContractId}/comment/reaction`, {
    comment_id,
    reaction: emoji,
  });
  return res.data;
};

export const removeReaction = async ({
  comment_id,
  emoji,
  internalContractId,
}: {
  comment_id: string;
  emoji: string;
  internalContractId: string;
}) => {
  const res = await axios.delete(`/contracts/internal/${internalContractId}/comment/reaction`, {
    data: { comment_id, reaction: emoji },
  });
  return res.data;
};

export const resolveThread = async ({
  thread_id,
  internalContractId,
  resolved,
}: ResolveThreadMutationVariables & {
  internalContractId: string;
}) => {
  await axios.post(`/contracts/internal/${internalContractId}/thread/resolve`, {
    thread_id,
    resolved,
  });
};

export const deleteComment = async ({
  comment_id,
  internalContractId,
}: DeleteCommentMutationVariables & {
  internalContractId: string;
}) => {
  await axios.delete(`/contracts/internal/${internalContractId}/comment/delete`, {
    data: {
      comment_id,
    },
  });
};

export const deleteThread = async ({
  thread_id,
  internalContractId,
}: DeleteThreadMutationVariables & {
  internalContractId: string;
}) => {
  await axios.delete(`/contracts/internal/${internalContractId}/thread/delete`, {
    data: {
      thread_id,
    },
  });
};

export const restoreThread = async ({
  thread_id,
  internalContractId,
}: RestoreThreadMutationVariables & {
  internalContractId: string;
}) => {
  await axios.post(`/contracts/internal/${internalContractId}/thread/restore`, {
    thread_id,
  });
};

export const updateRead = async ({
  thread_id,
  read = true,
  internalContractId,
}: UpdateReadMutationVariables & {
  internalContractId: string;
}) => {
  await axios.post(`/contracts/internal/${internalContractId}/thread/updateRead`, {
    thread_id,
    read,
  });
};
