import { Modal } from "components/organisms/modal";
import { ComponentProps, useEffect, useState } from "react";
import { Subdirectory } from "types/FileStorage";
import { useMediaMutateOperations } from "../media/hooks";

interface Props extends Partial<ComponentProps<typeof Modal>> {
  currentDirectory?: Subdirectory | null;
  onFolderCreated: (folder: Subdirectory) => void;
}

const AddMediaFolderModal = ({ currentDirectory, onFolderCreated, ...props }: Props) => {
  const [name, setName] = useState("");
  const { createFolder } = useMediaMutateOperations();

  useEffect(() => {
    if (props.open) {
      setName("");
    }
  }, [props.open]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!name.length) return;
    const createdFolder = await createFolder({
      parent_directory_id: currentDirectory?.id,
      name,
    });

    if (createdFolder) {
      props.onOpenChange?.(false);
      onFolderCreated(createdFolder);
    }
  };

  return (
    <Modal
      contentProps={{ onClick: (e) => e.stopPropagation() }}
      header="New Folder"
      body={
        <form
          className="flex flex-col overflow-y-auto px-5"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <label className="mb-2 block text-sm text-[#5B6B79]">Name</label>
          <input
            type="text"
            className="w-full rounded-md py-2 px-3 h-10 border border-gray-light outline-none"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus
            required
          />
          <div className="w-full flex justify-end mt-8">
            <div className="flex gap-1">
              <button
                type="button"
                className="border border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
                onClick={() => {
                  props.onOpenChange?.(false);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="border-0 bg-gray-darkest rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2"
              >
                Create
              </button>
            </div>
          </div>
        </form>
      }
      {...props}
    />
  );
};

export default AddMediaFolderModal;
