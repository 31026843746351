/** @jsxImportSource @emotion/react */

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { HIGHLIGHT_BUFFER, REQUIREMENT_CLASSNAMES } from "./constants";
import { Page } from "react-pdf";
import { ScreenSpinner } from "utils/icons";
import { GroupedBlock, StepValue } from "../types";
import { SearchCheck, Split } from "lucide-react";
import { MouseEvent, memo, useCallback, useRef } from "react";
import { useAppSelector } from "store/storeTypes";
import { useMemo } from "react";
import tw from "twin.macro";
import useExtractionOperations from "hook/useExtractionOperations";
import { useSelection } from "./SelectionContext";
import { Requirement } from "types/Requirement";
import { useUnmergeRequirement } from "./hooks";
import SpinnerCircle from "utils/Spinner/SpinnerCircle";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { Section } from "components/copilot/CopilotSchemaTypes";
import { scrollToTemplateManager } from "../document-sidebar/template-manager/utils";

type Props = {
  page: number;
  scale: number;
  pageBlocks: GroupedBlock[];
  isReadOnly: boolean;
  dimensions: { width: number; height: number };
  onBlockSelected: (reqId: string) => void;
  sectionsMap: Record<string, ToImmutable<Section>>;
  keysOfSectionMap: string[];
  groupedFilteredRequirements: Record<string, Pick<Requirement, "id">>;
};

const PageWrapper = ({
  keysOfSectionMap,
  onBlockSelected,
  page,
  dimensions = { height: 0, width: 0 },
  scale,
  pageBlocks,
  isReadOnly,
  sectionsMap,
  groupedFilteredRequirements,
}: Props) => {
  return (
    <div className="relative">
      <Page
        className="mx-auto w-fit"
        pageNumber={page}
        renderAnnotationLayer={false}
        scale={scale}
        loading={
          <div className="absolute inset-0 flex items-center justify-center">
            <ScreenSpinner />
          </div>
        }
      >
        {pageBlocks?.map((block) => (
          <PageBlock
            sectionsMap={sectionsMap}
            isActiveInFilter={!!groupedFilteredRequirements[block.requirement.requirement.id]}
            pageBlocks={pageBlocks}
            dimensions={dimensions}
            onBlockSelected={onBlockSelected}
            isReadOnly={isReadOnly}
            block={block}
            scale={scale}
            keysOfSectionMap={keysOfSectionMap}
            key={block.id}
          />
        ))}
      </Page>
    </div>
  );
};

interface PageBlockProps extends Omit<Props, "page" | "groupedFilteredRequirements"> {
  block: GroupedBlock;
  isActiveInFilter: boolean;
}

const PageBlock = memo(
  ({
    block,
    keysOfSectionMap,
    dimensions,
    sectionsMap,
    scale,
    onBlockSelected,
    isReadOnly,
    isActiveInFilter,
  }: PageBlockProps) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const selection = useSelection();
    const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
    const { setExtractionRequirementSkipped } = useExtractionOperations();
    const { handleUnmergeRequirement, isLoading: isUnmerging } = useUnmergeRequirement();
    const isSelected = useMemo(
      () => selection.selectedBlocks?.some((selectedBlock) => selectedBlock.id === block.id),
      [block.id, selection.selectedBlocks]
    );
    const { requirement, bounds } = block;
    const existsInTemplate = keysOfSectionMap.includes(requirement.proposal_reference.section_id || "");

    const isRequirementsStep = extraction?.step === StepValue.Requirements;
    const isAssigned = existsInTemplate && !!requirement.proposal_reference.section_id;
    const isSkippedRequirement = !!requirement?.requirement?.skipped;
    const isSelectedRequirement = !requirement?.requirement?.skipped;
    const isSelectedAndHighlighted = !isReadOnly && !requirement?.requirement?.skipped;

    const requirementStateToClassName = isSkippedRequirement
      ? REQUIREMENT_CLASSNAMES.extracted
      : isAssigned
      ? REQUIREMENT_CLASSNAMES.assigned
      : isSelectedRequirement
      ? REQUIREMENT_CLASSNAMES.selected
      : "";
    const mouseLeaveHandler = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
        const allSimilarBlocks = document.querySelectorAll(`[data-element='${requirement.requirement.element_id}']`);

        const className = isAssigned
          ? ""
          : isSkippedRequirement
          ? "hovered-extracted-requirement"
          : isSelectedAndHighlighted
          ? "hovered-selected-requirement"
          : "";

        allSimilarBlocks?.forEach((node) => {
          className && node.classList.remove(className);
        });

        e.stopPropagation();
      },
      [isAssigned, isSkippedRequirement, isSelectedAndHighlighted, requirement.requirement.element_id]
    );

    if (!requirement || !bounds) return null;
    const { top_left: topLeft, top_right: topRight, bottom_right: bottomRight } = bounds;

    const top = topRight.Y * dimensions.height * scale - HIGHLIGHT_BUFFER;
    const left = topLeft.X * dimensions.width * scale - HIGHLIGHT_BUFFER;
    const bottom = bottomRight.Y * dimensions.height * scale + HIGHLIGHT_BUFFER;
    const right = topRight.X * dimensions.width * scale + HIGHLIGHT_BUFFER;
    const width = right - left;
    const height = bottom - top;

    return (
      <div
        ref={ref}
        data-element={requirement.requirement.element_id}
        className={`${requirementStateToClassName} !z-[2] outline-0 group absolute border border-gray-400 bg-[rgba(0,0,0,0.15)] ds-selectable ${
          isSelected ? "highlighted-dragged-selected-requirement" : ""
        }`}
        style={{
          top,
          left,
          width,
          height,
        }}
        onClick={() => {
          if (isAssigned) {
            scrollToTemplateManager(`template-manager-section-${requirement.proposal_reference.section_id}`);
            return;
          }

          if (extraction?.id && !isReadOnly && isRequirementsStep) {
            setExtractionRequirementSkipped(
              extraction.id,
              requirement.requirement.id,
              !requirement?.requirement?.skipped
            );

            if (requirement?.requirement?.skipped) onBlockSelected(requirement.requirement.id);
          }
        }}
        css={[
          !isReadOnly && extraction?.step === StepValue.Requirements && tw`cursor-pointer`,
          isSkippedRequirement && tw`outline-gray-500`, // Extracted Requirement (Grey)
          isSelectedRequirement && tw`border-[#2a46ab] outline-[#2a46ab] bg-[rgba(42, 70, 171, .25)]`, // Selected Requirement (Blue)
          //   isAssignStep &&
          isAssigned && tw`bg-[rgba(14, 120, 8, .25)] outline-[rgb(14, 120, 8)] border-[rgb(14, 120, 8)]`, // Assigned Requirement (Green)
        ]}
        onMouseMove={
          !isReadOnly
            ? () => {
                const allSimilarBlocks = document.querySelectorAll(
                  `[data-element='${requirement.requirement.element_id}']`
                );

                const className = isAssigned
                  ? ""
                  : isSkippedRequirement
                  ? "hovered-extracted-requirement"
                  : isSelectedAndHighlighted
                  ? "hovered-selected-requirement"
                  : "";
                allSimilarBlocks?.forEach((node) => {
                  className && node.classList.add(className);
                });
              }
            : undefined
        }
        onMouseLeave={!isReadOnly ? mouseLeaveHandler : undefined}
      >
        {!!requirement.proposal_reference.section_id && existsInTemplate && (
          <div
            title={sectionsMap[requirement.proposal_reference.section_id]?.title}
            className="cursor-pointer opacity-0 text-xxs select-none flex items-center gap-1.5 absolute p-0.5 shadow-sharp-thin rounded transition-opacity bg-gray-darkest text-gray-200 disable-drag-select group-hover:opacity-100 hover:bg-zinc-700"
            style={{
              top: 4,
              left: 4,
            }}
          >
            <div className="max-w-[200px] line-clamp-4 break-words px-1">
              {sectionsMap[requirement.proposal_reference.section_id]?.title || "No section title"}
            </div>
          </div>
        )}
        {!isReadOnly && isRequirementsStep && block.isMergedRequirement && (
          <div
            className="absolute pl-1.5 -top-0.5 left-full opacity-0 group-hover:opacity-100"
            css={[isUnmerging && tw`opacity-100`, isActiveInFilter && tw`right-[calc(100%+26px)]`]}
            onMouseMove={!isReadOnly ? mouseLeaveHandler : undefined}
          >
            <button
              onClick={(e) => {
                if (isUnmerging) return;
                handleUnmergeRequirement(requirement.requirement.id);
                e.stopPropagation();
              }}
              className="flex items-center gap-1.5 text-xs whitespace-nowrap py-1 px-2 bg-gray-darkest text-gray-200 shadow-sharp-thin rounded hover:bg-zinc-700 hover:text-white disabled:text-gray-300 disabled:cursor-not-allowed disabled:border-gray-500 disabled:bg-gray-400 disable-child-hover"
              disabled={isUnmerging}
            >
              {isUnmerging ? <SpinnerCircle className="h-3 w-3" /> : <Split size={12} />} Unmerge
            </button>
          </div>
        )}
        {isActiveInFilter && (
          <div className="absolute pointer-events-none -top-0.5 -left-0.5 -right-0.5 -bottom-0.5 border-2 border-black">
            <div
              className="px-1 flex rounded-l text-white items-center absolute -top-0.5 -bottom-0.5 right-full"
              style={{ backgroundImage: "linear-gradient(268deg, #0D0D0D 6.89%, #737373 95.08%)" }}
            >
              <SearchCheck size={14} />
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default memo(PageWrapper);
