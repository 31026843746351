import { ComponentProps, memo } from "react";
import MultiDatePicker from "react-multi-date-picker";

interface Props extends ComponentProps<typeof MultiDatePicker> {}

const DatePicker = ({ className, ...props }: Props) => {
  return (
    <MultiDatePicker
      arrow={false}
      shadow={false}
      offsetY={4}
      className={`shadow border border-zinc-200 ${className}`}
      {...props}
    />
  );
};

export default memo(DatePicker);
