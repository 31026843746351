/** @jsxImportSource @emotion/react */

import { Modal } from "../modal";
import { useAppDispatch } from "store/storeTypes";
import { useCallback, useEffect, useMemo, useState } from "react";
import tw from "twin.macro";
import { Minus, Plus, X, ZoomIn } from "lucide-react";
import { ScreenSpinner } from "utils/icons";
import { Document, Page, pdfjs } from "react-pdf";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { WorkspaceFile } from "types/FileStorage";
import { getFile } from "api/api";
import { toggleDocumentViewerModal } from "store/reducers/modalsSlice";
import { decrementScale, incrementScale } from "utils/scale";

const DEFAULT_SCALE = 1.75;

type DocumentViewerModalInitialProps = {
  url: string;
  id: string;
};
export type DocumentViewerModalProps = {
  open: boolean;
  initialProps?: Partial<DocumentViewerModalInitialProps>;
};

const DocumentViewerModal = ({ open, initialProps }: DocumentViewerModalProps) => {
  const { url = "", id } = initialProps || {};
  const dispatch = useAppDispatch();
  const [scale, setScale] = useState(DEFAULT_SCALE);
  const [numPages, setNumPages] = useState(0);
  const [foundFileUrl, setFoundFileUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!!url || !id || !open) return;
    setIsLoading(true);
    getFile(id)
      .then((res) => {
        const file: WorkspaceFile = res?.data;
        setFoundFileUrl(file.download_url);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));

    return () => {
      setFoundFileUrl("");
    };
  }, [id, open, url]);

  const pageList = useMemo(() => Array.from({ length: numPages }, (_, i) => i + 1), [numPages]);

  useEffect(() => {
    if (open) {
      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      setScale(DEFAULT_SCALE);
    }
  }, [open]);

  const onDocumentLoadSuccess = useCallback(async (pdf: DocumentCallback) => {
    setNumPages(pdf.numPages);
  }, []);

  const onDocumentLoadError = useCallback((error: Error) => {
    console.log("Load error", error);
  }, []);

  const file = useMemo(() => ({ url: url || foundFileUrl }), [foundFileUrl, url]);

  return (
    <Modal
      hideClose
      contentProps={{
        css: tw`p-0 !h-[calc(100vh-40px)] !w-[calc(100vw-40px)] !min-w-[50%] overflow-hidden rounded-md !max-h-[1200px] !min-h-[90%] !max-w-[1400px]`,
      }}
      bodyProps={tw`m-0`}
      open={open}
      onOpenChange={(openState) => {
        dispatch(toggleDocumentViewerModal({ open: openState }));
      }}
      body={
        <div className="relative h-full">
          <button
            onClick={(e) => {
              dispatch(toggleDocumentViewerModal({ open: false }));
            }}
            className="z-[3] select-none flex items-center gap-2 absolute p-1.5 top-2 left-2 shadow-sharp-thin rounded duration-100 bg-gray-300 text-slate-900 hover:brightness-95 font-medium"
          >
            <X size={18} />
          </button>
          <div className="absolute z-[3] top-2 right-2 flex flex-row items-center gap-2">
            <div className="shadow backdrop-blur-lg rounded-md p-1.5 pl-3 flex gap-4 flex-row items-center bg-gray-300/60">
              <div className="text-xs flex items-center gap-2">
                <ZoomIn size={16} className="stroke-[0.5]" />
                <div className="text-xs font-semibold">{Math.trunc(scale * 100)}%</div>
              </div>
              <div className="flex items-center gap-1">
                <button
                  className="w-7 h-7 flex justify-center items-center rounded-md bg-black/[0.12] hover:bg-black/[0.2] active:bg-black/[0.24]"
                  onClick={() => setScale(incrementScale)}
                >
                  <Plus size={14} />
                </button>
                <button
                  className="w-7 h-7 flex justify-center items-center rounded-md bg-black/[0.12] hover:bg-black/[0.2] active:bg-black/[0.24]"
                  onClick={() => setScale(decrementScale)}
                >
                  <Minus size={14} />
                </button>
              </div>
            </div>
          </div>
          <div className="overflow-y-auto h-full">
            <div className="px-8 pt-14 w-full">
              {!!file?.url && !isLoading ? (
                <Document
                  renderMode="canvas"
                  file={file}
                  onLoadError={onDocumentLoadError}
                  onLoadSuccess={onDocumentLoadSuccess}
                  className="w-full h-full"
                  loading={
                    <div className="absolute inset-0 top-14 flex items-center justify-center">
                      <ScreenSpinner />
                    </div>
                  }
                >
                  {window === undefined ? (
                    <div />
                  ) : (
                    pageList.map((page) => {
                      return (
                        <Page
                          className="mx-auto w-fit"
                          pageNumber={page}
                          renderAnnotationLayer={false}
                          scale={scale}
                          loading={
                            <div className="absolute inset-0 flex items-center justify-center">
                              <ScreenSpinner />
                            </div>
                          }
                        />
                      );
                    })
                  )}
                </Document>
              ) : (
                <div className="flex items-center justify-center h-full absolute inset-0 top-[28px]">
                  <ScreenSpinner />
                </div>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default DocumentViewerModal;
