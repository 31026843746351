import { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { ProposalReviewStatus } from "components/yjs-editor/components/text-editor/constants";
import { useComments } from "api/comments/useComments";
import { useAppSelector } from "store/storeTypes";
import { addDocIdToSuccessfulReviews, removeDocIdFromReviews } from "store/reducers/proposal/proposalReducer";
import { ExtractionTask } from "types/Autopilot/HealthCheck";
import { toggleComments } from "store/reducers/copilot/copilotDrawerReducer";

export const useReviewEngine = (internalContractId: string, referenceId: string) => {
  const dispatch = useDispatch();
  const proposalReviewStatus = useRef(ProposalReviewStatus.Idle);
  const proposal_review_tasks = useAppSelector((state) => state.autopilotHealthCheck.proposal_review_tasks);
  const { proposalsWithSuccessfulReviews, reviewsInProgress } = useAppSelector((state) => state.proposal);
  const { refetch } = useComments(internalContractId, referenceId);

  const proposalHasSuccessfulReview = useMemo(() => {
    if (!referenceId) return false;
    return proposalsWithSuccessfulReviews.includes(referenceId);
  }, [proposalsWithSuccessfulReviews, referenceId]);

  const proposalReviewTaskInProgress = useMemo(() => {
    if (!referenceId) return null;

    const taskInProgress = proposal_review_tasks?.find((task) => task.reference_id === referenceId && !task.failed);

    if (!taskInProgress && reviewsInProgress.includes(referenceId)) {
      return {
        extraction_id: null,
        reference_id: referenceId,
        is_started: true,
        failed: false,
        minutes_time_remaining: undefined,
      };
    }

    return taskInProgress || null;
  }, [proposal_review_tasks, referenceId, reviewsInProgress]);

  useEffect(() => {
    const refetchComments = async () => {
      const response = await refetch();

      if (response.isSuccess) {
        const hasVultronComments = response.data?.some((thread) =>
          thread.comments.some((comment) => comment.commenter.is_vultron)
        );
        if (!hasVultronComments && referenceId) {
          dispatch(addDocIdToSuccessfulReviews(referenceId));
        } else {
          dispatch(toggleComments(true));
        }
        proposalReviewStatus.current = ProposalReviewStatus.Idle;
      }
    };

    if (!proposalReviewTaskInProgress && proposalReviewStatus.current === ProposalReviewStatus.InProgress) {
      refetchComments();
    } else if (proposalReviewTaskInProgress) {
      proposalReviewStatus.current = ProposalReviewStatus.InProgress;
    }
  }, [proposalReviewTaskInProgress, referenceId, dispatch]);

  useEffect(() => {
    const removeReviewId = (id: string) => {
      dispatch(removeDocIdFromReviews(id));
    };

    if (proposal_review_tasks.length && reviewsInProgress.length) {
      for (const id of reviewsInProgress) {
        const relatedTasks = proposal_review_tasks.filter((task: ExtractionTask) => task.reference_id === id);

        const taskInProgress = relatedTasks.some((task: ExtractionTask) => task.is_started);

        if (taskInProgress) {
          removeReviewId(id);
        } else {
          setTimeout(() => {
            removeReviewId(id);
          }, 8000);
        }
      }
    }
  }, [dispatch, proposal_review_tasks, referenceId, reviewsInProgress]);

  return {
    proposalHasSuccessfulReview,
    proposalReviewTaskInProgress,
  };
};
