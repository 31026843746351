import { isEqual } from "lodash";
import { toolbarSections } from "../toolbar-sections/constants";
import { useProxyRef } from "hook/useProxyRef";
import { useState, useEffect } from "react";

export const useToolbarOverflow = (
  containerRef: React.RefObject<HTMLElement>,
  itemsRef: React.RefObject<Record<string, HTMLElement | null>>
) => {
  const [overflowStates, setOverflowStates] = useState<{ [key: string]: boolean }>({});
  const prevOverflowRef = useProxyRef(overflowStates);

  useEffect(() => {
    const updateWidthAndOverflow = () => {
      const newOverflowStates: { [key: string]: boolean } = {};
      const items = itemsRef.current;
      if (!items || !containerRef.current) return;

      const overflowButtonWidth = items["overflowButton"]?.clientWidth || 0;
      const barWidth = containerRef.current?.clientWidth + overflowButtonWidth;
      // If the bar is not visible, we can't calculate overflow
      if (barWidth <= 0) return;

      const lastVisibleSectionIndex = toolbarSections.findIndex(({ key }) => prevOverflowRef.current[key]) - 1;

      // Check each toolbar component if it is overflowing
      toolbarSections.forEach(({ key }, i) => {
        if (key === "overflowButton") return; // Ignore overflow button
        const toolbarSection = items[key];
        const isLast = i === lastVisibleSectionIndex;
        if (toolbarSection) {
          // If this is the last visible section, we need to account for the overflow button
          const overflowButtonPadding = isLast ? overflowButtonWidth : 0;
          const xEnd = toolbarSection.offsetLeft + toolbarSection.clientWidth + overflowButtonPadding;
          newOverflowStates[key] = xEnd > barWidth;
        }
      });

      // If overflow states have changed, update state
      setOverflowStates((prev) => {
        if (!isEqual(prev, newOverflowStates)) {
          return newOverflowStates;
        }
        return prev;
      });
    };

    const resizeObserver = new ResizeObserver(updateWidthAndOverflow);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [itemsRef, containerRef.current]);

  return overflowStates;
};
