import { Editor } from "@tiptap/react";
import ToolbarTable from "../toolbar-table/ToolbarTable";
import { ToolbarAlignment } from "../ToolbarAlignment";
import { ToolbarBlock } from "../ToolbarBlock";
import { ToolbarCommands } from "../ToolbarCommands";
import { ToolbarHeadings } from "../ToolbarHeadings";
import { ToolbarInline } from "../ToolbarInline";
import { ToolbarMedia } from "../ToolbarMedia";
import { ToolbarSection } from "./types";

const ToolbarTableWithMedia = ({ editor }: { editor: Editor }) => {
    return (
        <>
            <ToolbarTable editor={editor} />
            <ToolbarMedia editor={editor} />
        </>
    );
};

// Array of toolbar sections with their respective components
// We then use the useToolbarOverflow hook to determine if a section is overflowing
// If it is overflowing we will hide it and display it in a dropdown
export const toolbarSections: ToolbarSection[] = [
    { key: "commands", component: ToolbarCommands },
    { key: "headings", component: ToolbarHeadings },
    { key: "inline", component: ToolbarInline },
    { key: "alignment", component: ToolbarAlignment },
    { key: "block", component: ToolbarBlock },
    { key: "media", component: ToolbarTableWithMedia },
];
