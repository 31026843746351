/** @jsxImportSource @emotion/react */

import TemplateManager from "./template-manager";

const DocumentSidebar = () => {
  return (
    <div className="flex flex-col h-full">
      <TemplateManager />
    </div>
  );
};

export default DocumentSidebar;
