import MobileScreen from "./components/MobileScreen";
import AppRouter from "./config/AppRouter";
import ScrollTop from "./utils/ScrollTop";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { useAccount } from "hook/useAccount";
import NotificationsProvider from "context/notificationContext";
import { MountPoint } from "components/organisms/confirm-modal/utils";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./api/queryClient";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const App = () => {
  useAccount();

  return (
    <NotificationsProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <TooltipProvider disableHoverableContent>
          <div className="hidden xl:block">
            <AppRouter />
          </div>
          <MobileScreen className="xl:hidden" />
          <ScrollTop />
          <MountPoint />
        </TooltipProvider>
      </QueryClientProvider>
    </NotificationsProvider>
  );
};

export default App;
