import { STATIC_FEATURE_FLAG_MAP } from "const-values/StaticFeatureFlagMap";
import { useFlags as useLDFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlagSet } from "types/FeatureFlagSet";

export function useFlags(): FeatureFlagSet {
  const isLaunchDarklyEnabled = process.env.REACT_APP_USE_LAUNCHDARKLY === "true";
  const flags = useLDFlags<FeatureFlagSet>();
  if (isLaunchDarklyEnabled) return flags;

  return STATIC_FEATURE_FLAG_MAP;
}
