import { usePublishVolume } from "hook/draft/usePublishVolume";
import usePersistedStorage from "hook/persisted-storage/usePersistedStorage";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  DisplayOption,
  PreviewConfig,
  SectionFormat,
  resetPreviewProposalState,
} from "store/reducers/draft/previewProposalReducer";
import { togglePreviewProposalModal } from "store/reducers/modalsSlice";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { CopilotPresencePage } from "types/Presence";
import snakecaseKeys from "snakecase-keys";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";

const FALLBACK_CONFIG = { includeSourceDocuments: false, sectionFormats: [] };

export const usePreviewProposalConfig = () => {
  const activeDraftId = useAppSelector((store) => store.previewProposalState.activeDraftId);
  const [previewProposalConfig, setPreviewProposalConfig] = usePersistedStorage<PreviewConfig>(
    `previewProposalConfig-${activeDraftId}`
  );

  const toggleIncludeSourceDocuments = useCallback(
    (includeSourceDocuments: boolean) => {
      if (previewProposalConfig) setPreviewProposalConfig({ ...previewProposalConfig, includeSourceDocuments });
    },
    [previewProposalConfig, setPreviewProposalConfig]
  );

  const updateSectionFormat = useCallback(
    (properties: Partial<SectionFormat> & { id: string }) => {
      const { id, ...payload } = properties;
      const newConfig = { ...((previewProposalConfig as PreviewConfig) || FALLBACK_CONFIG) };

      const foundFormatIdx = newConfig.sectionFormats.findIndex((sectionFormat) => sectionFormat.id === id);

      if (foundFormatIdx === -1) {
        newConfig.sectionFormats.push({
          id,
          displayOption: DisplayOption.FullRequirement,
          headingStyle: {
            bold: true,
            underline: false,
            italic: false,
          },
          ...payload,
        });
        return;
      }

      const foundFormat = newConfig.sectionFormats[foundFormatIdx];
      const newFormat = { ...foundFormat, ...payload };
      newConfig.sectionFormats[foundFormatIdx] = newFormat;

      setPreviewProposalConfig(newConfig);
    },
    [previewProposalConfig, setPreviewProposalConfig]
  );

  const updateSectionAndSubsectionFormat = useCallback(
    (sectionId: string, section: FormattedSection, format: Partial<SectionFormat>) => {
      const configFormat = previewProposalConfig?.sectionFormats?.find(
        (sectionFormat) => sectionFormat.id === sectionId
      );

      if (!configFormat) return;

      updateSectionFormat({ id: sectionId, ...format });

      if ((section as FormattedSection).subsections) {
        ((section as FormattedSection).subsections as FormattedSection[]).forEach((subsection) => {
          updateSectionFormat({ id: subsection.id, ...format });
        });
      }
    },
    [previewProposalConfig, updateSectionFormat]
  );

  return {
    updateSectionFormat,
    updateSectionAndSubsectionFormat,
    previewProposalConfig,
    toggleIncludeSourceDocuments,
  };
};

export const usePublishTrigger = () => {
  const dispatch = useAppDispatch();
  const { publishVolume, isPublishing } = usePublishVolume();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const activeDraftId = useAppSelector((store) => store.previewProposalState.activeDraftId);
  const [previewProposalConfig] = usePersistedStorage<PreviewConfig>(`previewProposalConfig-${activeDraftId}`);

  const handlePublish = useCallback(async () => {
    if (activeDraftId && !isPublishing && previewProposalConfig?.sectionFormats) {
      const docId = await publishVolume({
        volume_id: activeDraftId,
        section_formats: snakecaseKeys(previewProposalConfig.sectionFormats, { deep: true }),
        include_source_documents: previewProposalConfig?.includeSourceDocuments || false,
      });
      if (!docId) return;

      dispatch(togglePreviewProposalModal({ open: false }));
      dispatch(resetPreviewProposalState());
      searchParams.set("tab", CopilotPresencePage.Proposal);
      navigate(`/dashboard/contracts/details?${searchParams.toString()}`, { state: { docId } });
    }
  }, [
    activeDraftId,
    isPublishing,
    previewProposalConfig?.sectionFormats,
    previewProposalConfig?.includeSourceDocuments,
    publishVolume,
    dispatch,
    searchParams,
    navigate,
  ]);

  return { handlePublish, isPublishing };
};

export const useDetectPathChange = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(togglePreviewProposalModal({ open: false }));
    dispatch(resetPreviewProposalState());
  }, [dispatch, location]);
};
