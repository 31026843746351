/** @jsxImportSource @emotion/react */

import { ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { EllipsisVertical, LucidePlus } from "lucide-react";
import Icon from "components/atoms/icons/Icon";
import tw, { theme } from "twin.macro";
import useExtractionOperations from "hook/useExtractionOperations";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { DndContext, DragOverlay, DraggableAttributes } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { setEditableTemplateRowState } from "store/reducers/extract/CurrentExtractionReducer";
import { StepValue } from "../../../types";
import { HTMLAttributes, forwardRef, memo, useMemo, useState } from "react";
import { useSectionDropdownItems, useTemplateRowOperations } from "../hooks";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useDrag } from "./hooks";
import { verticalSortableListCollisionDetection } from "pages/draft-volume/draft-volume-sidebar/utils";
import SortableItem from "../subsection-row/SortableItem";
import SubsectionRow from "../subsection-row";
import { isInstantDraftStarted } from "../../../utils";
import { scrollToTemplateManager } from "../utils";
import Tooltip from "components/atoms/tooltip";

interface Props extends HTMLAttributes<HTMLDivElement> {
  volumeId: string;
  section: FormattedSection;
  withOpacity?: boolean;
  isDragging?: boolean;
  dragProps?: { attributes?: DraggableAttributes; listeners?: SyntheticListenerMap };
}

const SectionRow = forwardRef<HTMLDivElement, Props>(
  ({ dragProps, isDragging, withOpacity, style, volumeId, section }, ref) => {
    const [portalRef, setPortalRef] = useState<HTMLDivElement | null>(null);
    const [titleRef, setTitleRef] = useState<HTMLSpanElement | null>(null);
    const [titleContainerRef, setTitleContainerRef] = useState<HTMLDivElement | null>(null);
    const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
    const { setSectionName } = useExtractionOperations();
    const { handleAddSection, handleAddSubsection } = useTemplateRowOperations(extraction?.id);
    const dispatch = useAppDispatch();
    const editableTemplateRowState = useAppSelector((store) => store.currentExtractionState.editableTemplateRowState);
    const isReadOnly =
      extraction?.status === ExtractionStatus.Completed ||
      isInstantDraftStarted(extraction?.instantDraftConfig?.status);
    const isImportStep = extraction?.step === StepValue.Review;
    const { items } = useSectionDropdownItems(section, volumeId, extraction?.id);
    const isEditing = editableTemplateRowState.id === section.id;
    const { sensors, handleDragEnd, dragSections, handleDragStart, handleDragCancel, activeDragId } = useDrag(
      section.subsections || [],
      volumeId,
      extraction?.id
    );

    const activeDragSection = useMemo(
      () => section.subsections?.find((subsection) => subsection.id === activeDragId),
      [activeDragId, section.subsections]
    );

    const shouldShowTootlipTitle = useMemo(
      () =>
        !isEditing &&
        !isDragging &&
        !!section.title &&
        (titleRef?.clientWidth || 0) >= (titleContainerRef?.clientWidth || 0),
      [isDragging, isEditing, titleContainerRef?.clientWidth, titleRef?.clientWidth, section.title]
    );

    return (
      <div
        ref={ref}
        className="ml-[8px] rounded-md"
        css={[
          isDragging && tw`bg-white`,
          isDragging && isImportStep && tw`bg-layout-gray-light`,
          isDragging && tw`!m-0`,
          {
            boxShadow: isDragging ? theme`boxShadow.expanded` : "none",
            margin: isDragging ? theme`boxShadow.expanded` : "none",
            zIndex: isDragging ? "4" : "auto",
            opacity: withOpacity ? "0.3" : "1",
            pointerEvents: isEditing ? "none" : "auto",
            ...style,
          },
          isReadOnly && tw`ml-[22px]`,
        ]}
        {...dragProps?.attributes}
      >
        <div>
          <div>
            <div ref={setPortalRef} className="flex flex-col gap-1.5 select-none cursor-pointer">
              <div
                className="cursor-default overflow-hidden relative group text-gray-darkest rounded-md"
                css={[
                  !isReadOnly && tw`cursor-pointer`,
                  isReadOnly && tw`overflow-visible`,
                  //  !isEditing && !isReadOnly && tw`hover:bg-gray-200`
                ]}
              >
                <div className="flex flex-row pr-4 pl-2 gap-0.5 items-center">
                  {!isReadOnly && (
                    <div className="flex flex-row items-center opacity-0 group-hover:opacity-100">
                      <DropdownMenu
                        portalProps={{ container: portalRef }}
                        items={[
                          {
                            key: 1,
                            label: "Section",
                            onSelect: () => {
                              const idx = extraction?.framework?.volumes
                                ?.find((volume) => volume.id === volumeId)
                                ?.sections?.findIndex(({ id }) => id === section.id);
                              handleAddSection(volumeId, typeof idx === "number" ? idx + 1 : undefined);
                            },
                          },
                          {
                            key: 2,
                            label: "Subsection",
                            onSelect: () => {
                              handleAddSubsection(volumeId, section.id);
                            },
                          },
                        ]}
                      >
                        <div className="z-[1] bg-transparent rounded p-0.5 text-slate-500 hover:text-slate-900 hover:bg-gray-200">
                          <LucidePlus size={12} />
                        </div>
                      </DropdownMenu>
                      <div
                        className="z-[1] bg-transparent rounded p-0.5 text-slate-500 hover:text-slate-900 hover:bg-gray-200"
                        css={[{ cursor: isDragging ? "grabbing" : "grab" }, isDragging && tw`opacity-100`]}
                        {...dragProps?.listeners}
                      >
                        <Icon name="Draggable" className="w-3 h-3" />
                      </div>
                    </div>
                  )}
                  <Tooltip
                    contentProps={{ align: "start", side: "left", sideOffset: 34 }}
                    content={section.title}
                    disableHoverableContent
                    disabled={!shouldShowTootlipTitle}
                    delayDuration={800}
                  >
                    <div
                      className="relative text-xs flex flex-row gap-1.5 items-center w-[calc(100%-18px)]"
                      css={[isReadOnly && tw`w-[calc(100%+20px)]`]}
                      onClick={() => {
                        scrollToTemplateManager("template-manager-section-" + section.id);
                      }}
                    >
                      <div
                        className="absolute -left-3 inset-y-0 w-0.5 bg-gray-200"
                        css={[isReadOnly && tw`opacity-100`, !isReadOnly && tw`group-hover:opacity-0`]}
                      />
                      {isEditing ? (
                        <>
                          <input
                            placeholder="Section title..."
                            autoFocus
                            onChange={(e) => {
                              dispatch(setEditableTemplateRowState({ localValue: e.target.value }));
                            }}
                            onKeyDown={(e) => {
                              if (e.code === "Enter") {
                                if (extraction?.id) {
                                  setSectionName(
                                    extraction.id,
                                    volumeId,
                                    section.id,
                                    editableTemplateRowState.localValue
                                  );
                                }
                                setTimeout(
                                  () =>
                                    dispatch(
                                      setEditableTemplateRowState({
                                        id: "",
                                        localValue: "",
                                      })
                                    ),
                                  100
                                );
                              }
                            }}
                            onBlur={() => {
                              if (extraction?.id) {
                                setSectionName(
                                  extraction.id,
                                  volumeId,
                                  section.id,
                                  editableTemplateRowState.localValue
                                );
                              }
                              setTimeout(
                                () =>
                                  dispatch(
                                    setEditableTemplateRowState({
                                      id: "",
                                      localValue: "",
                                    })
                                  ),
                                100
                              );
                            }}
                            value={editableTemplateRowState.localValue}
                            className="text-[13px] font-medium py-2 outline-none text-gray-darkest w-full bg-transparent"
                          />
                        </>
                      ) : (
                        <div className="text-[13px] py-2 w-full min-w-0">
                          <div ref={setTitleContainerRef} className="flex flex-row items-center">
                            <span
                              ref={setTitleRef}
                              className="whitespace-nowrap font-medium"
                              css={[!section.title.trim() && tw`text-gray-400`]}
                            >
                              {section.title || "Section title..."}
                            </span>
                          </div>
                          {!isEditing && (
                            <div
                              className="absolute bottom-0 right-0 top-0 to-transparent bg-gradient-to-l w-14 from-white"
                              css={[!isReadOnly && tw`group-hover:w-16 group-hover:from-50% group-hover:from-white`]}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </Tooltip>
                </div>
                {!isReadOnly && (
                  <div
                    className="opacity-0 text-slate-700 text-sm flex items-center gap-1.5 absolute bottom-0 right-0 top-0 pl-2 pr-2 bg-white group-hover:opacity-100"
                    css={[isEditing && tw`hidden`]}
                  >
                    <DropdownMenu
                      portalProps={{ container: portalRef }}
                      contentProps={{ align: "end", css: tw`min-w-[120px]` }}
                      items={items}
                    >
                      <div className="">
                        <EllipsisVertical size={14} />
                      </div>
                    </DropdownMenu>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <DndContext
              sensors={sensors}
              collisionDetection={verticalSortableListCollisionDetection}
              onDragEnd={(event) => handleDragEnd(event)}
              onDragStart={handleDragStart}
              onDragCancel={handleDragCancel}
            >
              <SortableContext
                id="EXTRACTION_TEMPLATE_SUBSECTIONS"
                // @ts-ignore
                items={dragSections || []}
                strategy={verticalListSortingStrategy}
              >
                {dragSections?.map((subsection) => (
                  <SortableItem key={subsection.id} volumeId={volumeId} section={subsection} />
                ))}
                <DragOverlay style={{ transformOrigin: "0 0 " }}>
                  {!!activeDragId && activeDragSection && (
                    <SubsectionRow volumeId={volumeId} section={activeDragSection} isDragging />
                  )}
                </DragOverlay>
              </SortableContext>
            </DndContext>
          </div>
        </div>
      </div>
    );
  }
);

export default memo(SectionRow);
