import { useCallback, useMemo, useState } from "react";

type SortConfig = {
  key: string;
  direction: "ascending" | "descending" | "";
  by_date: boolean;
};

export const useSortable = <T extends { [key: string]: any }>(
  items: T[] = []
): {
  items: T[];
  sortConfig: SortConfig;
  handleSorting: (key: string, direction: SortConfig["direction"], by_date: boolean) => void;
} => {
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: "", direction: "", by_date: false });

  const sortedItems = useMemo(() => {
    if (!items.length) return items;

    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const valueTypeA = typeof a[sortConfig.key];
        const valueTypeB = typeof b[sortConfig.key];
        if (sortConfig.direction === "ascending" && sortConfig.by_date) {
          return new Date(a[sortConfig.key]).valueOf() - new Date(b[sortConfig.key]).valueOf();
        }
        if (sortConfig.direction === "descending" && sortConfig.by_date) {
          return new Date(b[sortConfig.key]).valueOf() - new Date(a[sortConfig.key]).valueOf();
        }

        if (valueTypeA === "string" && valueTypeB === "string") {
          if (sortConfig.direction === "ascending")
            return a[sortConfig.key]?.toLowerCase().localeCompare(b[sortConfig.key]?.toLowerCase());

          if (sortConfig.direction === "descending")
            return b[sortConfig.key]?.toLowerCase().localeCompare(a[sortConfig.key]?.toLowerCase());
        }

        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const handleSorting = useCallback((key: string, direction: SortConfig["direction"], by_date: boolean) => {
    return setSortConfig({ key, direction: direction, by_date: by_date || false });
  }, []);

  return {
    items: sortedItems,
    sortConfig,
    handleSorting,
  };
};
