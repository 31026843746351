import { Volume } from "components/copilot/CopilotSchemaImmutableTypes";
import { Section } from "components/copilot/CopilotSchemaTypes";
import { PropsWithChildren, useMemo } from "react";
import { DropdownMenu, DropdownMenuProps } from "../dropdown-menu";
import { getAllSections } from "components/copilot/ComplianceMatrix/utils";
import { ToImmutable } from "YJSProvider/LiveObjects";

interface Props extends Omit<DropdownMenuProps<Section>, "items" | "multiselect"> {
    volumes?: Volume[];
    includeEmpty?: boolean;
    activeVolumeId?: string;
    onSelect: (sec: ToImmutable<Section> | null) => void;
}

export const SectionsDropdown = ({
    children,
    activeVolumeId,
    includeEmpty,
    onSelect,
    volumes,
    ...props
}: PropsWithChildren<Props>) => {
    const sections = useMemo(() => getAllSections(volumes, activeVolumeId, true), [activeVolumeId, volumes]);

    const items = useMemo(
        () => [
            ...(includeEmpty ? [{ key: 0, label: "-", value: null, onSelect: () => onSelect(null) }] : []),
            ...(sections
                ?.filter(({ title }) => !!title)
                ?.map((sec) => ({
                    key: sec.id,
                    label: sec.title,
                    value: sec.id,
                    onSelect: () => onSelect(sec),
                })) || []),
        ],
        [includeEmpty, onSelect, sections]
    );

    return (
        <DropdownMenu items={items} {...props}>
            {children}
        </DropdownMenu>
    );
};
