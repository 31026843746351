import { Editor } from "@tiptap/react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenMediaModal } from "store/reducers/yjs-editor-reducer";
import { ImageIcon } from "../icons/Image";
import { Button } from "../primitives/Button";
import InsertImageModal from "./ContentLibrary/modals/InsertImageModal";
import styles from "./Toolbar.module.css";

type Props = {
  editor: Editor;
};

export function ToolbarMedia({ editor }: Props) {
  const dispatch = useDispatch();
  const { openMediaModal } = useSelector((state: any) => state.yjsEditor);

  return (
    <>
      <Button
        className={styles.toolbarButton}
        variant="subtle"
        onClick={() => dispatch(setOpenMediaModal(true))}
        aria-label="Image"
      >
        <ImageIcon className="text-gray-500" />
      </Button>
      <InsertImageModal editor={editor} open={openMediaModal} onOpenChange={() => dispatch(setOpenMediaModal(false))} />
    </>
  );
}
