import { toggleCreateProjectModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";
import Icon from "components/atoms/icons/Icon";
import { useFilterOptions } from "./hooks";
import { DropdownMenu } from "components/molecules/dropdown-menu";

// =================== Contract management search component =======================
const ContractPageSearchBar = ({ selectedFilters, setSelectedFilters }) => {
  const dispatch = useAppDispatch();
  const { statusItems, deadlineItems } = useFilterOptions(selectedFilters, setSelectedFilters);
  const selectedStatuses = selectedFilters?.status || [];
  let deadlineDate = selectedFilters?.response_deadline || [];

  return (
    <>
      <div className="py-2.5 px-1.5 flex border-b justify-between border-[#ebeced] items-center gap-8 shadow-sm bg-white pl-6">
        <div className="flex w-full items-center justify-between gap-1">
          <div className="flex w-max items-center py-3 px-3">
            <h4 className="font-semibold text-base">Projects</h4>
          </div>
          <div className="min-[1550px]:max-w-[874px] max-w-[820px] ml-auto flex flex-col gap-1 items-end">
            <div className="flex items-center gap-2 justify-start flex-auto flex-wrap px-4">
              <DropdownMenu multiselect searchable items={statusItems}>
                <div
                  title={selectedStatuses.join(", ")}
                  className="rounded-md max-w-[150px] text-sm min-h-[26px] min-w-10 bg-white flex justify-between px-2 py-1.5 items-center gap-2 border border-gray-light text-gray-600"
                >
                  <div className="truncate">{selectedStatuses.length ? selectedStatuses.join(", ") : "Status"}</div>
                  <Icon name="CarrotDown" className="min-w-3" />
                </div>
              </DropdownMenu>
              <DropdownMenu items={deadlineItems}>
                <div
                  title={deadlineDate}
                  className="rounded-md max-w-[150px] text-sm min-h-[26px] min-w-10 bg-white flex justify-between px-2 py-1.5 items-center gap-2 border border-gray-light text-gray-600"
                >
                  <div className="truncate">{deadlineDate.length ? deadlineDate : "Deadline"}</div>
                  <Icon name="CarrotDown" className="min-w-3" />
                </div>
              </DropdownMenu>

              <button
                className="text-gray-darkest flex flex-grow items-center hover:border-gray-300 border border-gray-200 bg-gray-100 cursor-pointer font-medium text-sm px-2 py-1.5 rounded gap-2"
                onClick={() => dispatch(toggleCreateProjectModal({ open: true }))}
              >
                <Icon name="Plus" className="w-4 h-4" />
                <span>New Project</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractPageSearchBar;
