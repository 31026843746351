import uniqBy from "lodash/uniqBy";
import isEqual from "lodash/isEqual";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GroupedBlock, MergedRequirement } from "../types";
import { Extraction, ExtractionFramework, InstantDraftStatus } from "components/copilot/CopilotSchemaTypes";
import { SelectionEvent } from "@viselect/vanilla";
import { LiveList, LiveObject, ToImmutable } from "YJSProvider/LiveObjects";
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import { useNotification } from "context/notificationContext";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import {
  getFilteredRequirements,
  getRequirementGroups,
  getTemplateRequirements,
} from "store/reducers/extract/CurrentExtractionReducer";
import useExtractionOperations from "hook/useExtractionOperations";
import { createComplianceMatrixRowRequirement } from "utils/complianceMatrix";
import { useSelection } from "./SelectionContext";
import { getSortedRequirementsBySectionOrder } from "utils/extraction";

export const useDragSelectOperation = (allFilteredBlocks: GroupedBlock[], extraction?: ToImmutable<Extraction>) => {
  const { setSelectedBlocks, clearSelection } = useSelection();
  const selectionRef = useRef<SelectionEvent["selection"]>();

  useEffect(() => {
    clearSelection?.();
  }, [clearSelection, extraction?.step]);

  const onMove = useCallback(
    ({
      store: {
        changed: { added, removed },
      },
    }: SelectionEvent) => {
      added.forEach((node) => {
        if (node instanceof HTMLElement) {
          document
            .querySelectorAll(`[data-element='${node.dataset.element}']`)
            .forEach((node) => node.classList.add("highlighted-dragged-selected-requirement"));
        }
      });
      removed.forEach((node) => {
        if (node instanceof HTMLElement) {
          document
            .querySelectorAll(`[data-element='${node.dataset.element}']`)
            .forEach((node) => node.classList.remove("highlighted-dragged-selected-requirement"));
        }
      });
    },
    []
  );

  const onStop = useCallback(
    ({ event, selection, store: { stored } }: SelectionEvent) => {
      if (!event) return;
      document.body.style.userSelect = "unset";
      selectionRef.current = selection;
      const dragSelectedBlocks = uniqBy(allFilteredBlocks, "requirement.requirement.element_id").filter((block) =>
        Array.from(stored).some((item) => {
          if (item instanceof HTMLElement) {
            return item.dataset.element && item.dataset.element === block.id;
          }
          return false;
        })
      );
      setSelectedBlocks?.(dragSelectedBlocks);
    },
    [allFilteredBlocks, setSelectedBlocks]
  );

  const onBeforeStart = useCallback(
    ({ event, store }: SelectionEvent) => {
      document.body.style.userSelect = "none";
      const target = event?.target as HTMLElement;

      clearSelection?.();
      if (!!store.stored.length) return false;
      return !target?.closest(".ds-selectable");
    },
    [clearSelection]
  );

  return { clearSelection, onMove, onStop, onBeforeStart };
};

type MergeRequirementsVariables = {
  requirement_ids: string[];
};

export const useMergeRequirements = () => {
  const { extractionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");
  const { setToast } = useNotification();
  const dispatch = useAppDispatch();
  const { setBulkExtractionRequirementsMerged, createAndInsertRequirement } = useExtractionOperations();
  const extractionComplianceMatrix = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.compliance_matrix
  );

  const mergeRequirements = useCallback(
    async (body: MergeRequirementsVariables) => {
      if (isLoading || !extractionId) return;
      setIsLoading(true);

      try {
        const { data } = await axios.post<MergedRequirement>(`/autopilot/${projectId}/requirements/groups`, body);

        const sectionSortedRequirements = getSortedRequirementsBySectionOrder(extractionComplianceMatrix || []);

        const childRequirements =
          sectionSortedRequirements?.filter(
            (row) =>
              row.requirement.element_id &&
              data.child_requirements.some((childRequirement) => childRequirement.id === row.requirement.id)
          ) || [];
        const hasMultipleAssignedSections =
          uniqBy(
            childRequirements?.filter((req) => !!req.proposal_reference.section_id),
            "proposal_reference.section_id"
          )?.length > 1;
        const firstRequirementWithAssignment = childRequirements.find((row) => !!row.proposal_reference.section_id);
        const volumeAssignment = hasMultipleAssignedSections
          ? ""
          : firstRequirementWithAssignment?.proposal_reference?.volume_id || "";
        const sectionAssignment = hasMultipleAssignedSections
          ? ""
          : firstRequirementWithAssignment?.proposal_reference?.section_id || "";

        const firstYjsRequirementIdxWithinSection =
          sectionSortedRequirements
            .filter(({ proposal_reference }) => proposal_reference.section_id === sectionAssignment)
            ?.findIndex((row) => firstRequirementWithAssignment?.requirement?.id === row.requirement.id) || 0;
        const createdRequirement = createComplianceMatrixRowRequirement({
          content: data.content,
          id: data.id,
          skipped: firstRequirementWithAssignment ? false : childRequirements[0].requirement?.skipped,
          element_id: childRequirements[0]?.requirement?.element_id,
        });
        const rowProperties = {
          document: new LiveObject({
            id: data.document_id,
            name: firstRequirementWithAssignment?.document?.name || "",
          }),
          requirement: createdRequirement,
          written_content: data.response || "",
          proposal_reference: new LiveObject({
            volume_id: volumeAssignment,
            section_id: sectionAssignment,
          }),
        };
        if (projectId) {
          await dispatch(getRequirementGroups({ projectId }));
          await dispatch(getFilteredRequirements({ projectId }));
        }

        if (extractionId) {
          setBulkExtractionRequirementsMerged(extractionId, body.requirement_ids);
          createAndInsertRequirement(extractionId, firstYjsRequirementIdxWithinSection, rowProperties);
          await dispatch(getTemplateRequirements(extractionId));
        }
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
          setToast.error({ msg: e.response.data.error_msg });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      createAndInsertRequirement,
      dispatch,
      extractionComplianceMatrix,
      extractionId,
      isLoading,
      projectId,
      setBulkExtractionRequirementsMerged,
      setToast,
    ]
  );

  return { mergeRequirements, isLoading };
};

export const useUnmergeRequirement = () => {
  const { extractionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");
  const { setToast } = useNotification();
  const dispatch = useAppDispatch();
  const { unmergeRequirement } = useExtractionOperations();
  const mergedRequirements = useAppSelector((store) => store.currentExtractionState.mergedRequirements);

  const handleUnmergeRequirement = useCallback(
    async (requirementId: string) => {
      if (isLoading || !extractionId) return;
      setIsLoading(true);

      try {
        await axios.delete<MergedRequirement>(`/autopilot/${projectId}/requirements/groups/${requirementId}`);

        const mergedRequirement = mergedRequirements.find(
          (mergedRequirement) => mergedRequirement.id === requirementId
        );

        if (extractionId && mergedRequirement)
          unmergeRequirement(
            extractionId,
            mergedRequirement.id,
            mergedRequirement.child_requirements.map(({ id }) => id)
          );
        if (projectId) {
          await dispatch(getRequirementGroups({ projectId }));
          await dispatch(getFilteredRequirements({ projectId }));
        }

        if (extractionId) await dispatch(getTemplateRequirements(extractionId));
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
          setToast.error({ msg: e.response.data.error_msg });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, extractionId, isLoading, mergedRequirements, projectId, setToast, unmergeRequirement]
  );

  return { handleUnmergeRequirement, isLoading };
};

export const useSyncDraftConfig = ({
  extractionId,
  outlineVolumes,
}: {
  extractionId?: string;
  outlineVolumes?: ToImmutable<ExtractionFramework["volumes"]>;
}) => {
  const { updateInstantDraftConfig } = useExtractionOperations();
  const instantDraftStatus = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.status
  );
  const volumes = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.volumes || []
  );
  const sections = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.sections || []
  );

  const instantDraftSectionsAndVolumes = useMemo(
    () => ({ volumes: volumes || [], sections: sections || [] }),
    [sections, volumes]
  );

  useEffect(() => {
    if (!extractionId || instantDraftStatus === InstantDraftStatus.Done) return;

    const { sections, volumes } = instantDraftSectionsAndVolumes;

    if (!volumes.length && !sections.length) return;

    const newCheckedVolumes = outlineVolumes
      ?.filter((volume) => {
        const isAllSectionsChecked = volume.sections.every(({ id }) => sections.includes(id));
        return isAllSectionsChecked;
      })
      .map(({ id }) => id);

    if (!isEqual(newCheckedVolumes, volumes)) {
      updateInstantDraftConfig(extractionId, { volumes: new LiveList(newCheckedVolumes) });
    }
  }, [extractionId, instantDraftSectionsAndVolumes, instantDraftStatus, outlineVolumes, updateInstantDraftConfig]);
};
