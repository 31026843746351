import { ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import useExtractionOperations from "hook/useExtractionOperations";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import { useTrackUserMetric } from "utils/metrics";

const useImportExtraction = () => {
  const { extractionId } = useParams();
  const { importExtraction } = useExtractionOperations();
  const solicitation_type = useAppSelector((store) => store.currentExtractionState.currentExtraction?.soliciation_type);
  const extractionStatus = useAppSelector((store) => store.currentExtractionState.currentExtraction?.status);
  const [isImporting, setIsImporting] = useState(false);
  const trackUserEvent = useTrackUserMetric();

  const onConfirm = useCallback(async () => {
    if (extractionId && extractionStatus !== ExtractionStatus.Completed) {
      setIsImporting(true);
      const done = await importExtraction(extractionId);
      if (done) {
        trackUserEvent("Generate: Generation Completed", {
          analysis_id: extractionId,
          solicitation_type: solicitation_type,
        });
        setIsImporting(false);
      }
    }
  }, [extractionId, extractionStatus, importExtraction, solicitation_type, trackUserEvent]);

  return { importExtraction: onConfirm, isImporting };
};

export default useImportExtraction;
