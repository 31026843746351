/** @jsxImportSource @emotion/react */

import { EditorContent, useEditor } from "@tiptap/react";
import { EditorView } from "prosemirror-view";
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as Y from "yjs";
import styles from "./TextEditor.module.css";
import classNames from "classnames";
import { PROSE_CONTAINER_ID } from "components/editor/constants";
import { CopilotPresencePage } from "types/Presence";
import { Loading } from "../Loading";
import { SelectionMenu } from "../SelectionMenu";
import { selectedTextAction } from "store/reducers/yjs-editor-reducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AskAiSearchBar, { DropDownMenu, ResponseDropDown, ToneDropDown } from "../AskAiToolbar/SearchBarInput";
import {
  editorPrevSelectionAction,
  prevDomSelectionAction,
  resetEditorStateAction,
  storeEditorStateAction,
} from "store/reducers/yjs-editor-reducer";

import LiveNameCursors from "components/molecules/live-name-cursors";
import { useTrackUserMetric } from "utils/metrics";
import { YJSProvider } from "YJSProvider/YJSProvider";
import { useRoom } from "utils/yjs-configs/proposal-document/yjs.config";
import { CommentDraft } from "components/Comments/components/CommentDraft";
import { useSearchParams } from "react-router-dom";
import { useActiveThread } from "components/Comments/components/useThread";
import { useAppSelector } from "store/storeTypes";
import { toggleComments } from "store/reducers/copilot/copilotDrawerReducer";
import { setActiveCommentEditor } from "store/reducers/copilot/copilotReducer";
import { getExtensions } from "../extensions";
import { ThreadContext } from "components/Comments/types";
import { useCommentOperations } from "api/comments/useCommentOperations";
import { setHighlightedText } from "store/reducers/writing-assistant/writingAssistantReducer";
import ReviewEngineScan from "Assets/gifs/review-engine-scan.gif";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import { LOADING_MSGS } from "components/yjs-editor/components/text-editor/constants";
import tw from "twin.macro";
import "../../style/global.css";
import ReviewEngineModal from "components/molecules/review-engine-modal/ReviewEngineModal";
import { useReviewEngine } from "./hooks";

export function TextEditor({
  color,
  liveCursor,
  roomId,
  fullscreen,
}: {
  color: string;
  liveCursor: boolean;
  roomId: string;
  fullscreen?: boolean;
}) {
  return (
    <Suspense fallback={<Loading />}>
      <Editor color={color} liveCursor={liveCursor} roomId={roomId} fullscreen={fullscreen} />
    </Suspense>
  );
}

export function Editor({
  color,
  liveCursor,
  roomId,
  fullscreen,
}: {
  color: string;
  liveCursor: boolean;
  roomId: string;
  fullscreen?: boolean;
}) {
  const room = useRoom();
  const currentUserData = useSelector((store: any) => store.auth);

  return (
    <TiptapEditor
      fullscreen={fullscreen}
      liveCursor={liveCursor}
      roomId={roomId}
      name={currentUserData.currentUser.username}
      color={color}
      doc={room.awarenessDoc}
      provider={room.awarenessProvider}
    />
  );
}

type EditorProps = {
  doc: Y.Doc;
  name: string;
  color: string;
  provider?: YJSProvider;
  liveCursor: boolean;
  roomId: string;
  fullscreen?: boolean;
};

function TiptapEditor({ doc, name, color, provider, liveCursor, roomId, fullscreen }: EditorProps) {
  // Constants and initial state
  const INITIAL_LOADING_MSG = LOADING_MSGS[0];
  const dispatch = useDispatch();
  const trackUserMetric = useTrackUserMetric();
  const [searchParams] = useSearchParams();
  const internalContractId = searchParams.get("id")?.toLowerCase();
  const referenceId = searchParams.get("docId")?.toLowerCase();
  const roomIdParts = useMemo(() => roomId.split("/"), [roomId]);
  const [loadingMsg, setLoadingMsg] = useState(INITIAL_LOADING_MSG);
  const [initialThreadId] = useState(searchParams.get("threadId"));

  // Redux selectors
  const { toggleAskAiInputWthDrpDown, selectedText } = useSelector((state: any) => state.yjsEditor);
  const editorResponse = useSelector((state: any) => state.aiReducer.editorResponse);

  const { proposalHasSuccessfulReview, proposalReviewTaskInProgress } = useReviewEngine(
    internalContractId || "",
    referenceId || ""
  );

  // Loading message animation
  useAnimateLoadingMsg(!!proposalReviewTaskInProgress, 10000, LOADING_MSGS, (msg) => setLoadingMsg(msg));

  // Editor operations
  const scrollDiv = document.getElementById("scrollDiv");
  const proseElement = document.getElementById("tiptapEditor");
  const { deleteThreadMutation, restoreThreadMutation } = useCommentOperations(
    internalContractId!,
    referenceId as string
  );

  // Thread operations
  const removeThread = useCallback(
    (thread: string) => deleteThreadMutation.mutate({ thread_id: thread }),
    [deleteThreadMutation]
  );
  const restoreThread = useCallback(
    (thread: string) => restoreThreadMutation.mutate({ thread_id: thread }),
    [restoreThreadMutation]
  );

  // Extensions for editor
  const extensions = useMemo(
    () =>
      getExtensions({
        provider,
        doc,
        user: {
          name,
          color,
          picture: "",
        },
        removeThread,
        restoreThread,
      }),
    [provider, doc, name, color, removeThread, restoreThread]
  );

  // Editor setup
  const editor = useEditor({
    editorProps: {
      attributes: {
        class: styles.editor,
        id: "proposal-tiptap-editor",
      },
      transformPastedHTML(html) {
        return html.replace(/<img.*?>/g, "");
      },
    },
    extensions,
    onSelectionUpdate: ({ editor }) => {
      if (!editor.isEditable) return;
      const selection = editor.state.selection;
      const { from, to } = selection;
      const text = !selection.empty ? editor.state.doc.textBetween(from, to, "\n") : "";
      if (text) {
        dispatch(setHighlightedText(text));
      }
    },
  });
  const editorRef = useRef<HTMLDivElement>(null);

  // Editor lifecycle and metrics
  useEffect(() => {
    if (editor) {
      const currentSelection = editor.state.selection;
      dispatch(editorPrevSelectionAction(currentSelection));
      dispatch(storeEditorStateAction(editor));
      if (roomIdParts[0] === "document") {
        trackUserMetric("Proposals: Proposal Edited", {
          proposal_document_id: roomIdParts[2],
        });
      }
    }

    return () => {
      dispatch(resetEditorStateAction());
    };
  }, [editor, dispatch, roomIdParts, trackUserMetric]);

  // Handle text selection
  const handleSelection = () => {
    const domSelection = window.getSelection();
    const selectedTextDiv = domSelection?.toString() || "";
    if (selectedTextDiv && domSelection) {
      dispatch(
        prevDomSelectionAction({
          domSelection,
          scrollDivLeft: scrollDiv?.scrollLeft,
          scrollDivTop: scrollDiv?.scrollTop,
          proseElement: proseElement,
        })
      );
      dispatch(selectedTextAction(selectedTextDiv));
    }
  };

  // Comment management
  const activeThread = useActiveThread(editor);
  const commentsOpen = useAppSelector((state) => state.copilotDrawer.commentsOpen);
  useEffect(() => {
    if (!editor) return;

    if (activeThread && !commentsOpen) {
      dispatch(toggleComments(true));
    }

    dispatch(setActiveCommentEditor({ editor, editorId: referenceId }));

    return () => {
      dispatch(setActiveCommentEditor({ editor: undefined, editorId: undefined }));
    };
  }, [editor, activeThread, commentsOpen, dispatch]);

  // Initial thread handling
  useEffect(() => {
    if (initialThreadId) {
      dispatch(toggleComments(true));
      if (editor) {
        editor.commands.setActiveComment(initialThreadId);
      }
    }
  }, [initialThreadId, editor, dispatch]);

  if (!editor) return null;

  return (
    <div className={styles.editorWrapper} ref={editorRef} id="scrollDiv" onPointerUp={handleSelection}>
      {referenceId && <ReviewEngineModal referenceId={referenceId} open={proposalHasSuccessfulReview} />}
      {!!proposalReviewTaskInProgress && (
        <div className="z-[1] mb-32 flex h-full justify-center items-center absolute inset-x-0 inset-y-0 bg-[rgba(255,255,255,0.2)] backdrop-blur-sm">
          <div className="flex flex-col gap-2 items-center">
            <img className="w-[230px] h-[230px]" src={ReviewEngineScan} alt="" />
            <div className="mt-[-35px] h-14 text-center text-gray-darkest">
              <div className="text-lg font-medium">
                {loadingMsg}
                <span className="loading-ellipsis" />
              </div>
              <div className="text-base mt-1 text-gray-dark">
                Review may take between 1-5 minutes based on proposal size.
              </div>
            </div>
          </div>
        </div>
      )}

      {liveCursor && (
        <LiveNameCursors activePage={CopilotPresencePage.Proposal} activeRoomId={roomId} cursorPanel={editorRef} />
      )}
      <div
        className={classNames(styles.prose, "prose")}
        id={PROSE_CONTAINER_ID}
        css={[!!proposalReviewTaskInProgress && tw`overflow-hidden`]}
      >
        <div className={styles.container} id="tiptapEditor">
          {editor && <SelectionMenu editor={editor} />}
          {editor && selectedText !== "" && toggleAskAiInputWthDrpDown && (
            <AskAiSearchBar fullscreen={fullscreen} editor={editor} proseElement={proseElement} scrollDiv={scrollDiv} />
          )}
          {editor &&
            toggleAskAiInputWthDrpDown &&
            (editorResponse?.response === undefined || editorResponse.response === "") && (
              <DropDownMenu fullscreen={fullscreen} editor={editor} proseElement={proseElement} scrollDiv={scrollDiv} />
            )}

          {editor &&
            selectedText !== "" &&
            toggleAskAiInputWthDrpDown &&
            (editorResponse?.response === undefined || editorResponse.response === "") && (
              <ToneDropDown fullscreen={fullscreen} editor={editor} proseElement={proseElement} scrollDiv={scrollDiv} />
            )}
          {editor && selectedText !== "" && toggleAskAiInputWthDrpDown && (
            <ResponseDropDown
              fullscreen={fullscreen}
              editor={editor}
              proseElement={proseElement}
              scrollDiv={scrollDiv}
            />
          )}
          <EditorContent editor={editor} className="focus:outline-none" readOnly={toggleAskAiInputWthDrpDown} />
          <CommentDraft
            editor={editor}
            internalContractId={(internalContractId || "") as string}
            referenceId={referenceId as string}
            context={ThreadContext.PROPOSAL}
            editorContainerRef={editorRef}
          />
        </div>
      </div>
    </div>
  );
}

// Prevents a matchesNode error on hot reloading
EditorView.prototype.updateState = function updateState(state) {
  // @ts-ignore
  if (!this.docView) return;
  // @ts-ignore
  // eslint-disable-next-line eqeqeq
  this.updateStateInner(state, this.state.plugins != state.plugins);
};
