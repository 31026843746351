/** @jsxImportSource @emotion/react */

import { ToImmutable } from "YJSProvider/LiveObjects";
import { ExtractionFramework, ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import compact from "lodash/compact";
import keyBy from "lodash/keyBy";
import { useMemo, useRef, useState } from "react";
import { ChevronDown, ChevronRight, FileText, Info } from "lucide-react";
import {
  ExtractionTemplate,
  setContextBankOpen,
  setSectionToMove,
} from "store/reducers/extract/CurrentExtractionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import * as Accordion from "@radix-ui/react-accordion";
import groupBy from "lodash/groupBy";
import ContextRequirementsList from "./ContextRequirementsList";
import Tooltip from "components/atoms/tooltip";
import { TEMPLATE_TYPE_TO_META } from "../../../template-selection/constants";
import { isInstantDraftStarted } from "../../../utils";

const ContextBankManager = () => {
  const [values, setValues] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const open = useAppSelector((store) => store.currentExtractionState.contextBankOpen);
  const documents = useAppSelector((store) => store.currentExtractionState.documents);
  const templates = useAppSelector((store) => store.currentExtractionState.templates);
  const templateRequirements = useAppSelector((store) => store.currentExtractionState.templateRequirements);
  const isReadOnly =
    extraction?.status === ExtractionStatus.Completed || isInstantDraftStarted(extraction?.instantDraftConfig?.status);
  const ref = useRef(null);

  const documentMap = useMemo(() => keyBy(documents, "id"), [documents]);
  const documentToVolumeMap = useMemo(
    () =>
      templates.reduce<{
        [key: string]: {
          volumes: ToImmutable<ExtractionFramework["volumes"]>;
          volumeToTemplateTypeMap: Record<string, ExtractionTemplate["template_type"]>;
        };
      }>((acc, template) => {
        const volumeToTemplateTypeMap: Record<string, ExtractionTemplate["template_type"]> = {};

        const storageVolumes = compact(
          template.template_data.volumes.map(({ id }) => {
            const foundVolume = extraction?.framework.context_bank?.find((volume) => volume.id === id);
            if (foundVolume) {
              volumeToTemplateTypeMap[foundVolume.id] = template.template_type;
            }
            return foundVolume;
          })
        );
        const documentVolumes = [...(acc[template.document_id]?.volumes || []), ...storageVolumes];

        return {
          ...acc,
          [template.document_id]: {
            volumes: documentVolumes,
            volumeToTemplateTypeMap: {
              ...acc[template.document_id]?.volumeToTemplateTypeMap,
              ...volumeToTemplateTypeMap,
            },
          },
        };
      }, {}),
    [extraction?.framework.context_bank, templates]
  );

  const groupedSections = useMemo(
    () =>
      keyBy(
        extraction?.framework.volumes.flatMap((vol) => vol.sections),
        "id"
      ),
    [extraction?.framework.volumes]
  );

  const documentToVolumeEntries = useMemo(() => Object.entries(documentToVolumeMap), [documentToVolumeMap]);
  const groupedTemplateRequirements = useMemo(() => keyBy(templateRequirements, "id"), [templateRequirements]);
  const sections = useMemo(() => {
    const volumes = Object.values(documentToVolumeMap).flatMap((obj) => obj.volumes);
    const sections = volumes.flatMap((volume) => volume.sections);
    return sections;
  }, [documentToVolumeMap]);
  const assignedSectionsCount = useMemo(
    () => sections.filter((section) => !!groupedSections[section.id]).length,
    [groupedSections, sections]
  );

  const groupedYjsRequirements = useMemo(() => {
    const filteredReqs = extraction?.compliance_matrix
      .filter(
        (yjsReq) =>
          !yjsReq.requirement.soft_deleted &&
          !yjsReq.requirement.skipped &&
          !!groupedTemplateRequirements[yjsReq.requirement.id]
      )
      .map((req) => ({ ...req, atlasSectionId: groupedTemplateRequirements[req.requirement.id].section_id }));

    return groupBy(filteredReqs, "atlasSectionId");
  }, [extraction?.compliance_matrix, groupedTemplateRequirements]);

  return (
    <div ref={ref} className="h-[48%] duration-150" css={[!open && tw`h-[53px]`]}>
      <div
        className="flex h-full flex-col relative transition-transform bg-white shadow-[0px_2px_3px_0px_rgba(0,0,0,0.3)] text-black border-t-1.5 border-gray-light"
        css={[!open && tw`justify-center`]}
      >
        <div className="flex flex-col gap-0.5 px-3">
          <div className="font-medium py-3 text-gray-darkest text-sm w-full flex items-center justify-between">
            <div className="flex items-center gap-1.5">
              Assign Sections
              <Tooltip
                portalProps={{ container: ref.current }}
                content="Each selected section contains groups of requirements. Please assign them to the relevant volume or section within the outline."
              >
                <div>
                  <Info size={14} />
                </div>
              </Tooltip>
            </div>
            <div className="flex gap-1.5 items-center flex-row">
              <div className="text-xs font-normal text-gray-500">
                {assignedSectionsCount}/{sections.length} assigned
              </div>
              <button
                className="text-lg duration-150 rounded p-0.5 hover:bg-zinc-200"
                onClick={() => dispatch(setContextBankOpen(!open))}
                css={[!open && tw`rotate-180`]}
              >
                <ChevronDown size={14} />
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col overflow-auto" css={[!open && tw`hidden`]}>
          {documentToVolumeEntries.map(([docId, { volumes, volumeToTemplateTypeMap }], i) => {
            const document = documentMap[docId];

            return (
              !!volumes.length && (
                <div key={docId} className="flex flex-col">
                  <div
                    className="z-[1] flex items-center gap-1 p-2 border-y border-gray-light bg-zinc-100 sticky top-0 text-xs"
                    css={[!!i && tw`top-[-1px]`]}
                  >
                    <FileText size={14} className="shrink-0" />
                    <div className="truncate">{document.file_name}</div>
                  </div>
                  <Accordion.Root
                    type="multiple"
                    value={values}
                    onValueChange={(value) => {
                      setValues(value);
                    }}
                  >
                    <div className="flex flex-col">
                      {volumes.map(({ sections, id }, i) => (
                        <>
                          <div
                            key={id}
                            className="flex items-center underline bg-white border-b border-gray-lightest gap-1 p-2 pl-3 text-xs"
                          >
                            {TEMPLATE_TYPE_TO_META[volumeToTemplateTypeMap[id]]?.headerLabel}
                          </div>
                          {sections.map((section, i2) => (
                            <Accordion.Item
                              key={section.id}
                              value={section.id}
                              css={[
                                (i2 < sections.length - 1 || i < volumes.length - 1) &&
                                  tw`border-b border-gray-lightest`,
                              ]}
                            >
                              <Accordion.Header>
                                <div className="flex flex-col gap-1 select-none">
                                  <div
                                    className="cursor-default relative overflow-hidden group bg-white text-gray-darkest"
                                    css={[!!groupedSections[section.id] && tw`opacity-40`]}
                                  >
                                    <div className="flex flex-row pl-2 pr-2.5 gap-0.5 items-center justify-between">
                                      <div className="text-xs flex flex-row gap-3.5 items-center w-full">
                                        <Accordion.Trigger
                                          title={section.title}
                                          className="flex flex-row group flex-1 gap-1.5 items-center min-w-0 duration-100"
                                        >
                                          <ChevronRight
                                            size={14}
                                            className="flex-shrink-0 transition-transform mr-0.5 duration-200 group-data-[state=open]:rotate-90"
                                          />
                                          <div className="text-xs 2xl:text-sm py-3 w-full min-w-0">
                                            <div className="flex flex-row justify-between items-center min-w-0">
                                              <span
                                                className="whitespace-nowrap font-medium truncate"
                                                css={[!section.title.trim() && tw`text-gray-400`]}
                                              >
                                                {section.title || "Section title..."}
                                              </span>
                                            </div>
                                          </div>
                                        </Accordion.Trigger>
                                        {!isReadOnly && (
                                          <button
                                            disabled={!!groupedSections[section.id]}
                                            onClick={() => dispatch(setSectionToMove(section))}
                                            className="text-xs w-[74px] font-medium text-zinc-100 rounded px-2.5 py-1 bg-gray-darkest duration-100 hover:bg-zinc-700 disabled:pointer-events-none"
                                          >
                                            {!!groupedSections[section.id] ? "Assigned" : "Assign"}
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Header>
                              <Accordion.Content className="collapsibleContent">
                                <ContextRequirementsList
                                  groupedSections={groupedSections}
                                  requirements={groupedYjsRequirements[section.id]}
                                  isImported={!!groupedSections[section.id]}
                                />
                              </Accordion.Content>
                            </Accordion.Item>
                          ))}
                        </>
                      ))}
                    </div>
                  </Accordion.Root>
                </div>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ContextBankManager;
