import React, { ComponentProps } from "react";
import * as RadixSlider from "@radix-ui/react-slider";

interface SliderProps extends ComponentProps<typeof RadixSlider.Root> {}

const Slider: React.FC<SliderProps> = (props) => {
  return (
    <RadixSlider.Root className="relative flex items-center select-none touch-none h-5" {...props}>
      <RadixSlider.Track className="bg-gray-400 relative grow rounded-full h-[3px]">
        <RadixSlider.Range className="absolute bg-black rounded-full h-full" />
      </RadixSlider.Track>
      <RadixSlider.Thumb className="block w-5 h-5 bg-white rounded-[10px] border border-gray-200 shadow-sm cursor-pointer focus:outline-none" />
    </RadixSlider.Root>
  );
};

export default Slider;
