import usePersistedStorage from "hook/persisted-storage/usePersistedStorage";
import { useFlags } from "hook/useFlags";
import moment from "moment";
import { X } from "lucide-react";
import { FeatureFlagSet } from "types/FeatureFlagSet";

const NewFeatureBannerController = () => {
  const { newFeatureBanner = {} } = useFlags();
  const { version, features } = newFeatureBanner;
  const [ignoreNewFeatureBanner, setIgnoreNewFeatureBanner] = usePersistedStorage<boolean>(
    `ignoreNewFeatureBanner-${version}`,
    false
  );

  const shouldShowBanner = !ignoreNewFeatureBanner && !!features;

  return (
    shouldShowBanner && (
      <NewFeatureBannerBody newFeatureBanner={newFeatureBanner} onIgnore={() => setIgnoreNewFeatureBanner(true)} />
    )
  );
};

const NewFeatureBannerBody = ({
  newFeatureBanner,
  onIgnore,
}: {
  newFeatureBanner: FeatureFlagSet["newFeatureBanner"];
  onIgnore: () => void;
}) => {
  const { features, date } = newFeatureBanner;

  return (
    <div className="absolute left-0 bottom-full right-0">
      <button
        onClick={() => onIgnore()}
        className="absolute right-[18px] top-2.5 text-slate-600 duration-100 hover:text-black"
      >
        <X size={14} />
      </button>
      <div className="max-h-[200px] overflow-y-auto p-2.5 pr-1.5 mx-2 bg-gray-light border border-slate-400 rounded-md shadow-md">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row items-center gap-1 pr-6">
            <div className="text-slate-500 text-xs font-medium">What's new</div>
            <span className="text-xxxs">&#8226;</span>
            <div className="text-slate-500 text-xs font-medium">{moment(date).format("MMM Do")}</div>
          </div>
          {features?.map(({ title, copy }) => (
            <div key={title} className="flex flex-col gap-0.5">
              <div className="text-slate-700 text-xs font-semibold">{title}</div>
              <div className="text-slate-800 text-[11px]">{copy}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewFeatureBannerController;
