import axios from "axios";
import { RoomTypes } from "./types";
import { parseArrayBufferToChunks } from "utils/parseArrayBufferToChunks";

export const authenticateToProposal = async (requestType: string, internalContractId: string, requestId: string) => {
  const response = await axios
    .post(`/contracts/internal/${internalContractId}/proposal/${requestType}/request_edit`, {
      id: requestId,
      provider: "yjs",
    })
    .then((response) => {
      if (response.status === 401) {
        // @ts-ignore
        window.location = "/auth";
        return Promise.reject();
      }
      return response.data;
    })
    .catch((e) => {
      if (e.response.status === 401) {
        // @ts-ignore
        window.location = "/auth";
      }
    });
  const token = response.token;
  if (!token) throw new Error("No token in response");
  return token;
};

export const getCopilotOrProposalStorage = async (
  requestType: string,
  internalContractId: string,
  requestId: string
) => {
  const roomType = requestType === "copilot" ? RoomTypes.Copilot : RoomTypes.ProposalDocument;

  const res = await axios.get<ArrayBuffer>(
    `/ypy/rooms/${roomType}/${internalContractId}/${
      roomType === RoomTypes.Copilot ? "ydoc_updates" : `ydoc_updates?proposal_document_id=${requestId}`
    }`,
    {
      responseType: "arraybuffer",
    }
  );
  return parseArrayBufferToChunks(res.data);
};
