import { Plugin } from "@tiptap/pm/state";
import { Extension } from "@tiptap/react";

const PasteExtension = Extension.create({
  addProseMirrorPlugins() {
    const { editor } = this;
    return [
      new Plugin({
        props: {
          handlePaste(view, event) {
            // If we have html content strip the font family
            const html = event.clipboardData?.getData("text/html");
            if (!html) return false;
            const htmlNodes = new DOMParser().parseFromString(html, "text/html");
            const nearestMark = editor.state.selection.$from.marks().at(-1)?.attrs;
            // Function to recursively remove font size and font family from all elements
            const removeFontStyles = (node: Node | HTMLElement) => {
              if (!(node instanceof HTMLElement)) return;

              if (node.style) {
                if (nearestMark?.fontSize) {
                  node.style.fontSize = nearestMark.fontSize;
                } else {
                  node.style.removeProperty("font-size");
                }
                if (nearestMark?.fontFamily) {
                  node.style.fontFamily = nearestMark.fontFamily;
                } else {
                  node.style.removeProperty("font-family");
                }
              }
              node.childNodes.forEach(removeFontStyles);
            };

            // Remove font styles from all elements in the parsed HTML
            const removeHighlightsAndCommentsStyles = (node: Node | HTMLElement) => {
              if (!(node instanceof HTMLElement)) return;

              const isCommentSpan = (element: HTMLElement) => {
                return (
                  element.tagName.toLowerCase() === "span" &&
                  (element.hasAttribute("data-comment-id") ||
                    element.hasAttribute("data-comment-resolved") ||
                    element.classList.contains("comment-idle") ||
                    element.classList.contains("comment-resolved"))
                );
              };

              if (isCommentSpan(node)) {
                // Replace the comment span with its contents
                while (node.firstChild) {
                  node.parentNode?.insertBefore(node.firstChild, node);
                }
                node.parentNode?.removeChild(node);
                return;
              }

              // Continue processing child nodes
              node.childNodes.forEach((child) => removeHighlightsAndCommentsStyles(child as HTMLElement));
            };

            // Apply both transformations only to the pasted content
            removeFontStyles(htmlNodes.body);
            removeHighlightsAndCommentsStyles(htmlNodes.body);

            // Insert the cleaned pasted content
            const cleanedHtml = htmlNodes.body.innerHTML.trim();
            editor.commands.insertContentAt(editor.state.selection, cleanedHtml);
            return true;
          },
        },
      }),
    ];
  },
});

export default PasteExtension;
