/** @jsxImportSource @emotion/react */

import Popover from "components/atoms/popover";
import { Button } from "components/editor/components";
import { ExtractionTemplate, GeneratedDraft } from "store/reducers/extract/CurrentExtractionReducer";
import { TEMPLATE_TYPE_TO_META } from "./constants";
import { useAppSelector } from "store/storeTypes";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { Extraction, ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import { MutableRefObject } from "react";
import ChipWithDot from "components/atoms/chip-with-dot";
import { isInstantDraftStarted } from "../utils";

type Props = {
  draft: GeneratedDraft;
  template: ExtractionTemplate;
  selectingTemplateIds: string[];
  extraction?: ToImmutable<Extraction>;
  onSelectTemplate: (hasDraft: boolean) => void;
  isContextual?: boolean;
  containerRef: MutableRefObject<HTMLDivElement | null>;
};

const TemplateTableRow = ({
  draft,
  isContextual,
  template,
  onSelectTemplate,
  selectingTemplateIds,
  extraction,
  containerRef,
}: Props) => {
  const documents = useAppSelector((store) => store.currentExtractionState.documents);
  const isSelecting = selectingTemplateIds.includes(draft.id);
  const isReadOnly =
    extraction?.status === ExtractionStatus.Completed || isInstantDraftStarted(extraction?.instantDraftConfig?.status);
  const hasDraftOrExistsInContextBank = isContextual
    ? !!extraction?.framework.context_bank?.some((existingDraft) => draft.id === existingDraft.id)
    : !!extraction?.framework.volumes.some((existingDraft) => draft.id === existingDraft.id);

  return (
    <tr className="text-left text-sm border-b border-b-gray-lightest">
      <td className="pl-4 pr-10 py-3">
        <div className="text-gray-darkest w-max max-w-[400px] 2xl:max-w-[500px] 3xl:max-w-[650px] line-clamp-2 font-medium">
          {draft.title}
        </div>
      </td>
      <td className="px-4 py-3">
        <ChipWithDot color={TEMPLATE_TYPE_TO_META[template.template_type].color}>
          {TEMPLATE_TYPE_TO_META[template.template_type].label}
        </ChipWithDot>
      </td>
      <td className="px-4 py-3">
        <Popover
          portalProps={{ container: containerRef.current }}
          content={
            <div className="text-gray-darkest w-64 p-2.5 whitespace-pre-line text-sm">{template.description}</div>
          }
        >
          <div className="text-blue-500 select-none w-full text-xs duration-100 cursor-pointer whitespace-nowrap hover:text-blue-400">
            View description
          </div>
        </Popover>
      </td>
      <td className="px-4 py-3">
        <div className="text-gray-500 text-xs w-full line-clamp-2">
          {documents.find(({ id }) => id === template.document_id)?.file_name || "Not found"}
        </div>
      </td>
      <td align="right" className="px-4 py-3">
        <div className="text-gray-500">
          <Button
            loading={isSelecting}
            disabled={isSelecting || isReadOnly}
            variant="primary"
            size="sm"
            onClick={() => onSelectTemplate(hasDraftOrExistsInContextBank)}
            className="!bg-[#EEE] w-[83px] !text-[#475569] rounded py-1 px-2.5 text-xs font-medium duration-100 hover:brightness-90"
          >
            {hasDraftOrExistsInContextBank ? "Deselect" : "Select"}
          </Button>
        </div>
      </td>
    </tr>
  );
};

export default TemplateTableRow;
