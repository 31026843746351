import { ReactComponent as ResponseGeneration } from "Assets/svgs/response-generation.svg";
import { LucideArrowRight } from "lucide-react";
import { useMemo } from "react";
import { useAppSelector } from "store/storeTypes";
import { THREAD_COUNT } from "./constants";

const InstantDraftInstructions = () => {
  const instantDraftConfigSections = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.sections
  );
  const extractionMatrix = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.compliance_matrix || []
  );

  const requirementsToSubmitLength = useMemo(
    () =>
      extractionMatrix.filter(
        ({ requirement, proposal_reference }) =>
          !requirement.soft_deleted &&
          !!requirement.content?.trim() &&
          !!proposal_reference.section_id &&
          !requirement.skipped &&
          instantDraftConfigSections?.includes(proposal_reference.section_id)
      ).length,
    [extractionMatrix, instantDraftConfigSections]
  );
  const estimate = !requirementsToSubmitLength
    ? 0
    : requirementsToSubmitLength < 15
    ? 5
    : Math.ceil(requirementsToSubmitLength / THREAD_COUNT + (extractionMatrix.length % 8));

  return (
    <div className="bg-gray-lightest border border-gray-light rounded-md gap-4 flex flex-row justify-between items-center">
      <div className="gap-8 flex flex-col p-8">
        <div className="flex flex-col gap-2">
          <div className="text-gray-darkest text-2xl font-medium">Instant Draft</div>
          <div className="text-gray-500 text-sm">Generate an instant draft of the proposal. </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-2 items-center">
            <LucideArrowRight size={18} /> <span className="text-sm text-gray-600">Generates initial responses</span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <LucideArrowRight size={18} /> <span className="text-sm text-gray-600">Ranks content relevancy</span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <LucideArrowRight size={18} />
            <span className="text-sm text-gray-600">Incorporates capture intelligence</span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <LucideArrowRight size={18} /> <span className="text-sm text-gray-600">Scores generated responses</span>
          </div>
        </div>
        <div className="text-gray-600 text-sm font-medium">
          Estimated Time: {!estimate && "0 minutes"}
          {!!estimate && `${estimate} minutes`}
        </div>
      </div>
      <div className="px-4 h-full bg-white border border-gray-lightest rounded-md m-4">
        <ResponseGeneration className="w-96 h-72" />
      </div>
    </div>
  );
};

export default InstantDraftInstructions;
