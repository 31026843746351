import VanillaSelectionArea from "@viselect/vanilla";
import { SelectionEvents, SelectionOptions } from "@viselect/vanilla";
import React, { useEffect, createContext, useContext, useRef, useState, PropsWithChildren, useCallback } from "react";
import { GroupedBlock } from "../types";
import classNames from "classnames";

export interface SelectionAreaProps extends Partial<SelectionOptions>, React.HTMLAttributes<HTMLDivElement> {
  id?: string;
  className?: string;
  isReadOnly: boolean;
  onBeforeStart?: SelectionEvents["beforestart"];
  onBeforeDrag?: SelectionEvents["beforedrag"];
  onStart?: SelectionEvents["start"];
  onMove?: SelectionEvents["move"];
  onStop?: SelectionEvents["stop"];
}

const SelectionContext = createContext<{
  selectedBlocks?: GroupedBlock[];
  setSelectedBlocks?: React.Dispatch<React.SetStateAction<GroupedBlock[]>>;
  setSelection?: React.Dispatch<React.SetStateAction<VanillaSelectionArea | undefined>>;
  selectionState?: VanillaSelectionArea | undefined;
  clearSelection?: () => void;
}>({});

export const useSelection = () => useContext(SelectionContext);

export const SelectionAreaListener: React.FunctionComponent<SelectionAreaProps> = ({
  onBeforeStart,
  onStart,
  onMove,
  onStop,
  boundaries,
  children,
  className,
  id,
  container,
  selectables,
  isReadOnly,
}) => {
  const root = useRef<HTMLDivElement>(null);
  const { setSelection, selectionState, clearSelection } = useSelection();

  useEffect(() => {
    const areaBoundaries = boundaries ? boundaries : (root.current as HTMLElement);

    const selection = new VanillaSelectionArea({
      boundaries: areaBoundaries,
      container,
      selectables,
    });

    setSelection?.(selection);
  }, [boundaries, container, selectables, setSelection]);

  useEffect(() => {
    selectionState?.on("beforestart", (evt) => onBeforeStart?.(evt));
    selectionState?.on("start", (evt) => onStart?.(evt));
    selectionState?.on("move", (evt) => onMove?.(evt));
    selectionState?.on("stop", (evt) => onStop?.(evt));
  }, [onBeforeStart, onMove, onStart, onStop, selectionState]);

  useEffect(() => {
    if (isReadOnly) {
      selectionState?.destroy();
      clearSelection?.();
    }
  }, [isReadOnly, selectionState, clearSelection]);

  return (
    <div ref={root} className={classNames("selection:bg-transparent", className)} id={id}>
      {children}
    </div>
  );
};

export const SelectionAreaProvider = ({ children }: PropsWithChildren<{}>) => {
  const [selectedBlocks, setSelectedBlocks] = useState<GroupedBlock[]>([]);
  const [selectionState, setSelection] = useState<VanillaSelectionArea | undefined>();
  const selectionStateRef = useRef(selectionState);
  selectionStateRef.current = selectionState;

  const clearSelection = useCallback(() => {
    setSelectedBlocks?.([]);
    selectionStateRef?.current?.clearSelection();
  }, []);

  return (
    <SelectionContext.Provider
      value={{ selectedBlocks, selectionState, setSelectedBlocks, setSelection, clearSelection }}
    >
      {children}
    </SelectionContext.Provider>
  );
};
