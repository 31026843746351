import { Modal } from "components/organisms/modal";
import SmartImport from "./SmartImport";
import EssentialDetails from "./EssentialDetails";
import { Button } from "components/editor/components";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { memo, useCallback, useState } from "react";
import { clearNewProject } from "store/reducers/newProjectReducer";
import { createPrivateInternalContract } from "api/api";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { appendContract } from "store/reducers/contractManagerReducerSlice";
import axios from "axios";
import { useLocalStorage } from "hook/useLocalStorage";
import { useNotification } from "context/notificationContext";
import { useNavigate } from "react-router-dom";
import { CopilotPresencePage } from "types/Presence";
import { toggleCreateProjectModal } from "store/reducers/modalsSlice";
import { useCreateInitialCaptureForms } from "./hooks";
import { useFlags } from "hook/useFlags";
import { FeatureFlagSet } from "types/FeatureFlagSet";

export interface CreateProjectModalProps {
  open: boolean;
}

const CreateProjectModal = ({ open }: CreateProjectModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setToast } = useNotification();
  const { isExtractLoading, isEnhanceLoading, form, attachments } = useAppSelector((root) => root.newProject);
  const dispatch = useAppDispatch();
  const flags = useFlags();
  const createInitialCaptureForms = useCreateInitialCaptureForms(open, flags.capture);
  const { localValue } = useLocalStorage("vultron_user_token", "");
  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { localValue: use_auth0 } = useLocalStorage("vultron_user_use_auth0", "");
  const shouldUseAuth0Header = use_auth0 === true;

  const canSubmit = form.title && form.description && form.client_name;
  const isLoading = isExtractLoading || isEnhanceLoading || isSubmitting;

  const navigate = useNavigate();

  const redirectToProject = useCallback(
    (projectId: string) => {
      const queryParams = new URLSearchParams({ tab: CopilotPresencePage.Project, id: projectId });
      const relativePath = `/dashboard/contracts/details/?${queryParams.toString()}`;
      navigate(relativePath);
    },
    [navigate]
  );

  const createContract = useCallback(async () => {
    if (isLoading || !canSubmit) return;
    setIsSubmitting(true);
    try {
      const res = await createPrivateInternalContract(form);
      if (!res.data.id) {
        throw new Error();
      }
      await Promise.all(
        attachments.map(async (file, i) => {
          let formData = new FormData();
          formData.append("file", file);
          formData.append("internal_upload_type", "0");

          axios({
            method: "POST",
            url: "/contracts/internal/" + res.data.id + "/document/",
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localValue}`,
              Workspace: `Workspace ${workspace_id}`,
              "X-Authorization-Auth0": JSON.stringify(shouldUseAuth0Header),
            },
          });
        })
      );
      if (flags.capture) {
        await createInitialCaptureForms(res.data.id);
      }
      dispatch(clearNewProject());
      dispatch(appendContract(res.data));
      dispatch(toggleCreateProjectModal({ open: false }));
      redirectToProject(res.data.id);
    } catch (e) {
      setToast.error({
        title: "Unable to create project",
        msg: "We were unable to create the project due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
      });
    } finally {
      setIsSubmitting(false);
    }
  }, [
    attachments,
    canSubmit,
    createInitialCaptureForms,
    dispatch,
    flags.capture,
    form,
    isLoading,
    localValue,
    redirectToProject,
    setToast,
    shouldUseAuth0Header,
    workspace_id,
  ]);

  return (
    <Modal
      contentProps={{ css: tw`w-[78vw] max-w-[1000px]` }}
      footerProps={{ css: tw`border-t border-gray-light mt-0 pt-4` }}
      header="Create Project"
      body={
        <div className="flex flex-col overflow-y-auto pb-2 pr-2">
          <SmartImport />
          <EssentialDetails />
        </div>
      }
      footer={
        <div className="flex gap-2 justify-end">
          <Button
            onClick={() => {
              dispatch(toggleCreateProjectModal({ open: false }));
              dispatch(clearNewProject());
            }}
            className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
            variant="outline"
            size="md"
          >
            Cancel
          </Button>
          <Tooltip
            delayDuration={150}
            disabled={!!canSubmit}
            content={`Please enter a valid title, client name, and description to create a project.`}
          >
            <Button
              loading={isSubmitting}
              onClick={() => createContract()}
              disabled={isLoading || !canSubmit}
              variant="primary"
              size="md"
            >
              Create
            </Button>
          </Tooltip>
        </div>
      }
      open={open}
      onOpenChange={(o) => {
        if (!o) {
          dispatch(clearNewProject());
        }
        dispatch(toggleCreateProjectModal({ open: o }));
      }}
    />
  );
};
export default memo(CreateProjectModal);
