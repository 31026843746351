import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";
import { Editor } from "@tiptap/react";

interface DraftCommentState {
  draftActive: boolean | null;
  draftContent: string | null;
}

export const commentDraftPluginKey = new PluginKey<DraftCommentState>("commentDraftPluginKey");
export const commentMetaKey = "commentDraftPluginKey";

export const clearDraft = (editor: Editor) => {
  const prevMeta = commentDraftPluginKey.getState(editor.view.state);
  editor.view.dispatch(editor.view.state.tr.setMeta(commentMetaKey, { ...prevMeta, draftActive: null }));
};

export const CommentsPlugin = new Plugin({
  key: commentDraftPluginKey,
  state: {
    init() {
      return { draftActive: null, draftContent: null };
    },
    apply(tr, value) {
      let newDraftActive = value.draftActive;
      let newDraftContent = value.draftContent;

      const meta = tr.getMeta(commentMetaKey);
      if (meta) {
        newDraftActive = meta.draftActive;
        newDraftContent = meta.draftContent;
      }

      return {
        draftActive: newDraftActive,
        draftContent: newDraftContent,
      };
    },
  },
  props: {
    decorations(state) {
      const decorations: Decoration[] = [];
      const { draftActive } = commentDraftPluginKey.getState(state) || {};

      if (draftActive) {
        const { from, to } = state.selection;
        decorations.push(Decoration.inline(from, to, { class: "comment-draft" }));
      }

      return DecorationSet.create(state.doc, decorations);
    },
  },
});
