/** @jsxImportSource @emotion/react */

import { Document } from "react-pdf";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { GroupedBlock } from "../types";
import { CircleCheck } from "lucide-react";
import { ScreenSpinner } from "utils/icons";
import { Section } from "components/copilot/CopilotSchemaTypes";
import { setRequirementsConfig } from "store/reducers/extract/CurrentExtractionReducer";
import { useDragSelectOperation } from "./hooks";
import { forwardRef, useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import PageWrapper from "./PageWrapper";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { SelectionAreaListener } from "./SelectionContext";
import { useDocViewNotification } from "../../context/doc-view-notification-context/docViewNotificationContext";
import { keyBy } from "lodash";
import { useTrackUserMetric } from "utils/metrics";

type PageDimensionsMap = Record<number, { width: number; height: number }>;

type Props = {
  scale: number;
  groupedBlocks: {
    allFilteredBlocks: GroupedBlock[];
    pageGroups: Record<number, GroupedBlock[]>;
  };
  isReadOnly: boolean;
  sectionsMap: Record<string, ToImmutable<Section>>;
  keysOfSectionMap: string[];
};

const DocumentContainer = forwardRef<HTMLDivElement, Props>(
  ({ scale, keysOfSectionMap, sectionsMap, isReadOnly, groupedBlocks }, ref) => {
    const dispatch = useAppDispatch();
    const { setToast } = useDocViewNotification();
    const trackUserEvent = useTrackUserMetric();
    const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
    const activeDocumentId = useAppSelector((store) => store.currentExtractionState.activeDocument?.id);
    const groupedFilteredRequirementsByDocument = useAppSelector(
      (store) => store.currentExtractionState.groupedFilteredRequirementsByDocument
    );
    const mergedRequirements = useAppSelector((store) => store.currentExtractionState.mergedRequirements);

    const groupedFilteredRequirements = useMemo(
      () =>
        keyBy(
          (groupedFilteredRequirementsByDocument[activeDocumentId || ""] || []).map((filteredRequirement) => {
            return filteredRequirement.group_id
              ? mergedRequirements.find((mergedRequirement) => mergedRequirement.id === filteredRequirement.group_id) ||
                  filteredRequirement
              : filteredRequirement;
          }),
          "id"
        ),
      [activeDocumentId, groupedFilteredRequirementsByDocument, mergedRequirements]
    );

    const [numPages, setNumPages] = useState(0);
    const [dimensions, setDimensions] = useState<PageDimensionsMap>({});
    const { onMove, onStop, onBeforeStart } = useDragSelectOperation(groupedBlocks.allFilteredBlocks, extraction);
    const isLoading = useAppSelector((store) => {
      const { isLoadingCoordinates, isLoadingDocuments, isLoadingTemplate, isLoadingRequirementGroups } =
        store.currentExtractionState;
      return isLoadingCoordinates || isLoadingDocuments || isLoadingTemplate || isLoadingRequirementGroups;
    });
    const activeDocument = useAppSelector((store) => store.currentExtractionState.activeDocument);

    const pageList = useMemo(() => Array.from({ length: numPages }, (_, i) => i + 1), [numPages]);

    const file = useMemo(
      () => ({ url: activeDocument?.secure_preview_url || "" }),
      [activeDocument?.secure_preview_url]
    );

    const onBlockSelected = useCallback(
      (reqId: string) => {
        setToast.success({
          msg: (
            <div className="flex flex-row gap-2 items-center text-sm text-white">
              <CircleCheck size={14} />
              <span>Requirement added.</span>
            </div>
          ),
        });
        trackUserEvent("Generate: Requirement Added", {
          analysis_id: extraction?.id,
          solicitation_type: extraction?.solicitation_type,
        });
        dispatch(setRequirementsConfig({ currentRequirementIdAdded: reqId }));
      },
      [dispatch, extraction?.id, extraction?.solicitation_type, setToast, trackUserEvent]
    );

    const onDocumentLoadSuccess = useCallback(async (pdf: DocumentCallback) => {
      setNumPages(pdf.numPages);

      try {
        const pageDimensionsMap: PageDimensionsMap = {};
        // If you want to get the dimension for page 1 (1-indexed)
        const pageList = Array.from({ length: pdf.numPages }, (_, i) => i + 1);
        const requestList = await Promise.all(pageList.map((page) => pdf.getPage(page)));

        for (let i = 0; i < requestList.length; i++) {
          const page = requestList[i];
          const width = page.view[2];
          const height = page.view[3];
          pageDimensionsMap[pageList[i]] = { width, height };
        }
        setDimensions(pageDimensionsMap);
      } catch {}
    }, []);

    const onDocumentLoadError = useCallback((error: Error) => {
      console.log("Load error", error);
    }, []);

    const DisplayChild = useMemo(
      () => (
        <div className="px-8 pt-14 w-full">
          {file.url && !isLoading ? (
            <Document
              renderMode="canvas"
              file={file}
              onLoadError={onDocumentLoadError}
              onLoadSuccess={onDocumentLoadSuccess}
              className="w-full h-full"
              loading={
                <div className="absolute inset-0 top-14 flex items-center justify-center">
                  <ScreenSpinner />
                </div>
              }
            >
              {window === undefined ? (
                <div />
              ) : (
                pageList.map((page) => {
                  const pageBlocks = groupedBlocks.pageGroups[page] || [];

                  return (
                    <PageWrapper
                      groupedFilteredRequirements={groupedFilteredRequirements}
                      page={page}
                      dimensions={dimensions[page]}
                      key={page}
                      isReadOnly={isReadOnly}
                      pageBlocks={pageBlocks}
                      scale={scale}
                      onBlockSelected={onBlockSelected}
                      sectionsMap={sectionsMap}
                      keysOfSectionMap={keysOfSectionMap}
                    />
                  );
                })
              )}
            </Document>
          ) : (
            <div className="flex items-center justify-center h-full">
              <ScreenSpinner />
            </div>
          )}
        </div>
      ),
      [
        dimensions,
        file,
        groupedBlocks.pageGroups,
        groupedFilteredRequirements,
        isLoading,
        isReadOnly,
        sectionsMap,
        keysOfSectionMap,
        onBlockSelected,
        onDocumentLoadError,
        onDocumentLoadSuccess,
        pageList,
        scale,
      ]
    );

    return (
      <>
        <SelectionAreaListener
          isReadOnly={isReadOnly}
          onStop={onStop}
          className="doc-view-selection-container flex justify-center h-full overflow-y-auto"
          onMove={onMove}
          selectables=".ds-selectable"
          container=".doc-view-selection-container"
          onBeforeStart={onBeforeStart}
        >
          {DisplayChild}
        </SelectionAreaListener>
      </>
    );
  }
);

export default DocumentContainer;
