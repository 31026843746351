export enum CaptureSchemaType {
  Customer = "capture:customer",
  Solution = "capture:solution",
  Approach = "capture:approach",
  Differentiation = "capture:differentiation",
  PastPerformance = "capture:past_performance",
}

export type CaptureSchema = {
  id: string;
  questions: SchemaQuestion[];
  title: string;
  type: CaptureSchemaType;
  version: number;
};

export enum FormItemStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Done = "done",
}

type SchemaQuestion = {
  id: string;
  text: string;
  type: string;
};

export type FormItem = {
  id: string;
  question_id: string;
  question: string;
  task_details: {
    // null initially
    assignee: string | null;
    status: FormItemStatus;
    deadline: string | null;
  } | null;
  response: {
    // null if no response
    value: string;
  } | null;
};

export type Form = {
  id: string;
  schema: Omit<CaptureSchema, "version">;
  items: FormItem[];
};
