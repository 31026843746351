/** @jsxImportSource @emotion/react */
import "twin.macro";

import { HTMLProps, ReactNode } from "react";
import { Interpolation, Theme } from "@emotion/react";

interface Props extends Omit<HTMLProps<SVGElement>, "title"> {
  heading?: string;
  title?: ReactNode;
  containerProps?: HTMLProps<HTMLDivElement> & { css?: Interpolation<Theme> };
}

export const Empty = ({ heading, title, containerProps }: Props) => {
  return (
    <div className="flex flex-col justify-center items-center h-[90%] w-full" {...containerProps}>
      <div className="font-bold text-xl mb-1 text-center">{heading}</div>
      <span className="text-gray-text text-sm mt-3 text-center">{title}</span>
    </div>
  );
};
