/** @jsxImportSource @emotion/react */

import { ComponentProps, ReactNode } from "react";
import { Modal } from "../modal";
import { ConfirmDialogProps, confirmable } from "react-confirm";
import { Button } from "components/editor/components";
import tw from "twin.macro";
import Icon from "components/atoms/icons/Icon";

type Variant = "error";

interface Props extends ComponentProps<typeof Modal> {
  cancelLabel?: string;
  proceedLabel?: string;
  variant?: Variant;
  footerActions?: ReactNode;
}

const ConfirmModal: React.FC<ConfirmDialogProps<Props, boolean>> = ({
  show,
  proceed,
  header,
  body,
  cancelLabel,
  proceedLabel,
  variant,
  footerActions,
  ...props
}) => {
  return (
    <Modal
      contentProps={{ css: tw`max-w-[495px]` }}
      open={show}
      onOpenChange={(o) => {
        if (!o) proceed(false);
      }}
      header={
        <div className="text-slate-900 flex text-base font-medium">
          <span className="mr-2">
            <Icon name="WarningCircle" className="text-[#E99C26]" css={[variant === "error" && tw`text-red-600`]} />{" "}
          </span>
          {header}
        </div>
      }
      body={<div className="text-slate-700 flex text-sm px-6 pr-12">{body}</div>}
      footer={
        <div className="flex items-center justify-between flex-1">
          {footerActions}
          <div className="flex items-center gap-2 justify-end" css={[!footerActions && tw`w-full`]}>
            <Button
              size="md"
              variant="outline"
              className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
              onClick={() => {
                proceed(false);
              }}
            >
              {cancelLabel || "Cancel"}
            </Button>
            <Button
              autoFocus
              size="md"
              variant="primaryBlack"
              css={[variant === "error" && tw`bg-red-600 hover:bg-red-500`]}
              onClick={() => {
                proceed(true);
              }}
            >
              {proceedLabel || "Confirm"}
            </Button>
          </div>
        </div>
      }
      {...props}
    />
  );
};

export default confirmable(ConfirmModal);
