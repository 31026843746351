import { Editor } from "@tiptap/react";
import { Sparkles } from "lucide-react";
import { Button } from "../../primitives/Button";
import styles from "../Toolbar.module.css";
import { useDispatch } from "react-redux";
import { toggleAskAiInputWthDrpDownAction } from "store/reducers/yjs-editor-reducer";

type Props = {
  editor: Editor;
};

export function ToolbarAskAiButton({ editor }: Props) {
  const dispatch = useDispatch();
  return (
    <>
      <Button
        variant="subtle"
        className={`${styles.toolbarAskAiButton} px-[12px] `}
        onClick={() => {
          dispatch(toggleAskAiInputWthDrpDownAction(true));
        }}
        // data-active={editor.isActive("bold") ? "is-active" : undefined}
        aria-label="Bold"
      >
        <div>
          <Sparkles size={18} color="#A782C3" />
          <span className="text-[#a782c3]">Ask AI</span>
        </div>
      </Button>
    </>
  );
}
