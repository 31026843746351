/** @jsxImportSource @emotion/react */

import { PopoverClose } from "@radix-ui/react-popover";
import Icon from "components/atoms/icons/Icon";
import { useMemo } from "react";
import { RequirementCompliance } from "components/copilot/CopilotSchemaImmutableTypes";
import { COMPLIANCE_TO_META } from "const-values/Draft";

type Props = {
  isolated?: boolean;
  selectedStatus?: RequirementCompliance;
  onStatusSelect: (id: RequirementCompliance) => void;
};

const CompliancePopoverContent = ({ isolated, selectedStatus, onStatusSelect, ...props }: Props) => {
  const statusOptions = useMemo(() => {
    return Object.values(RequirementCompliance).map((status) => ({
      status,
      ...COMPLIANCE_TO_META[status],
    }));
  }, []);

  return (
    <div className="w-max max-w-[425px] overflow-hidden bg-white" {...props}>
      <div className="flex flex-col max-h-[180px] overflow-y-auto">
        {statusOptions?.map(({ label, status }) =>
          isolated ? (
            <button
              onClick={() => onStatusSelect(status)}
              className="flex items-center justify-between gap-3 w-full py-2 px-2 duration-100 bg-transparent hover:bg-slate-100"
            >
              <div className="flex items-center gap-2 line-clamp-1">
                {COMPLIANCE_TO_META[status || RequirementCompliance.Empty].icon}
                {label}
              </div>
              {selectedStatus === status && <Icon name="Check" className="w-5 h-5 min-w-[20px]" />}
            </button>
          ) : (
            <PopoverClose
              className="flex items-center justify-between gap-3 w-full py-2 px-2 duration-100 bg-transparent hover:bg-slate-100"
              key={status}
              onClick={() => onStatusSelect(status)}
            >
              <div className="flex items-center gap-2 line-clamp-1">
                {COMPLIANCE_TO_META[status || RequirementCompliance.Empty].icon}
                {label}
              </div>
              {selectedStatus === status && <Icon name="Check" className="w-5 h-5 min-w-[20px]" />}
            </PopoverClose>
          )
        )}
      </div>
    </div>
  );
};

export default CompliancePopoverContent;
